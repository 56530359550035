import React, { useState } from "react";
import SolidButton from '../../components/buttons/SolidButton';

const FollowingModal = () => {
    const [showModal, setShowModal] = useState(false);
    const data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 0, 0, 0, 0, 0, 0];
    return (
        <>
            <span onClick={() => setShowModal(true)}>Following</span>
            {showModal && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="absolute bg-black opacity-50 inset-0" onClick={() => setShowModal(false)}></div>
                    <div className="bg-white p-4 rounded-lg shadow-lg max-w-3xl z-50">
                        <div className="flex justify-between items-center mb-4">
                            <h3 className="text-xl font-semibold flex-grow text-center">100,034 Following</h3>
                            <button className="text-gray-500" onClick={() => setShowModal(false)}>
                                <span className="text-2xl">×</span>
                            </button>
                        </div>
                        <form className="rounded mb-2">
                            <input
                                className="border rounded w-full py-2 px-3 placeholder-opacity-50"
                                type="text" placeholder="Search User"
                            />
                        </form>
                        <ul className="overflow-y-auto max-h-96 ">
                            {data.map((item, index) => (
                                <li key={index} className="flex justify-between items-center mt-2 p-2 rounded cursor-pointer transition ">
                                    <div className="flex items-center">
                                        <span className="text-black opacity-7 h-9 w-9 text-xl flex items-center justify-center bg-gray-400 rounded-full">K</span>
                                        <div className="ml-4 px-4">
                                            <span className="font-medium text-black">KarenKulm34</span>
                                        </div>
                                    </div>
                                    <div className="flex items-center">
                                        <SolidButton text='Following' className="text-black bg-[var(--Cobalt-Blue)] font-normal" />
                                        <i className="fa fa-star text-green-400 ml-2" />
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </>
    );
};

export default FollowingModal;
