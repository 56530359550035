import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useValidateRequest } from "../../contexts/ValidateRequest";

import { FaX } from "react-icons/fa6";
import { BiLoader } from "react-icons/bi";
import { TbArrowBigDown, TbArrowBigUp } from "react-icons/tb";

import SolidButton from "../../components/buttons/SolidButton";
import OutlineButton from "../../components/buttons/OutlineButton";

// import { ReactComponent as GreyArrowUp } from "../../assets/icons/GreyArrowUp.svg";
// import { ReactComponent as GreyArrowDown } from "../../assets/icons/GreyArrowDown.svg";
import { ReactComponent as ReviewArrowUp } from "../../assets/icons/ReviewArrowUp.svg";

const ReviewProductModal = ({ getComment, refreshSinglePost }) => {
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [review, setReview] = useState("");
  const [voteType, setVoteType] = useState("");

  const [post, setPost] = useState(null);

  const { axiosApiCall } = useValidateRequest();
  const { productId } = useParams();

  // Handles Vote - should be extracted to a utility function :)
  const handleVote = async (id, type, action, updateFunction) => {
    try {
      const data = new FormData();
      data.set("target_object_id", id);
      data.set("target_type", type);
      data.set("target_user_id", post.user_id);
      data.set("jwt", window.localStorage.getItem("jwt") || "ggg");

      const response = await axiosApiCall(
        action,
        "post",
        data,
        "multipart/form-data"
      );
      if (response.status === 200) {
        updateFunction(id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSinglePost = async (postId) => {
    try {
      const params = { include_metrics: true };
      const { data } = await axiosApiCall(`post/${postId}`, "get", params);
      setPost(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleReviewSubmission = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const data = new FormData();
      data.set("target_id", productId);
      data.set("content", review);

      await axiosApiCall("comment", "post", data, "multipart/form-data");

      if (post && voteType === "upVote") {
        handleVote(productId, post.type, "vote", getSinglePost);
      }

      if (post && voteType === "downVote") {
        handleVote(productId, post.type, "devote", getSinglePost);
      }

      await getComment();
      await refreshSinglePost();

      setIsSubmitting(false);
      setReview("");
      setShowModal(false);
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
      setShowModal(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    getSinglePost(productId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  return (
    <div>
      <ReviewArrowUp />
      <button
        onClick={() => setShowModal(true)}
        className={`absolute top-12 lg:top-0.5 left-2 lg:left-[38%] px-4 py-2 text-sm border-none outline-none rounded bg-[var(--Cobalt-Blue)] text-white hover:bg-[#006080]`}
      >
        Leave a review
      </button>
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 p-4">
          <div
            className="absolute bg-black opacity-60 inset-0"
            onClick={() => setShowModal(false)}
          ></div>
          <div className="relative bg-white p-4 rounded-lg shadow-lg max-w-3xl z-50">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold flex-grow">
                Review this product
              </h3>
              <button
                className="text-gray-800 font-semibold absolute top-2 right-2"
                onClick={() => setShowModal(false)}
              >
                <FaX className="w-3 h-3" />
              </button>
            </div>
            <p>Please vote before leaving a review</p>

            <form
              onSubmit={handleReviewSubmission}
              className="rounded mb-2 p-4"
            >
              <div className="flex justify-center gap-x-2 items-center mt-2 mb-2 transition-all">
                <TbArrowBigUp
                  onClick={() => setVoteType("upVote")}
                  className={`outline-none h-14 w-14 cursor-pointer ${voteType === "upVote" ? "stroke-[#007EA7] fill-[#007EA7]" : "stroke-gray-400/80 hover:stroke-[#007EA7]"} `}
                />
                <TbArrowBigDown
                  onClick={() => setVoteType("downVote")}
                  className={`outline-none h-14 w-14 cursor-pointer ${voteType === "downVote" ? "stroke-red-500 fill-red-500" : "stroke-gray-400/80 hover:stroke-red-500"} `}
                />
              </div>
              <label className="">Leave feedback</label>
              <input
                type="text"
                id="comment"
                value={review}
                onChange={(e) => setReview(e.target.value)}
                className="w-full outline-none rounded-md border border-gray-300 hover:border-[#007EA7]/40 focus:border-[#007EA7] active:border-[#007EA7] px-4 py-[12px] text-sm placeholder-gray-400 focus:placeholder:opacity-75 text-gray-600 mt-2"
                placeholder="Kindly vote before leaving a review...."
              />

              {isSubmitting ? (
                <button
                  className={`mt-6 w-full text-white rounded-md bg-[var(--Cobalt-Blue)] focus:outline-none focus:shadow-outline py-3 p-6 `}
                >
                  <div className="w-full flex items-center justify-center">
                    <BiLoader className="w-5 h-5 text-white animate-spin text-center" />
                  </div>
                </button>
              ) : (
                <SolidButton
                  text="Review"
                  disabled={!voteType || !review ? true : false}
                  onClick={() => {}}
                  className={`mt-6 w-full text-white rounded-md ${!review || !voteType ? "cursor-not-allowed hover:bg-[var(--Cobalt-Blue)]" : "cursor-pointer"}`}
                />
              )}

              <OutlineButton
                text="Cancel"
                onClick={() => setShowModal(false)}
                className="mt-4 w-full text-[var(--Cobalt-Blue)] h-12  text-center rounded-md font-[400] bg-gray-600/10 hover:bg-gray-600/20"
              />
              <div></div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReviewProductModal;
