import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { BiLoaderCircle } from 'react-icons/bi';
import { Link } from "react-router-dom";
import { validateEmail } from '../../utils/auth';
import { useValidateRequest } from '../../contexts/ValidateRequest';

import tc1 from "../../assets/images/tc1.png";
import tc2 from "../../assets/images/tc2.png";
import tc3 from "../../assets/images/tc3.png";
import tc4 from "../../assets/images/tc4.png";
import tc5 from "../../assets/images/tc5.png";
import tc7 from "../../assets/images/tc7.png";
import tcbubble from "../../assets/images/tcbubble.png";
import NavigationBar from "../../components/homepage/NavigationBar";
import HomePageFooter from "../../components/homepage/HomePageFooter";

const About = () => {
  const { axiosApiCall } = useValidateRequest();

  const [isEmailValid, setIsEmailValid] = useState(true);
  
  const { handleSubmit, reset, register, formState: { isSubmitting } } = useForm({
    defaultValues: { email: '', full_name: '', hair_type: '' },
});
  const onSubmit = async ({ email, full_name,hair_type  }) => {
    console.log({ email, full_name,hair_type  });

    if (!validateEmail(email)) {
        setIsEmailValid(false);
        return;
    }

    setIsEmailValid(true);

    try {
        const data = new FormData();
        data.set('email', email);
        data.set('full_name', full_name);
        data.set('hair_type', hair_type);

        const response = axiosApiCall('subscribe/newsletter/public', 'post', data);

        await toast.promise(response, {
            pending: 'Loading...',
            success: 'Subscribed successfully!',
            error: 'Something went wrong',
            autoClose: 2000,
        });

        const res = await response;
        if (res?.status === 200) reset();

    } catch (error) {
        console.error('Error:', error);
    }
};

  return (
    <>
      <NavigationBar />

      <div className="relative flex items-center justify-center bg-primary h-fit">
        <div className="absolute inset-0 bg-[#5B96A8] opacity-40 -z-10"></div>
        <div className="container flex justify-center items-center h-full pb-12 z-10">
          <div className="w-full">
            <div className="mx-auto mt-14 lg:mt-28 max-w-[900px] text-center flex flex-col justify-center items-center">
              <h1 className="mb-6 text-4xl font-bold lg:text-6xl lg:leading-[1.2] text-[#003249]">
                Hair Care with a personal touch.
              </h1>
              <p className="text-lg lg:leading-8 text-[#003249] w-full px-4 lg:w-[80%]">
                From curated style inspirations to tailored product
                recommendations, Texture Care offers a personalised approach to
                cater to your individual hair needs, ensuring every step of your
                hair care journey is easy and tailored to perfection.
              </p>
              <div className="my-10 flex flex-wrap items-center justify-center gap-5">
                <Link
                  to="/onboarding"
                  className="inline-flex items-center justify-center rounded-md border-2 border-[#007EA7] bg-[#007EA7] w-[200px] px-10 py-[14px] text-center text-base text-white"
                >
                  Sign Up
                </Link>

                <Link
                  to="https://vimeo.com/922149219?share=copy"
                  target="_blank"
                  className="inline-flex items-center justify-center rounded-md border-2 border-[#003249] w-[200px] py-[14px] text-center text-base text-[#003249]"
                >
                  View Demo
                </Link>
              </div>
            </div>

            <div className="w-full mt-12 flex flex-col lg:flex-row gap-8 justify-center items-center z-10 px-4 lg:px-0">
              <div className="">
                <img
                  src={tc1}
                  alt="hair display"
                  className="h-full w-full object-cover"
                />
              </div>
              <div className="">
                <img
                  src={tc2}
                  alt="hair display2"
                  className="h-full w-full object-cover"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="my-16 w-full flex flex-col gap-y-3 items-center justify-center px-4 lg:px-0">
          <h2 className="text-3xl font-bold lg:text-5xl lg:leading-[1.2] text-[#003249] text-center">
            Often Imitated, Never Duplicated
          </h2>
          <p className="text-lg leading-8 text-[#003249] text-center lg:text-start">
            Empowering and amplifying your hair journey while championing
            authenticity and self- expression.
          </p>
        </div>

        <div className="mb-16 w-full flex flex-col items-center justify-center lg:flex-row gap-y-8 lg:gap-x-10 px-4 lg:px-0">
          <div className="relative lg:w-[42%] p-16">
            <div className="absolute inset-0 bg-[#5B96A8] rounded-[3rem] opacity-40 -z-10" />
            <img
              src={tc3}
              alt="app view1"
              className="h-full w-full object-cover z-10"
            />
          </div>

          <div className="flex flex-col gap-y-2 lg:w-[48%] justify-center">
            <h3 className="text-3xl font-bold text-center lg:text-start lg:leading-snug text-[#003249]">
              A feed that actually makes sense.
            </h3>
            <p className="text-lg leading-8 text-[#003249] text-center lg:text-start">
              Discover endless possibilities for styling and maintaining your
              hair. From tutorials and inspiration to expert advice, join in
              conversations, tips, and support that celebrate the beauty and
              diversity of Afro-textured hair.
            </p>
          </div>
        </div>

        <div className="mb-16 w-full flex flex-col items-center justify-center lg:flex-row-reverse gap-y-8 lg:gap-x-10 px-4 lg:px-0">
          <div className="relative lg:w-[42%] p-16">
            <div className="absolute inset-0 bg-[#F4C2C2] rounded-[3rem] opacity-80 -z-10" />
            <img
              src={tc4}
              alt="app view2"
              className="h-full w-full object-cover z-10"
            />
          </div>

          <div className="flex flex-col gap-y-2 lg:w-[48%] justify-center">
            <h3 className="text-3xl font-bold text-center lg:text-start lg:leading-snug text-[#003249]">
              You're tagged.
            </h3>
            <p className="text-lg leading-8 text-[#003249] text-center lg:text-start">
              Start the conversation and let others discover your personal hair
              must-haves. With a simple product tag in your posts, share and
              showcase your favourite hair products
            </p>
          </div>
        </div>

        <div className="mb-16 w-full flex flex-col items-center justify-center lg:flex-row gap-y-8 lg:gap-x-10 px-4 lg:px-0">
          <div className="relative lg:w-[42%] p-16">
            <div className="absolute inset-0 bg-[#5B96A8] rounded-[3rem] opacity-40 -z-10" />
            <img
              src={tc5}
              alt="app view3"
              className="h-full w-full object-cover z-10"
            />
          </div>

          <div className="flex flex-col gap-y-2 lg:w-[48%] justify-center">
            <h3 className="text-3xl font-bold text-center lg:text-start lg:leading-snug text-[#003249]">
              It's like a reward.
            </h3>
            <p className="text-lg leading-8 text-[#003249] text-center lg:text-start">
              Join the conversation and earn rewards by interacting with our
              community. Whether you're an experienced hair enthusiast or just
              starting your natural hair journey, Texture Care welcomes you.
            </p>
          </div>
        </div>
      </div>

      <div className="mb-16 relative flex flex-col items-center justify-center bg-primary h-fit pb-8 px-2 lg:py-16 lg:px-12">
        <div className="absolute inset-0 bg-[#5B96A8] opacity-40 -z-10" />
        <h4 className="my-4 lg:my-6 text-4xl font-bold leading-snug sm:text-5xl sm:leading-snug lg:text-6xl lg:leading-[1.2] text-[#003249]">
          Watch Demo
        </h4>
        <video
          className="w-full h-fit lg:h-screen z-10 rounded-lg lg:mt-4"
          controls
          src="https://www.w3schools.com/html/mov_bbb.mp4"
        >
          Your browser does not support the video tag.
        </video>
      </div>

      <div className="relative w-full my-10 pb-10 lg:my-20 lg:py-20 flex flex-col items-center justify-center gap-y-8 px-4 lg:px-0">
        <h4 className="text-xl lg:text-2xl font-bold leading-snug sm:leading-snug lg:leading-[1.2] text-[#003249] text-center lg:text-start">
          Texture Care, supported by Microsoft for Startups Founders Hub.
        </h4>
        <div className="flex items-center justify-center">
          <img
            src={tc7}
            alt="microsoft"
            className="lg:h-1/2 lg:w-1/2 object-cover"
          />
        </div>

        <div className="absolute -bottom-[12rem] lg:-left-6 -left-10 -z-10">
          <img src={tcbubble} alt="tcbubble" className="object-cover" />
        </div>
      </div>

      <div className="w-full pt-16 pb-4 bg-[#003249] z-10 flex flex-col gap-y-12 ">
        <div className="w-full flex items-center justify-center">
          <form onSubmit={handleSubmit(onSubmit)} className="bg-[#007EA7] w-full lg:w-[80%] lg:rounded-xl px-4 lg:px-12 lg:py-14 py-10 flex flex-col gap-y-8  justify-center">
            <div className="text-white text-center flex flex-col gap-y-3 mb-2 lg:mb-6">
              <h4 className="font-bold  text-4xl">Join the Waitlist</h4>
              <p className="font-semibold text-lg">
                Be the first to experience the future of personalised hair care!
              </p>
            </div>

            <input
              type="text"
              className="w-full py-4 px-6 outline-none border-none rounded-md bg-white/90 font-medium text-base text-gray-800 placeholder-gray-600"
              placeholder="Enter Full Name"
              {...register('full_name')}
            />

            <input
              type="email"
              className="w-full py-4 px-6 outline-none border-none rounded-md bg-white/90 font-medium text-base text-gray-800 placeholder-gray-600"
              placeholder="Enter Email"
              {...register('email')}
            />

            <input
              type="text"
              className="w-full py-4 px-6 outline-none border-none rounded-md bg-white/90 font-medium text-base text-gray-800 placeholder-gray-600"
              placeholder="What's your hair type?"
              {...register('hair_type')}
            />

            <button
              type="submit"
              className="w-[200px] border-none outline-none rounded-md bg-black/80 hover:bg-black/90 text-white text-center py-4 font-semibold"
            >
              {/* Subscribe */}
              {isSubmitting ? <BiLoaderCircle className="w-5 h-5 text-white animate-spin" /> : 'Subscribe'}
            </button>
          </form>
        </div>
        <HomePageFooter />
      </div>
    </>
  );
};

export default About;
