import React from 'react';
import { Link } from "react-router-dom";
import { handleOpenPdf } from "../../utils/utils";
import tiktok from "../../assets/images/tiktok.png";
import logo from "../../assets/images/tc-logo-black.png";
import instagram from "../../assets/images/instagram.png";
import pinterest from "../../assets/images/pinterest.png";


const HomePageFooter = () => {
  const COOKIE_POLICY = process.env.PUBLIC_URL + '/assets/pdfs/COOKIE_POLICY.pdf';
  const PRIVACY_NOTICE = process.env.PUBLIC_URL + '/assets/pdfs/PRIVACY_NOTICE.pdf';
  const TERMS_AND_CONDITIONS = process.env.PUBLIC_URL + '/assets/pdfs/TERMS_AND_CONDITIONS.pdf';
  return (
    <footer className="bg-[#003249]">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
        <div className="md:mt-0 flex justify-center md:justify-start">
          <img
            alt="texture-care"
            src={logo}
            className="w-32 sm:w-36 md:w-32 lg:w-36 xl:w-40 h-auto max-w-full"
          />
        </div>

        <div className="mt-4 md:mt-0 md:mb-8 flex justify-center items-end space-x-6">
          <a
            href="https://tiktok.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-gray-200 p-2"
          >
            <span className="sr-only">Tiktok</span>
            <img
              src={tiktok}
              className="w-8 h-8 object-contain"
              alt="texture care"
            />
          </a>
          <a
            href="https://www.pinterest.co.uk/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-gray-200 p-2"
          >
            <span className="sr-only">Pinterest</span>
            <img
              src={pinterest}
              className="w-8 h-8 object-contain"
              alt="icon"
            />
          </a>
          <a
            href="https://www.instagram.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-gray-200 p-2"
          >
            <span className="sr-only">Instagram</span>
            <img
              src={instagram}
              className="w-8 h-8 object-contain"
              alt="icon"
            />
          </a>
        </div>

        <div
          className="-mx-5 -my-2 flex flex-wrap justify-center order-2 md:order-none md:mt-4 md:ml-8"
          aria-label="Footer"
        >
          <div className="px-5 py-2">
            <Link
              className="text-base text-white hover:text-gray-200"
              onClick={() => handleOpenPdf(TERMS_AND_CONDITIONS)}
            >
              Terms and Conditions
            </Link>
          </div>
          <div className="px-5 py-2">
            <Link
              className="text-base text-white hover:text-gray-200"
              onClick={() => handleOpenPdf(PRIVACY_NOTICE)}
            >
              Privacy Policy
            </Link>
          </div>
          <div className="px-5 py-2">
            <Link
              className="text-base text-white hover:text-gray-200"
              onClick={() => handleOpenPdf(COOKIE_POLICY)}
            >
              Cookie Policy
            </Link>
          </div>
        </div>
      </div>
    </footer>

  )
}

export default HomePageFooter;