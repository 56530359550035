import React, { useState } from "react";
import AllTab from "../components/tabs/AllTab";
import PostsTab from "../components/tabs/PostsTab";
// import ReviewsTab from "../components/tabs/ReviewsTab";
import DiscussionTab from "../components/tabs/DiscussionTab";

const ProfileTab = ({ data }) => {
  const [activeTab, setActiveTab] = useState(0);
  const tabs = [
    { name: "All", Component: <AllTab data={data} /> },
    { name: "Posts", Component: <PostsTab posts={data.post} /> },
    {
      name: "Discussion",
      Component: <DiscussionTab discussions={data.discussion} />,
    },
    // { name: "Reviews", Component: <ReviewsTab /> },
  ];
  return (
    <div className="flex flex-col items-center w-full transition-all">
      <ul className="flex justify-center items-center my-6">
        {tabs.map((tab, index) => (
          <li
            key={index}
            className={`cursor-pointer py-2.5 px-4 rounded transition ${activeTab === index ? "bg-[var(--Cobalt-Blue)] text-white" : "text-gray-500 hover:text-gray-700"}`}
            onClick={() => setActiveTab(index)}
          >
            {tab.name}
          </li>
        ))}
      </ul>

      <div className="w-full p-4">
        {tabs.map((tab, index) => (
          <div
            key={index}
            style={{ display: activeTab === index ? "block" : "none" }}
          >
            {tab.Component}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProfileTab;
