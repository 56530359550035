/**
 * Inner comment components
 *
 * This is the second layer comment display - replies
 * props - user (user's name to be displayed) and comment (user's comment)
 *
 * Dummy votes states, this would be pulled from DB and onClick
 * will update the DB with +1 (upvotes or downvotes)
 */

import React, { useState } from "react";
import { useStateContext } from "../../contexts/ContextProviders";
import { useValidateRequest } from "../../contexts/ValidateRequest";

import { TbArrowBigDown, TbArrowBigUp } from "react-icons/tb";
import { TbArrowBigUpFilled, TbArrowBigDownFilled } from "react-icons/tb";
import { MdOutlineDelete, MdOutlineEdit } from "react-icons/md";
import DeleteReply from "../../components/modals/DeleteReply";
import EditReply from "../../components/modals/EditReply";
import { Link } from "react-router-dom";

const InnerComment = ({
  userId,
  postId,
  parent_id,
  comment,
  commentId,
  username,
  like,
  unlike,
  voteStatus,
  getComment,
  setReplies,
  isAdminProfile,
  isProductPage,
  replyUserId,
  commentType
}) => {
  const [isClicked, setIsClicked] = useState(false);
  const [editClicked, setEditClicked] = useState(false);

  const { role, userId: globalUserId } = useStateContext();
  const { axiosApiCall } = useValidateRequest();

  // console.log(userId);

  // Handles Comments Vote
  const handleVote = async (id, type, action, updateFunction) => {
    try {
      const data = new FormData();
      data.set("target_object_id", id);
      data.set("target_type", type);
      data.set("target_user_id", userId);

      const response = await axiosApiCall(
        action,
        "post",
        data,
        "multipart/form-data"
      );
      if (response.status === 200) {
        updateFunction();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // This function upvotes a post
  const upVoteReply = (id) => handleVote(id, "comment", "vote", getReplies);
  const downVoteReply = (id) => handleVote(id, "comment", "devote", getReplies);

  const getReplies = async () => {
    const params = {
      page: 1,
      limit: 1000,
      target_id: postId,
      include_metrics: true,
    };
    try {
      const getCommentResponse = await axiosApiCall("comment", "get", params);
      const allComments = getCommentResponse.data.data;
      const secondLevelComments = allComments.filter((comment) => comment.parent_id !== null)
      setReplies(secondLevelComments);

    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex flex-col gap-y-2 p-2 px-4 my-4 last:mb-0 border border-[#007EA7]/10 rounded-lg hover:shadow-sm hover:bg-gray-200/10">
      {/* Delete Reply */}
      {isClicked && (
        <DeleteReply
          commentId={commentId}
          comment={comment}
          getComment={getComment}
          setIsClicked={setIsClicked}
          username={username}
        />
      )}
      {editClicked && (
        <EditReply
        commentId={commentId}
        comment={comment}
        getComment={getComment}
        setEditClicked={setEditClicked}
        editClicked={editClicked}
        username={username}
        commentType={commentType}
        parent_id={parent_id}
      />
      )}

      <Link
        to={
          replyUserId && globalUserId === replyUserId
            ? "/profile"
            : `/profile/${replyUserId}`
        }
        className="font-semibold text-[13px]"
      >
        {username || "Kelvin"}
      </Link>
      <p className="font-normal text-[13px]">{comment}</p>
      <div className="flex items-center gap-x-6 text-gray-500 font-light">
        {!isAdminProfile && (
          <button
            onClick={() => upVoteReply(commentId)}
            className="flex items-center gap-x-1"
          >
            {voteStatus === 0 ? (
              <TbArrowBigUp
                className="w-6 h-8"
                onClick={() => upVoteReply(commentId)}
              />
            ) : voteStatus === 1 ? (
              <TbArrowBigUpFilled className="w-6 h-8" />
            ) : (
              <TbArrowBigUp
                className="w-6 h-8"
                onClick={() => upVoteReply(commentId)}
              />
            )}
            <p className="font-semibold text-sm">{like}</p>
          </button>
        )}
        {!isAdminProfile && (
          <button
            onClick={() => downVoteReply(commentId)}
            className="flex items-center gap-x-1"
          >
            {voteStatus === 0 ? (
              <TbArrowBigDown
                className="w-6 h-8"
                onClick={() => downVoteReply(commentId)}
              />
            ) : voteStatus === -1 ? (
              <TbArrowBigDownFilled className="w-6 h-8" />
            ) : (
              <TbArrowBigDown
                className="w-6 h-8"
                onClick={() => downVoteReply(commentId)}
              />
            )}
            <p className="font-semibold text-sm">{unlike}</p>
          </button>
        )}

        {role === "ADMIN" && globalUserId === replyUserId && (
          <div className="flex items-center gap-x-6">
            <button
              onClick={() => {setEditClicked(true)}}
              className="flex items-center gap-x-1 opacity-80 hover:opacity-100 -ml-2"
            >
              <MdOutlineEdit className="w-6 h-6" />
              <p className="font-semibold text-sm">Edit Reply</p>
            </button>
          </div>
        )}
        {role === "ADMIN" && (
          <button
            onClick={() => {
              setIsClicked(true);
            }}
            className="flex items-center gap-x-1 opacity-80 hover:opacity-100 -ml-2"
          >
            <MdOutlineDelete className="w-6 h-8" />
            <p className="font-semibold text-sm">Delete Reply</p>
          </button>
        )}
      </div>
    </div>
  );
};

export default InnerComment;
