import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import SignUpForm from "../../components/auth/SignUpForm";
import logo from "../../assets/images/tc-logo.png";
// import logo from "./onboarding_images/logo.png";
import mobileLogo from "./onboarding_images/mobile_logo.png";

import { useSearchParams } from "react-router-dom";


const OnboardingReturn = () => {
  const [connectionError] = useState(false);

  const [searchParams] = useSearchParams();

  const email = searchParams.get('email') || "";


  return (
    <div className="w-full h-screen relative">
      {/* Backround Image */}
      <div className="w-full h-full fixed -z-10 background bg-mobile-hero md:bg-hero-img bg-center bg-cover"></div>
      {/* Background Image Ends */}

      {/* Header Section Begins*/}
      <div className="header w-full h-12 p-2 lg:px-4 flex justify-between items-center sticky top-3 ">
        <a href="/" className="logo">
          <img
            src={mobileLogo}
            alt="Texture Care"
            className="md:hidden w-24 sm:w-28 h-auto"
          />
          <img
            src={logo}
            alt="Texture Care"
          className="hidden md:block md:w-40 lg:w-44 h-auto"
          />
        </a>
        <div className="hidden md:block w-[17rem] p-3 rounded-3xl h-[3rem] bg-white">
          Got an account already?{" "}
          <a href="/sign-in" className="">
            Sign in!
          </a>
        </div>
        <a
          href="/sign-in"
          className="md:hidden w-[6.5rem] p-2 rounded-3xl h-[3rem] flex justify-center items-center bg-white text-center"
        >
          Sign in
        </a>
      </div>
      {/* Header Section Ends */}

      {/* Connection error Alert */}
      {connectionError ? (
        <div className="connection-wrap border-[1px] absolute top-16 right-4 border-red-500 bg-white p-2 px-4 rounded-3xl">
          <span className="hidden md:block text-red-500">
            Poor connection. Please try again
          </span>
          <span className="md:hidden text-red-500">
            Poor internet connection
          </span>
        </div>
      ) : (
        ""
      )}

      {/* Form Section Begins */}
      <div>
        <SignUpForm email={email} />
      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default OnboardingReturn;
