import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { handleOpenPdf } from "../utils/utils";
import { useStateContext } from "../contexts/ContextProviders";
import { useValidateRequest } from "../contexts/ValidateRequest";
import SolidButton from "./buttons/SolidButton";
import OutlineButton from "./buttons/OutlineButton";
import Notifications from "../pages/notification/Notification";
import Logo from "../assets/images/tc-logo.png";
import { ReactComponent as UserFeed } from "../assets/icons/home.svg";
import { ReactComponent as Package } from "../assets/icons/package.svg";
import { ReactComponent as UsersThree } from "../assets/icons/users_three.svg";
import { ReactComponent as StoreFront } from "../assets/icons/store_front.svg";
import { ReactComponent as Notification } from "../assets/icons/notifications.svg";
import { ReactComponent as Filter } from "../assets/icons/SlidersHorizontal.svg";
import { ReactComponent as MenuIcon } from "../assets/icons/icons8-menu-button-30.svg";
import { ReactComponent as CaretRight } from "../assets/icons/caret_right.svg";
import { ReactComponent as CaretDown } from "../assets/icons/caret_down.svg";
import { ReactComponent as CaretUp } from "../assets/icons/caret_up.svg";
import { ReactComponent as UserIcon } from "../assets/icons/user_icon.svg";
import { ReactComponent as LogoutIcon } from "../assets/icons/logout_icon.svg";
import { ReactComponent as CancelIcon } from "../assets/icons/cancel_icon.svg";

const DashboardHeader = ({
  discussionPostPage,
  imagePostPage,
  profilepage,
  productReviewPage,
  setFilter,
  setPage,
  setResponse,
}) => {
  const PRIVACY_NOTICE =
    process.env.PUBLIC_URL + "/assets/pdfs/PRIVACY_NOTICE.pdf";
  const TERMS_AND_CONDITIONS =
    process.env.PUBLIC_URL + "/assets/pdfs/TERMS_AND_CONDITIONS.pdf";
  const hairType = ["Curly", "Kinky", "Coily", "Straight"];
  const productType = [
    "Shampoo",
    "Conditioner",
    "Hair Oil",
    "Hair Serum",
    "Hair Mask",
    "Hair Protector",
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [hairOptionOpen, setHairOptionOpen] = useState(true);
  const [productOptionOpen, setProductOptionOpen] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [userDropDown, setUserDropDown] = useState(false);
  const [logOutCheck, setLogOutCheck] = useState(false);
  const [notificationMenu, setNotificationMenu] = useState(false);
  const [profileInfo, setProfileInfo] = useState(null);

  // Initialize state for checkboxes
  const [formState, setFormState] = useState({
    hairType: hairType.reduce((acc, type) => ({ ...acc, [type]: false }), {}),
    productType: productType.reduce(
      (acc, type) => ({ ...acc, [type]: false }),
      {}
    ),
  });

  // Handle change event for Filter Checkboxes
  const handleChange = (event) => {
    const { name, checked } = event.target;
    const [category, type] = name.split("-");
    setFormState((prevState) => ({
      ...prevState,
      [category]: {
        ...prevState[category],
        [type]: checked,
      },
    }));
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    setFilter(formState);
    setPage(1);
    setResponse([]);
  };

  const navigate = useNavigate();
  const { axiosApiCall } = useValidateRequest();
  const { handleClick, isClicked, userId, setRole, setUserId } =
    useStateContext();

  const filterOption = () => {
    setIsFilterOpen(!isFilterOpen);
    setHairOptionOpen(true);
    setProductOptionOpen(true);
    setShowModal(true);
    setUserDropDown(false);
  };

  const toogleModal = () => {
    setShowModal(false);
    setIsFilterOpen(false);
    setLogOutCheck(false);
  };

  const profile = () => {
    setUserDropDown(!userDropDown);
    setIsFilterOpen(false);
    setNotificationMenu(false);
  };

  const signOut = () => {
    // setShowModal(!showModal)
    setLogOutCheck(!logOutCheck);
    setIsOpen(false);
  };

  const notificationMenuCheck = () => {
    setNotificationMenu(!notificationMenu);
    setUserDropDown(false);
    setIsFilterOpen(false);
  };

  useEffect(() => {
    const getProfileInfo = async () => {
      try {
        userId &&
          (await axiosApiCall(
            `user/${userId}?include_metrics=true`,
            "get"
          ).then(
            (data) => data.status === 200 && setProfileInfo(data?.data?.data)
          ));
      } catch (error) {
        console.log(error);
      }
    };
    getProfileInfo();
  }, [axiosApiCall, userId]);

  return (
    <div className="sticky top-0 left-0 bg-white z-50 md:h-[80px]">
      {/* fixed */}
      <div
        className={`modal fixed z-20 top-0 w-full h-screen bg-black opacity-50 inset-0 lg:hidden ${showModal ? "block" : "hidden"}`}
        onClick={toogleModal}
      ></div>
      <div
        className={`modal absolute z-20 top-0 w-full h-screen bg-black opacity-50 inset-0 lg:hidden ${isOpen ? "block" : "hidden"}`}
        onClick={() => setIsOpen(!isOpen)}
      ></div>
      <nav className="flex items-center justify-between flex-wrap bg-grey-darkest p-4 w-full transition duration-500 ease-in-out">
        <div className="lg:w-[23rem] flex items-center justify-between flex-no-shrink text-white mr-6">
          <Link
            className="text-white flex no-underline hover:text-white hover:no-underline"
            to="/userfeed"
          >
            <span className="text-2xl pl-2">
              <img
                className="w-24 sm:w-28 md:w-32 lg:w-28 xl:w-32 h-auto max-w-full"
                src={Logo}
                alt="texture care logo"
              />
            </span>
          </Link>

          {/* For upload related modals, solid button (Post Content) should not show */}
          {isClicked.openUploadModal ||
          isClicked.openDiscussionUploadModal ||
          isClicked.openProductUploadModal ||
          discussionPostPage ||
          imagePostPage ||
          productReviewPage ||
          profilepage ? (
            ""
          ) : (
            <SolidButton
              text="Post content"
              onClick={() => {
                handleClick("postContentBtn");
              }}
              className="hidden lg:block text-white rounded-3xl px-4 hover:scale-110 transition-transform duration-300 ease-in-out"
            />
          )}
        </div>

        <div className="block lg:hidden">
          <button
            id="nav-toggle"
            disabled={
              isClicked.openUploadModal ||
              isClicked.openDiscussionUploadModal ||
              isClicked.openProductUploadModal
            }
            className="w-12 h-12 flex items-center justify-center border rounded text-grey border-grey-dark transition duration-300 ease-in-out"
            onClick={() => setIsOpen(!isOpen)}
          >
            <MenuIcon />
          </button>
        </div>

        <div
          className={`w-[90%] lg:w-auto h-fit flex-grow absolute lg:relative top-0 inset-0 m-auto z-20 lg:flex lg:items-center bg-white ${isOpen ? "block bg-white lg:bg-white mt-4 transition-transform duration-300 ease-in-out rounded-xl" : "hidden"} lg:pt-0 items-center justify-center`}
        >
          <ul className="hidden list-reset lg:flex justify-end flex-1 items-center">
            <li
              className="mr-3 hidden lg:block hover:cursor-pointer"
              onClick={filterOption}
            >
              {isClicked.openUploadModal ||
              isClicked.openDiscussionUploadModal ||
              isClicked.openProductUploadModal ||
              discussionPostPage ||
              imagePostPage ||
              productReviewPage ||
              profilepage ? (
                ""
              ) : (
                <div className="py-2 px-3 no-underline hover:scale-110 transition-transform duration-300 ease-in-out flex text-xl text-black border bg-[#FCFCFD] border-[#D0D5DD] rounded-3xl ">
                  Filter <Filter className="mt-1 ml-3" />
                </div>
              )}
            </li>
            <li className="mr-3 mb-2 lg:mb-0">
              <Link
                className="flex text-grey-dark no-underline hover:text-grey-lighter hover:text-underline hover:scale-110 transition-transform duration-300 ease-in-out py-2 px-4"
                to="/userfeed"
              >
                <UserFeed />
                <p className="block lg:hidden text-xl ml-4">User Feed</p>
              </Link>
            </li>
            <li className="mr-3 mb-2 lg:mb-0">
              <a
                className="py-2 px-4 text-black no-underline hover:scale-110 transition-transform duration-300 ease-in-out flex"
                href="https://mytexturecare.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <StoreFront />
                <p className="block lg:hidden text-xl ml-4">Online Shop</p>
              </a>
            </li>

            <li className="mr-3 mb-2 lg:mb-0">
              <Link
                className="flex text-grey-dark no-underline hover:text-grey-lighter hover:text-underline hover:scale-110 transition-transform duration-300 ease-in-out py-2 px-4"
                to="/product"
              >
                <Package />
                <p className="block lg:hidden text-xl ml-4">Products</p>
              </Link>
            </li>
            <li className="mr-3 mb-2 lg:mb-0">
              <Link
                className="flex text-grey-dark no-underline hover:text-grey-lighter hover:text-underline hover:scale-110 transition-transform duration-300 ease-in-out py-2 px-4"
                to="/discussion"
              >
                <UsersThree />
                <p className="block lg:hidden text-xl ml-4">Community Posts</p>
              </Link>
            </li>
            <li className="mr-3 mb-2 lg:mb-0">
              <div
                className="flex text-grey-dark no-underline hover:text-grey-lighter hover:text-underline hover:scale-110 transition-transform duration-300 ease-in-out py-2 px-4"
                onClick={notificationMenuCheck}
              >
                <Notification />
                <p className="block lg:hidden text-xl ml-4">Notifications</p>
              </div>
            </li>
            <li className="mr-3">
              <button
                className=" ml-4 text-grey-dark flex items-center hover:scale-110 hover:cursor-pointer transition-transform duration-300 ease-in-out"
                onClick={profile}
                disabled={
                  isClicked.openUploadModal ||
                  isClicked.openProductUploadModal ||
                  isClicked.openDiscussionUploadModal
                }
              >
                {profileInfo?.avt ? (
                  <img
                    className="w-10 h-10 rounded-full border border-gray-300 mx-auto sm:mx-0"
                    src={profileInfo?.avt}
                    alt="avatar"
                  />
                ) : (
                  <span className="bg-[#F79009] w-10 h-10 font-bold  text-center rounded-full flex items-center justify-center">
                    {profileInfo &&
                      profileInfo?.first_name.substring(0, 2).toUpperCase()}
                  </span>
                )}
                <div className="flex items-center justify-center ml-2">
                  <CaretDown className="w-4 h-3" />
                </div>
              </button>
            </li>
          </ul>
          {/* For Mobile */}
          <ul className="list-reset relative lg:hidden justify-end flex-1 items-center p-4">
            <CancelIcon
              className="cancel-btn absolute right-[1rem]"
              onClick={() => setIsOpen(false)}
            />
            <li className="mr-3 mb-2">
              <div
                className=" ml-2 text-grey-dark flex hover:scale-110 hover:cursor-pointer transition-transform duration-300 ease-in-out"
                onClick={() => navigate("/profile")}
              >
                {profileInfo?.avt ? (
                  <img
                    className="w-10 h-10 rounded-full border border-gray-300 "
                    src={profileInfo?.avt}
                    alt="avatar"
                  />
                ) : (
                  <span className="bg-[#F79009] w-10 h-10 font-bold  text-center rounded-full flex items-center justify-center text-[#007EA7">
                    {profileInfo &&
                      profileInfo?.first_name.substring(0, 2).toUpperCase()}
                  </span>
                )}
                <div className="flex items-center justify-center ml-2 text-xl">
                  My Profile
                </div>
              </div>
            </li>

            <li className="mr-3 mb-2 lg:mb-0">
              <Link
                className="flex text-grey-dark no-underline hover:text-grey-lighter hover:text-underline hover:scale-110 transition-transform duration-300 ease-in-out py-2 px-2"
                to="/userfeed"
              >
                <UserFeed />
                <p className="block lg:hidden text-xl ml-4">User Feed</p>
              </Link>
            </li>
            <li className="mr-3 mb-2 lg:mb-0">
              <a
                className="py-2 px-2 text-black no-underline hover:scale-110 transition-transform duration-300 ease-in-out flex"
                href="https://mytexturecare.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <StoreFront />
                <p className="block lg:hidden text-xl ml-4">Online Shop</p>
              </a>
            </li>

            <li className="mr-3 mb-2 lg:mb-0">
              <Link
                className="flex text-grey-dark no-underline hover:text-grey-lighter hover:text-underline hover:scale-110 transition-transform duration-300 ease-in-out py-2 px-2"
                to="/product"
              >
                <Package />
                <p className="block lg:hidden text-xl ml-4">Products</p>
              </Link>
            </li>
            <li className="mr-3 mb-2 lg:mb-0">
              <Link
                className="flex text-grey-dark no-underline hover:text-grey-lighter hover:text-underline hover:scale-110 transition-transform duration-300 ease-in-out py-2 px-2"
                to="/discussion"
              >
                <UsersThree />
                <p className="block lg:hidden text-xl ml-4">Community Posts</p>
              </Link>
            </li>
            <li className="mr-3 mb-2 lg:mb-0">
              <Link
                className="flex text-grey-dark no-underline hover:text-grey-lighter hover:text-underline hover:scale-110 transition-transform duration-300 ease-in-out py-2 px-2 relative"
                to="/notification"
              >
                <Notification />
                <span className="inline-block w-3 h-3 rounded-xl bg-[#6EBED5] border border-[#000] absolute left-6"></span>
                <p className="block lg:hidden text-xl ml-4">Notifications</p>
              </Link>
            </li>
            <li className="mr-3 mb-3 lg:mb-0">
              <div
                className="flex items-center pb-3 mb-2 border-b hover:cursor-pointe px-6"
                onClick={signOut}
              >
                <LogoutIcon />
                <p className="inline-block lg:hidden text-xl ml-5">Log out</p>
              </div>
            </li>
            <li>
              <Link
                className="mb-2 hover:cursor-pointer text-center"
                onClick={() => handleOpenPdf(TERMS_AND_CONDITIONS)}
              >
                Terms and Conditions
              </Link>
            </li>
            <li>
              <Link
                className="mb-2 hover:cursor-pointer text-center"
                onClick={() => handleOpenPdf(PRIVACY_NOTICE)}
              >
                Privacy Policy
              </Link>
            </li>
          </ul>
        </div>
      </nav>

      <div className="container relative shadow-lg mx-auto bg-white py-1 flex lg:hidden justify-between px-4">
        {isClicked.openUploadModal ||
        isClicked.openDiscussionUploadModal ||
        isClicked.openProductUploadModal ||
        discussionPostPage ||
        imagePostPage ||
        productReviewPage ||
        profilepage ? (
          ""
        ) : (
          <SolidButton
            text="Post content"
            onClick={() => {
              handleClick("postContentBtn");
            }}
            className="w-[45%] text-white rounded-3xl px-4 mb-2 hover:scale-110 transition-transform duration-300 ease-in-out"
          />
        )}

        {isClicked.openUploadModal ||
        isClicked.openDiscussionUploadModal ||
        isClicked.openProductUploadModal ||
        discussionPostPage ||
        productReviewPage ||
        imagePostPage ||
        profilepage ? (
          ""
        ) : (
          <div
            className="w-[45%] py-2 px-3 mb-2 no-underline hover:scale-110 transition-transform duration-300 ease-in-out flex justify-center text-xl text-black border bg-[#FCFCFD] border-[#D0D5DD] rounded-3xl"
            onClick={filterOption}
          >
            Filter <Filter className="mt-1 ml-3" />
          </div>
        )}
      </div>

      {/* Filter Menu */}
      <div
        className={`Filtermenu w-[90%] absolute top-[10rem] left-0 right-0 m-auto lg:m-0 lg:left-auto lg:top-[5rem] lg:right-[16rem] z-30 p-4 shadow-lg rounded-xl bg-white lg:w-56 ${isFilterOpen ? "block" : "hidden"}`}
      >
        <form onSubmit={handleSubmit}>
          <div className="hairOptions mb-2">
            <div
              className="wrap flex items-center justify-between pb-2 hover:cursor-pointer"
              onClick={() => setHairOptionOpen(!hairOptionOpen)}
            >
              <p>Hair type</p>
              <div>
                {hairOptionOpen ? (
                  <CaretRight className="w-4 h-3" />
                ) : (
                  <CaretUp className="w-4 h-3" />
                )}
              </div>
            </div>
            <div
              className={`options flex flex-col ml-4 ${hairOptionOpen ? "hidden" : "block"}`}
            >
              {hairType.map((label, index) => (
                <div key={index}>
                  <input
                    type="checkbox"
                    id={`checkbox-${index}`}
                    className="peer accent-[#007EA7]"
                    name={`hairType-${label}`}
                    value={label}
                    checked={formState.hairType[label]}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor={`checkbox-${index}`}
                    className=" peer-checked:text-[#007EA7] text-sm text-black py-1 px-2 ml-1"
                  >
                    {label}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="productOptions mb-2">
            <div
              className="wrap flex items-center justify-between pb-2 hover:cursor-pointer"
              onClick={() => setProductOptionOpen(!productOptionOpen)}
            >
              <p>Product type</p>
              <div>
                {productOptionOpen ? (
                  <CaretRight className="w-4 h-3" />
                ) : (
                  <CaretUp className="w-4 h-3" />
                )}
              </div>
            </div>
            <div
              className={`options flex flex-col ml-4 ${productOptionOpen ? "hidden" : "block"}`}
            >
              {productType.map((label, index) => (
                <div key={index}>
                  <input
                    type="checkbox"
                    id={`${index}`}
                    className="peer accent-[#007EA7]"
                    name={`productType-${label}`}
                    value={label}
                    checked={formState.productType[label]}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor={`${index}`}
                    className=" peer-checked:text-[#007EA7] text-sm text-black py-1 px-2 ml-1"
                  >
                    {label}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="pb-4">
            <input
              type="checkbox"
              id="trend"
              className="peer hidden accent-[#007EA7]"
              name="trending"
              value="Trending topics"
            />
            <label
              htmlFor="trend"
              className=" peer-checked:text-[#007EA7] text-black py-1 "
            >
              Trending topics
            </label>
          </div>
          <SolidButton
            text="View Results"
            type="submit"
            className="text-white w-full text-sm rounded-3xl px-4 py-[7px] hover:scale-110 transition-transform duration-300 ease-in-out"
          />
        </form>
      </div>

      {/* Notification Menu */}
      <div
        className={`notification-wrap absolute bg-white right-[1rem] w-[30rem] z-30 p-4 shadow-lg rounded-xl ${notificationMenu ? "block" : "hidden"} `}
      >
        <Notifications limit={true} />
      </div>

      {/* User Dropdown */}
      <div
        className={`modal fixed z-40 top-0 w-full h-screen bg-black opacity-50 inset-0 ${logOutCheck ? "block" : "hidden"}`}
        onClick={signOut}
      ></div>
      {userDropDown && (
        <div
          className={`Filtermenu w-[90%] absolute top-[10rem] left-0 right-0 m-auto lg:m-0 lg:left-auto lg:top-[5rem] lg:right-[1rem] z-30 p-4 shadow-lg rounded-xl bg-white lg:w-56 `}
        >
          <div
            className="flex items-center mb-2 hover:cursor-pointer"
            onClick={() => navigate("/profile")}
          >
            <UserIcon />
            <p className="ml-2">My Profile</p>
          </div>
          <div
            className="flex items-center pb-3 mb-2 border-b hover:cursor-pointer"
            onClick={signOut}
          >
            <LogoutIcon />
            <p className="ml-2">Log out</p>
          </div>
          <div
            className="mb-2 hover:cursor-pointer"
            onClick={() => handleOpenPdf(TERMS_AND_CONDITIONS)}
          >
            Terms and Conditions
          </div>
          <div
            className="mb-2 hover:cursor-pointer"
            onClick={() => handleOpenPdf(PRIVACY_NOTICE)}
          >
            Privacy Policy
          </div>
          <div></div>
        </div>
      )}

      {/* Logout Check  */}
      {logOutCheck && (
        <div className="w-[90%] lg:w-[30%] h-fit fixed top-[4rem] inset-0 m-auto z-50 shadow-lg rounded-md bg-white p-4">
          <p className="text-xl font-bold font-serif mb-3">Logout</p>
          <p className="mb-3">
            Are you sure you want to log out? If yes, you can always sign back
            in.
          </p>
          <SolidButton
            onClick={() => {
              window.localStorage.removeItem("validationToken");
              setRole(null);
              setUserId(null);
              navigate("/sign-in");
              signOut();
            }}
            className="w-full text-white mb-4"
            text={"Log out"}
          />
          <OutlineButton
            className="w-full outline-offset-0 border py-2 px-2 rounded"
            text={"Cancel"}
            onClick={signOut}
          />
        </div>
      )}
    </div>
  );
};

export default DashboardHeader;
