import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useValidateRequest } from "../../contexts/ValidateRequest";

// Assets
// import ShareIcon from "../icons/ShareIcon";
import { ReactComponent as CommentIcon } from "../../assets/icons/comment_icon.svg";
import { ReactComponent as ShareIcon } from "../../assets/icons/share_icon.svg";
import { ReactComponent as ArrowUp } from "../../assets/icons/arrowup_icon.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrowdown_icon.svg";

import { TbArrowBigUpFilled, TbArrowBigDownFilled } from "react-icons/tb";
import { useStateContext } from "../../contexts/ContextProviders";

// import ChatCircle from "../icons/ChatCircle";
// import GreenArrowUp from "../icons/GreenArrowUp";
// import RedArrowDown from "../icons/RedArrowDown";
// import ProductImage from "../../assets/images/product_image.jpeg";

const ProductCard = ({ product }) => {
  const [iscopied, setIscopied] = useState(false);
  const [userVoteStatus, setUserVoteStatus] = useState(
    product?.metrics?.user_has_voted
  );
  const [like, setLike] = useState(product?.metrics?.upvote_count);
  const [unlike, setUnlike] = useState(product?.metrics?.downvote_count);

  const UserImg =
    "https://imagedelivery.net/Tk7nDsSjmVd54VkZiW6IyA/ee43e1e6-4c51-41ab-9c9a-fe626d186700/public";

  const navigate = useNavigate();
  const { role } = useStateContext();
  const { axiosApiCall } = useValidateRequest();

  const copyToClipboard = async (id) => {
    let baseUrl = `${window.location.origin}`;

    try {
      baseUrl += `/product/${id}`;
      await navigator.clipboard.writeText(baseUrl);
      setIscopied(true);
      setTimeout(() => {
        setIscopied(false);
      }, 1000);
      // console.log('Text copied to clipboard', baseUrl);
    } catch (error) {
      console.error("Error copying text: ", error);
    }
  };

  // Handles Vote
  const handleVote = async (id, type, action, updateFunction) => {
    try {
      const data = new FormData();
      data.set("target_object_id", id);
      data.set("target_type", type);
      data.set("target_user_id", product.user_id);

      const response = await axiosApiCall(
        action,
        "post",
        data,
        "multipart/form-data"
      );
      if (response.status === 200) {
        updateFunction(id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // This function upvotes a post
  const upVotePost = (id) =>
    handleVote(id, product.type, "vote", getSinglePost);
  const downVotePost = (id) =>
    handleVote(id, product.type, "devote", getSinglePost);

  const getSinglePost = async (id) => {
    try {
      const params = { include_metrics: true };
      const { data } = await axiosApiCall(`post/${id}`, "get", params);
      setLike(data?.data?.metrics?.upvote_count);
      setUnlike(data?.data?.metrics?.downvote_count);
      setUserVoteStatus(data?.data?.metrics?.user_has_voted);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="relative flex w-full max-w-xs flex-col">
      <span className="bg-[#A1F783] border-[#74E358] w-[5rem] rounded-xl p-[3px] text-sm absolute top-[1rem] right-[1rem] text-center">
        {product.type}
      </span>
      <div onClick={() => navigate(`/product/${product.id}`)}>
        <img
          src={product.url === null ? UserImg : product.url}
          alt={product.title}
          className="w-full rounded-md lg:h-[10rem] object-cover"
        />
        <div className="mt-2 text-left pl-2 absolute bottom-[2rem] text-white text-sm bg-gradient-to-b from-[#00000000] to-black w-full h-[2rem] rounded-b-lg">
          {product.username}
        </div>
      </div>
      <div className="flex items-center justify-between mt-2">
        <div className="vote-wrap flex items-center justify-between w-[35%]">
          <div className="flex items-center space-x-1 text-green-500">
            {userVoteStatus === 0 ? (
              <ArrowUp className="w-4" onClick={() => upVotePost(product.id)} />
            ) : userVoteStatus === 1 ? (
              <TbArrowBigUpFilled className="w-4" />
            ) : (
              <ArrowUp className="w-4" onClick={() => upVotePost(product.id)} />
            )}
            <span className="ml-1">{like}</span>
          </div>
          <div className="flex items-center space-x-1 text-red-500">
            {userVoteStatus === 0 ? (
              <ArrowDown
                className="w-4"
                onClick={() => downVotePost(product.id)}
              />
            ) : userVoteStatus === -1 ? (
              <TbArrowBigDownFilled className="w-4" />
            ) : (
              <ArrowDown
                className="w-4"
                onClick={() => downVotePost(product.id)}
              />
            )}
            <span className="ml-1">{unlike}</span>
          </div>
        </div>
        <div className="share-wrap flex justify-between w-[35%]">
          <div className="comment flex text-[#419fbd]">
            <CommentIcon className="w-4" />
            <span className="ml-1">
              {role === "ADMIN" && product?.metrics?.comment_count}
            </span>
          </div>
          <div
            className="flex items-center space-x-1 text-red-500"
            onClick={() => copyToClipboard(product.id)}
          >
            <ShareIcon className="w-4" />
          </div>
        </div>
        {/* Copy notification. It shows when a user clicks the share icon  */}
        {iscopied && (
          <span className="text-[var(--Cobalt-Blue)] text-xs absolute bg-[#e6e2dffd] z-10 p-2 rounded-lg bottom-8 lg:bottom-0 right-0 lg:right-[-4.5rem]">
            Copied!
          </span>
        )}
      </div>
    </div>
  );
};

export default ProductCard;
