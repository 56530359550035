import React, { useState, useEffect } from "react";
import ShareIcon from "../icons/ShareIcon";
import ChatCircle from "../icons/ChatCircle";
import ReviewProductModal from "../../components/modals/ReviewProductModal";
import { useStateContext } from "../../contexts/ContextProviders";
import Skeleton from "react-loading-skeleton";

// Assets
import { MdOutlineDelete } from "react-icons/md";
import ProductImage from "../../assets/images/product_img.png";
import { ReactComponent as ArrowFatDown } from "../../assets/icons/ArrowFatDown.svg";

const ProductReviewCard = ({
  imgSrc,
  contentType,
  content,
  upvotes,
  downvotes,
  reviews,
  id,
  getComment,
  refreshSinglePost,
  username,
  isAdminProfle,
  userId,
}) => {
  const [iscopied, setIscopied] = useState(false);
  const { role, handleClick, setProductInfo, setDeleteProductID } =
    useStateContext();
  // const { axiosApiCall } = useValidateRequest();

  const copyToClipboard = async () => {
    let baseUrl = `${window.location.href}`;
    try {
      await navigator.clipboard.writeText(baseUrl);
      setIscopied(true);
      setTimeout(() => {
        setIscopied(false);
      }, 1000);
    } catch (error) {
      console.error("Error copying text: ", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="relative flex w-full flex-col overflow-hidden rounded-lg pb-8 lg:pb-0">
      <div className="relative mx-3 flex  overflow-hidden rounded-xl">
        {imgSrc ? (
          <img
          className="object-cover lg:h-[350px] w-full"
          src={imgSrc ? imgSrc : ProductImage}
          alt="product"
        />
        ) : (
          <Skeleton
            height={100}
            className="w-full h-full"
          />
        )}

<span className="absolute top-0 right-0 m-2 rounded-full px-2 text-center font-medium text-black bg-[#A1F783]">
          {contentType ? contentType : "Products"}
        </span>
        <div className="absolute bottom-0 left-0 right-0 p-2 bg-black bg-opacity-50">
          <h5 className="text-1xl tracking-tight text-white">{username}</h5>
        </div>
        {role === "ADMIN" && (
          <button
            onClick={() => {
              handleClick("deleteProductPost");
              setProductInfo({
                productDescription: content,
                productImgSrc: imgSrc ? imgSrc : ProductImage,
              });
              setDeleteProductID(id);
            }}
            className="absolute bottom-1 right-2 w-8 h-8 p-1 rounded-full bg-gray-100/20"
          >
            <MdOutlineDelete className="w-full h-full text-gray-200 hover:text-gray-50" />
          </button>
        )}
      </div>
      <div className="px-5 pb-5">
        <div className="mt-2 mb-5 flex items-center justify-between relative">
          <div className="flex space-x-2 lg:space-x-4 mt-1">
            <div className="flex items-center space-x-2">
              {!isAdminProfle && (
                <ReviewProductModal
                  getComment={getComment}
                  refreshSinglePost={refreshSinglePost}
                />
              )}
              <span className="text-[var(--Cobalt-Blue)]">{upvotes || 0}</span>
            </div>
            <div className="flex items-center space-x-2">
              <ArrowFatDown className="" />
              <span className="text-[var(--Cobalt-Blue)]">
                {downvotes || 0}
              </span>
            </div>

            <div className="flex items-center space-x-2">
              <ChatCircle />
              <span className="text-[var(--Cobalt-Blue)]">
                {reviews || 0} reviews
              </span>
            </div>
          </div>
          <div
            className="flex items-center space-x-4"
            onClick={copyToClipboard}
          >
            <ShareIcon />
          </div>
          {/* Copy notification. It shows when a user clicks the share icon  */}
          {iscopied && (
            <span className="text-[var(--Cobalt-Blue)] text-xs absolute bg-[#e6e2dffd] z-10 p-2 rounded-lg bottom-8 right-0 lg:bottom-0 lg:right-[-0.5rem]">
              Copied!
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductReviewCard;
