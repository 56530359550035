/**
 * Comment thread component
 *
 * This renders the first layer comments alongside upvotes and downvotes
 * with also, the inner replies (second layer comment)
 *
 * Dummy votes states, this would be pulled from DB and onClick
 * will update the DB with +1 (upvotes or downvotes)
 */

import React, { useState } from "react";
import { useStateContext } from "../../contexts/ContextProviders";
import { useValidateRequest } from "../../contexts/ValidateRequest";

import { FaRegComment } from "react-icons/fa";
import { TbArrowBigDown, TbArrowBigUp } from "react-icons/tb";
import { TbArrowBigUpFilled, TbArrowBigDownFilled } from 'react-icons/tb';
import { RxCaretDown, RxCaretUp } from "react-icons/rx";
import { MdOutlineDelete } from "react-icons/md";

import InnerComment from "./InnerComment";
import InnerCommentInput from "./InnerCommentInput";
import DeleteComment from "../../components/modals/DeleteComment";
import { Link } from "react-router-dom";
// import ModalContainer from "../../components/modals/ModalContainer";

const CommentThread = ({
  // commentNumber,
  comment,
  commentId,
  postId,
  userId,
  username,
  like,
  unlike,
  votingStatus,
  avatarFallback,
  setComments,
  setReplies,
  nextLevelComments,
  getComment,
  isProductPage,
  isAdminProfile,
  adminProductId,
  commentUserId,
}) => {
  const [nextLayer, setNextLayer] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const { role, userId: globalUserId } = useStateContext();
  const { axiosApiCall } = useValidateRequest();

  //toggles the opening of inner layer comments
  const handleOpenNextLayerComments = (e) => {
    e.preventDefault();
    setNextLayer((prev) => !prev);
  };

  // Handles Comments Vote
  const handleVote = async (id, type, action, updateFunction) => {
    try {
      const data = new FormData();
      data.set("target_object_id", id);
      data.set("target_type", type);
      data.set("target_user_id", userId);

      const response = await axiosApiCall(
        action,
        "post",
        data,
        "multipart/form-data"
      );
      if (response.status === 200) {
        updateFunction();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // This function upvotes a post
  const upVoteComment = (id) =>
    handleVote(id, "comment", "vote", getPostComments);
  const downVoteComment = (id) =>
    handleVote(id, "comment", "devote", getPostComments);

  const getPostComments = async () => {
    const params = {
      page: 1,
      limit: 1000,
      target_id: postId,
      include_metrics: true,
    };
    try {
      const getCommentResponse = await axiosApiCall("comment", "get", params);
      setComments(getCommentResponse.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  // console.log('upvotes',upvotes)
  // console.log('downVotes',downVotes)
  return (
    <div className="flex flex-col gap-y-4">
      {isClicked && (
        <DeleteComment
          commentId={commentId}
          comment={comment}
          getComment={getComment}
          avatarFallback={avatarFallback}
          username={username}
          setIsClicked={setIsClicked}
        />
      )}

      <div className="flex items-start">
        <Link
          to={
            commentUserId && globalUserId === commentUserId
              ? "/profile"
              : `/profile/${commentUserId}`
          }
          className="h-8 w-8 flex items-center justify-center rounded-full bg-gray-400 p-2 text-gray-700 font-bold text-sm mr-2"
        >
          {avatarFallback}
        </Link>

        <div className="flex flex-col gap-y-2 py-2 px-4 border-2 border-[#007EA7]/10 rounded-lg hover:shadow-sm hover:bg-gray-200/10 w-full">
          <Link
            to={
              commentUserId && globalUserId === commentUserId
                ? "/profile"
                : `/profile/${commentUserId}`
            }
            className="font-semibold text-sm"
          >
            {username}
          </Link>
          <p className="font-normal text-sm">{comment}</p>

          <div className="flex items-center gap-x-6 text-gray-500 font-light w-full">
            {!isProductPage && (
              <button
                className="flex items-center gap-x-1 opacity-80 hover:opacity-100"
              >
                {votingStatus === 0 ? (<TbArrowBigUp className="w-6 h-8" onClick={() => upVoteComment(commentId)}/>) : (votingStatus === 1) ? (<TbArrowBigUpFilled className="w-6 h-8"/>) : (<TbArrowBigUp className="w-6 h-8" onClick={() => upVoteComment(commentId)}/>)}
                <p className="font-semibold text-sm">{like}</p>
              </button>
            )}
            {!isProductPage && (
              <button
                className="flex items-center gap-x-1 opacity-80 hover:opacity-100"
              >
                 { votingStatus === 0 ? (<TbArrowBigDown className="w-6 h-8" onClick={() => downVoteComment(commentId)}/>) : (votingStatus === -1) ? (<TbArrowBigDownFilled className="w-6 h-8"/>) : (<TbArrowBigDown className="w-6 h-8" onClick={() => downVoteComment(commentId)}/>)}
                <p className="font-semibold text-sm">{unlike}</p>
              </button>
            )}
            {role !== "ADMIN" && (
              <button
                onClick={handleOpenNextLayerComments}
                className="flex items-center gap-x-1 opacity-80 hover:opacity-100"
              >
                <FaRegComment className="w-6 h-8" />
                <p className="font-semibold text-sm">
                  {nextLevelComments?.length || 0} replies
                </p>
                {!nextLayer ? (
                  <RxCaretDown className="h-5 w-5" />
                ) : (
                  <RxCaretUp className="h-5 w-5" />
                )}
              </button>
            )}

            {role === "ADMIN" && (
              <div className="flex items-center gap-x-6">
                <button
                  onClick={() => setIsClicked(true)}
                  className="flex items-center gap-x-1 opacity-80 hover:opacity-100 -ml-2"
                >
                  <MdOutlineDelete className="w-6 h-8" />
                  <p className="font-semibold text-sm">
                    {isProductPage ? "Delete Review" : "Delete Comment"}
                  </p>
                </button>
                <button
                  onClick={handleOpenNextLayerComments}
                  className="flex items-center gap-x-1 opacity-80 hover:opacity-100"
                >
                  <FaRegComment className="w-6 h-8" />
                  <p className="font-semibold text-sm">{nextLevelComments?.length || 0} Replies</p>
                  {!nextLayer ? (
                    <RxCaretDown className="h-5 w-5" />
                  ) : (
                    <RxCaretUp className="h-5 w-5" />
                  )}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {nextLayer && (
        <div className="ml-14 md:ml-20">
          {isProductPage && role === "MEMBER" ? (
            ""
          ) : (
            <InnerCommentInput
              getComment={getComment}
              isAdminProfile={isAdminProfile}
              adminProductId={adminProductId}
              parent_id={commentId}
            />
          )}
          {nextLevelComments?.map((comments) => (
              <InnerComment
                key={comments?.id}
                replyId={comments?.id}
                isAdminProfile={isAdminProfile}
                isProductPage={isProductPage}
                commentId={comments?.id}
                userId={userId}
                replyUserId={comments?.user_id}
                username={comments.title}
                postId={postId}
                comment={comments?.content}
                parent_id={comments?.parent_id}
                like={comments?.metrics?.upvote_count}
                unlike={comments?.metrics?.downvote_count}
                voteStatus={comments?.metrics?.user_has_voted}
                getComment={getComment}
                setReplies={setReplies}
                commentType={comments?.type}
              />
            ))}
        </div>
      )}
    </div>
  );
};

export default CommentThread;
