import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import logo from "../../assets/images/tc-logo-white.png";
import mobileLogo from "../../assets/images/tc-logo-white.png";
import SignInForm from "../../components/auth/SignInForm";

const SignIn = () => {
  return (
    <div className="w-full h-screen relative">
      {/* Backround Image */}
      <div className="w-full h-full fixed -z-10 background bg-mobile-hero md:bg-hero-img bg-center bg-cover" />
      {/* Background Image Ends */}

      {/* Header Section Begins*/}
      <div className="header w-full h-12 p-2 lg:pl-4 flex justify-between items-center sticky top-3 ">
        <a href="/" className="logo">
          <img
            src={mobileLogo}
            alt="Texture Care"
            className="md:hidden w-[8.5rem]"
          />
          <img
            src={logo}
            alt="Texture Care"
            className="hidden md:block md:w-[13rem] lg:w-[11rem]"
          />
        </a>
        <div className="hidden absolute md:flex w-[17rem] justify-center items-center text-center top-3 right-0 z-10 py-4 pl-4 rounded-tl-3xl rounded-bl-3xl h-[3rem] bg-white shadow-lg">
          Don't have an account?{" "}
          <a href="/onboarding" className="ml-2 underline font-semibold">
            Sign up!
          </a>
        </div>

        <div className="flex absolute md:hidden w-[8rem] justify-center items-center text-center top-[6px] right-0 z-10 py-2 pl-3 rounded-tl-3xl rounded-bl-3xl bg-white shadow-lg">
          <a href="/onboarding" className="font-semibold underline">
            Sign up!
          </a>
        </div>
      </div>
      {/* Header Section Ends */}

      {/* Form Section Begins */}
      <div className="">
        <SignInForm />
      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        containerId="signin"
      />
    </div>
  );
};

export default SignIn;
