import React, { useState } from "react";
import { useStateContext } from "../../contexts/ContextProviders";

import { FaCircleCheck } from "react-icons/fa6";
import { MdOutlineRadioButtonUnchecked } from "react-icons/md";

const SelectContentType = () => {
  const { setIsClicked, handleClick, role } = useStateContext();

  const [productPostBox, setProductPostBox] = useState(
    role === "ADMIN" ? true : false
  );
  const [imagePostBox, setImagePostBox] = useState(
    role === "ADMIN" ? false : true
  );
  const [discussionPostBox, setDiscussionPostBox] = useState(false);

  return (
    <div className="flex flex-col gap-y-4 fixed left-[50%] top-[15%] md:top-[50%] z-50 w-[95%] lg:w-[403px] rounded-[16px] p-4 translate-x-[-50%] lg:translate-y-[-50%] gap-4 bg-white duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]">
      <p className="font-[400] text-[20px] leading-8 text-[#000000]">
        Select the type of content you would like to post
      </p>

      {role === "ADMIN" && (
        <div
          onClick={() => {
            setImagePostBox(false);
            setDiscussionPostBox(false);
            setProductPostBox(true);
          }}
          className={
            !productPostBox
              ? "w-full flex items-center justify-between h-12 rounded px-3 border border-[#F2F9FB] bg-[#F2F9FB] cursor-pointer"
              : "w-full flex items-center justify-between h-12 rounded px-3 border border-[#007EA7] bg-[#F2F9FB] cursor-pointer"
          }
        >
          <p className="text-[#101828] text-[16px] leading-4 font-[400]">
            Product
          </p>
          {productPostBox ? (
            <FaCircleCheck className="text-white fill-[#007EA7] h-6 w-6" />
          ) : (
            <MdOutlineRadioButtonUnchecked className="h-6 w-6 text-[#EAECF0]" />
          )}
        </div>
      )}

      <div
        onClick={() => {
          setDiscussionPostBox(false);
          setProductPostBox(false);
          setImagePostBox(true);
        }}
        className={
          !imagePostBox
            ? "w-full flex items-center justify-between h-12 rounded px-3 border border-[#F2F9FB] bg-[#F2F9FB] cursor-pointer"
            : "w-full flex items-center justify-between h-12 rounded px-3 border border-[#007EA7] bg-[#F2F9FB] cursor-pointer"
        }
      >
        <p className="text-[#101828] text-[16px] leading-4 font-[400]">
          Image post
        </p>
        {imagePostBox ? (
          <FaCircleCheck className="text-white fill-[#007EA7] h-6 w-6" />
        ) : (
          <MdOutlineRadioButtonUnchecked className="h-6 w-6 text-[#EAECF0]" />
        )}
      </div>

      <div
        onClick={() => {
          setImagePostBox(false);
          setProductPostBox(false);
          setDiscussionPostBox(true);
        }}
        className={
          !discussionPostBox
            ? "w-full flex items-center justify-between h-12 rounded px-3 border border-[#F2F9FB] bg-[#F2F9FB] cursor-pointer"
            : "w-full flex items-center justify-between h-12 rounded px-3 border border-[#007EA7] bg-[#F2F9FB] cursor-pointer"
        }
      >
        <p className="text-[#101828] text-[16px] leading-4 font-[400]">
          Discussion post
        </p>
        {discussionPostBox ? (
          <FaCircleCheck className="text-white fill-[#007EA7] h-6 w-6" />
        ) : (
          <MdOutlineRadioButtonUnchecked className="h-6 w-6 text-[#EAECF0]" />
        )}
      </div>

      <button
        type="button"
        onClick={() =>
          (imagePostBox && handleClick("openUploadModal")) ||
          (discussionPostBox && handleClick("openDiscussionUploadModal")) ||
          (productPostBox && handleClick("openProductUploadModal"))
        }
        className="h-12 w-full text-center text-[#FCFCFD] bg-[var(--Cobalt-Blue)] hover:bg-[#006080] rounded font-[400]"
      >
        Continue
      </button>

      <button
        type="button"
        onClick={() => setIsClicked({ postContentBtn: false })}
        className="h-12 w-full text-center text-[#007EA7] rounded font-[400] bg-gray-600/10 hover:bg-gray-500/10"
      >
        Cancel
      </button>
    </div>
  );
};

export default SelectContentType;
