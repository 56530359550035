import React, { useState } from "react";
// import Logo from "../../assets/icons/logo.svg";
import Logo from "../../assets/images/tc-logo.png";
import SolidButton from "../buttons/SolidButton";
import { useNavigate, Link } from "react-router-dom";
import { isLoggedIn } from "../../utils/auth";
import { useStateContext } from "../../contexts/ContextProviders";
import { ReactComponent as LoginIcon } from "../../assets/icons/login_icon.svg";
import { ReactComponent as BlogIcon } from "../../assets/icons/blog_icon.svg";
import { ReactComponent as UsersThree } from "../../assets/icons/users_three.svg";
import { ReactComponent as StoreFront } from "../../assets/icons/store_front.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout_icon.svg";
import { ReactComponent as CancelIcon } from "../../assets/icons/cancel_icon.svg";
import { ReactComponent as MenuIcon } from "../../assets/icons/icons8-menu-button-30.svg";

const NavigationBar = () => {
  const userSignedIn = isLoggedIn();
  const [isOpen, setIsOpen] = useState(false);
  const [logOutCheck, setLogOutCheck] = useState(false);

  const navigate = useNavigate();
  const { isClicked } = useStateContext();

  const signOut = () => {
    console.log('signOut');
    setLogOutCheck(!logOutCheck);
    setIsOpen(false);
  };

  return (
    <div>
      <header className="bg-white sticky top-0 z-20 px-4">
        <div className="container mx-auto flex justify-between items-center py-4">
          {/* Left section: Logo */}
          <a href="/" className="flex items-center">
            <img className="w-24 sm:w-32 md:w-24 lg:w-28 xl:w-32 max-w-full h-auto" src={Logo} alt="texture care logo" />
          </a>



          {/* Hamburger menu (for mobile) */}
          <div className="flex md:hidden">
            <button
              className="w-12 h-12 flex items-center justify-center border rounded text-grey border-grey-dark transition duration-300 ease-in-out"
              disabled={isClicked.openUploadModal}
              onClick={() => setIsOpen(!isOpen)}
            >
              <MenuIcon />
            </button>
          </div>
          {/* Center section: Menu */}
          <div className="mx-auto bg-[#E2F3F6] right-4 max-w-[250px] rounded-lg py-5 shadow-lg lg:static lg:block lg:max-w-full lg:px-4 lg:py-0 lg:shadow-none xl:px-6 lg:flex lg:justify-center hidden">
            <nav className="hidden md:flex md:flex-grow justify-center">
              <ul className="flex justify-center space-x-4 text-white p-4">
                <li>
                  <Link
                    to="/" className="text-[#007EA7] font-medium px-2">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/about-us" className="text-[#007EA7] font-medium px-2">
                    About us
                  </Link>
                </li>
                <li>
                  <a href="https://mytexturecare.com/"
                    target="_blank"
                    rel="noopener noreferrer" className="text-[#007EA7] font-medium px-2">
                    Shop
                  </a>
                </li>
                <li>
                  <a href="https://mytexturecare.com/blogs/news"
                    target="_blank" rel="noopener noreferrer" className="text-[#007EA7] font-medium px-2">
                    Blog
                  </a>
                </li>
                <li>
                  <a href="mailto:hello@texturecare.co.uk" className="text-[#007EA7] font-medium px-2">
                    Contact us
                  </a>

                </li>
              </ul>
            </nav>
          </div>
          {/* Right section: Buttons (for desktop) */}
          <div className="hidden lg:flex items-center space-x-4">

            {
              userSignedIn ?
                <SolidButton
                  text="User Feed"
                  onClick={() => {
                    navigate("/userfeed")
                  }}
                  className="hidden lg:block text-white rounded-3xl px-4 hover:scale-110 transition-transform duration-300 ease-in-out"
                />
                :
                <div>
                  <Link
                    to="/sign-in"
                    className="px-[22px] py-2 text-base font-medium px-2 text-[#007EA7]"
                  >
                    Log In
                  </Link>
                  <Link
                    to="/onboarding"
                    className="rounded-md px-6 py-2 text-base font-medium px-2 text-white bg-[#007EA7]"
                  >
                    Sign Up
                  </Link>
                </div>
            }
          </div>
        </div>
      </header>
      {/* Mobile menu */}
      <nav
        className={`z-50 rounded-xl ${isOpen ? "flex" : "hidden"
          }`}
      >
        <ul className="lg:hidden justify-end flex-1 items-center p-4 rounded-xl">
          <CancelIcon
            className="cancel-btn absolute right-[1rem]"
            onClick={() => setIsOpen(false)}
          />
          {userSignedIn ? (<li className="mr-3 mb-2">
            <div
              className=" ml-2 text-grey-dark flex hover:scale-110 hover:cursor-pointer transition-transform duration-300 ease-in-out"
              onClick={() => navigate("/profile")}
            >
              <span className="bg-[#F79009] w-10 h-10 font-medium px-2  text-center rounded-full flex items-center justify-center text-[#007EA7">
                LT
              </span>
              <div className="flex items-center justify-center ml-2 text-xl">
                My Profile
              </div>
            </div>
          </li>
          ) : (
            <></>
          )}

          <li className="mr-3 mb-2 lg:mb-0">
            <Link
              className="py-2 px-2 text-black no-underline hover:scale-110 transition-transform duration-300 ease-in-out flex"
              to="/about-us"
            >
              <UsersThree />
              <p className="block lg:hidden text-xl ml-4">About Us</p>
            </Link>
          </li>

          <li className="mr-3 mb-2 lg:mb-0">
            <a
              className="py-2 px-2 text-black no-underline hover:scale-110 transition-transform duration-300 ease-in-out flex"
              href="https://mytexturecare.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StoreFront />
              <p className="block lg:hidden text-xl ml-4">Online Shop</p>
            </a>
          </li>
          <li className="mr-3 mb-2 lg:mb-0">
            <a
              className="flex text-grey-dark no-underline hover:text-grey-lighter hover:text-underline hover:scale-110 transition-transform duration-300 ease-in-out py-2 px-2"
              href="https://mytexturecare.com/blogs/news"
              target="_blank" rel="noopener noreferrer"
            >
              <BlogIcon />
              <p className="block lg:hidden text-xl ml-4">Blog </p>
            </a>
          </li>
          <li className="mr-3 mb-3 lg:mb-0">
            {userSignedIn ? (
              <div
                className="flex items-center pb-3 mb-2 hover:cursor-pointe px-6"
                onClick={signOut}
              >
                <LogoutIcon />
                <p className="inline-block lg:hidden text-xl ml-5">Log out</p>
              </div>
            ) : (
              <div
                className="flex items-center pb-3 mb-2 hover:cursor-pointe px-6"
                onClick={() => navigate("/sign-in")}
              >
                <LoginIcon />
                <p className="inline-block lg:hidden text-xl ml-5">Log In</p>
              </div>
            )}
          </li>
          {userSignedIn ? (
            <></>
          ) : (
            <li className="text-center">
              <Link
                to="/onboarding"
                className="text-xl rounded-md px-6 py-2 text-base font-medium px-2 text-white bg-[#007EA7]"
              >
                Sign Up
              </Link>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default NavigationBar;