/**
 * Discussion Feed Page
 *
 * Supposed to be routed from the user feed page via the post ID
 * /discussion -> /discussion/id_839dsj392w....
 *
 * Responsible for displaying the discussions going on in a particular
 * posts, via comments and replies.
 * Also, related products that routes to a market place for purchase
 */

import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useStateContext } from "../../contexts/ContextProviders";
import { TbArrowBigDown, TbArrowBigUp } from "react-icons/tb";
import { TbArrowBigUpFilled, TbArrowBigDownFilled } from 'react-icons/tb';
import { FaRegComment } from "react-icons/fa";
import { BiShare } from "react-icons/bi";
import { MdOutlineDelete } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import DeleteDiscussionPost from "../../components/modals/DeleteDiscussionPost";
import CommentThread from "../../components/comments/CommentThread";
import CommentInput from "../../components/comments/CommentInput";
import RelatedProducts from "../../components/RelatedProducts";
import DashboardHeader from "../../components/UserfeedHeader";
import BackButton from "../../components/buttons/BackButton";
import { useValidateRequest } from "../../contexts/ValidateRequest";

const ViewDiscussionPostTest = () => {
  const navigate = useNavigate();
  const { postId } = useParams();
  const [post, setPost] = useState(null);
  const [userVoteStatus, setUserVoteStatus] = useState(post?.metrics?.user_has_voted)
  const [relatedProducts, setRelatedProducts] = useState(null);
  const [comments, setComments] = useState(null);
  const [replies, setReplies] = useState(null);
  const [iscopied, setIscopied] = useState(false);
  const [like, setLike] = useState(false);
  const [unlike, setUnlike] = useState(false);
  const { axiosApiCall } = useValidateRequest();

  const copyToClipboard = async () => {
    let baseUrl = `${window.location.href}`;
    try {
      await navigator.clipboard.writeText(baseUrl);
      setIscopied(true);
      setTimeout(() => {
        setIscopied(false);
      }, 1000);
      // console.log('Text copied to clipboard', baseUrl);
    } catch (error) {
      console.error("Error copying text: ", error);
    }
  };

  // Handles Vote
  const handleVote = async (id, type, action, updateFunction) => {
    try {
      const data = new FormData();
      data.set("target_object_id", id);
      data.set("target_type", type);
      data.set("target_user_id", post.user_id);

      const response = await axiosApiCall(
        action,
        "post",
        data,
        "multipart/form-data"
      );
      if (response.status === 200) {
        updateFunction(id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // This function upvotes a post
  const upVotePost = (id) => handleVote(id, post.type, "vote", getSinglePost);
  const downVotePost = (id) =>
    handleVote(id, post.type, "devote", getSinglePost);

  const getSinglePost = async (postId) => {
    try {
      const params = { include_metrics: true };
      const { data } = await axiosApiCall(`post/${postId}`, "get", params);
      setPost(data.data);
      setLike(data?.data?.metrics?.upvote_count);
      setUnlike(data?.data?.metrics?.downvote_count);
      setUserVoteStatus(data?.data?.metrics?.user_has_voted)
      // console.log(data)
    } catch (error) {
      console.log(error);
    }
  };

  const dateString = post?.created_time;

  // Function to format date the post was created
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const daySuffix = (day) => {
      if (day > 3 && day < 21) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    return `${day}${daySuffix(day)} ${month} ${year}`;
  };

  const formattedDate = formatDate(dateString);

  const getComment = async () => {
    const params = {
      page: 1,
      limit: 100,
      target_id: postId,
      include_metrics: true,
    };
    try {
      const getCommentResponse = await axiosApiCall("comment", "get", params);
      const allComments = getCommentResponse.data.data;
      const firstComments = allComments.filter((comment) => comment.parent_id === null);
      const secondLevelComments = allComments.filter((comment) => comment.parent_id !== null)
      setComments(firstComments);
      setReplies(secondLevelComments);
    } catch (error) {
      console.log(error);
    }
  };

  //get other products
  const getOtherProducts = async () => {
    try {
      const params = { limit: 3, content_type: "product" };
      const { data } = await axiosApiCall("post", "get", params);
      setRelatedProducts(data.data.post);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.scrollTo(0,0)
    getOtherProducts();
    getComment();
    getSinglePost(postId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postId]);

  const { role, handleClick, isClicked, userId } = useStateContext();

  return (
    <section className=" flex flex-col">
      <DashboardHeader discussionPostPage={true} />

      {/* postContentBtn state - This is the entry points to opening the modal container */}
      {isClicked.deleteDiscussionPost && (
        <DeleteDiscussionPost
          postid={postId}
          date={formattedDate}
          postTitle={post?.title}
        />
      )}

      <div className="p-4 lg:ml-10">
        <BackButton onClick={() => navigate(-1)} />
      </div>

      <div className="flex flex-col md:flex-row mt-2 mb-6 gap-x-10 px-6 md:px-16">
        {/* Discussion left pane */}
        <div className="flex flex-col w-full md:w-[75%]">
          <div style={{'--imageUrl': `url(${post?.url})`}} className="relative flex flex-col py-2 px-4 gap-y-6 bg-[#007ea7] bg-cover bg-center bg-[image:var(--imageUrl)] rounded-md mb-6">
            {/* <img src={post?.url} alt="" className="w-full h-full" /> */}
            {post?.url && <div className="overlay absolute top-0 left-0 bg-[#0707078c] w-full h-full rounded-md"></div>}
            <div className="flex justify-between relative">
              <div className="flex items-center gap-x-2 ">
                <Link
                  to={
                    post?.user_id && userId === post?.user_id
                      ? "/profile"
                      : `/profile/${post?.user_id}`
                  }
                  className="h-5 w-5 rounded-full flex items-center justify-center bg-gray-200 text-gray-800 font-bold text-[10px]"
                >
                  {post?.username !== null ? post?.username.slice(0, 2).toUpperCase() : "XX"}
                </Link>
                <div className="h-1 w-1 rounded-full bg-white" />
                <p className="text-[11px] text-white font-light">
                  {formattedDate || ""}
                </p>
              </div>

              {post?.hair_type.length > 0 || post?.product_type.length > 0 ? (
                <span className="p-1 px-2 text-xs bg-yellow-200 text-gray-600 rounded-full">{`${post?.hair_type.map((hair_tag) => " " + hair_tag)}${post?.hair_type.length === 0 ? "" : post?.product_type.length === 0 ? "" : ","} ${post?.product_type.map((product_tag) => " " + product_tag)}`}</span>
              ) : null}
            </div>
            <div className="content wrap relative">
            
                <p className="font-semibold text-white text-center mb-2">
                {post?.title}
                </p>
                <p className="text-white break-words">{post?.content}</p>
            </div>  

            <div className="w-full flex items-center justify-between relative">
              <div className="flex items-center gap-x-4">
                <button
                  className="flex items-center gap-x-1 text-gray-100 hover:text-white"
                >
                  {userVoteStatus === 0 ? (<TbArrowBigUp className="w-6 h-8" onClick={() => upVotePost(post.id)}/>) : (userVoteStatus === 1) ? (<TbArrowBigUpFilled className="w-6 h-8"/>) : (<TbArrowBigUp className="w-6 h-8" onClick={() => upVotePost(post.id)}/>)}
                  <p className="font-semibold ">{like}</p>
                </button>
                <button
                  className="flex items-center gap-x-1 text-gray-100 hover:text-white"
                >
                  { userVoteStatus === 0 ? (<TbArrowBigDown className="w-6 h-8" onClick={() => downVotePost(post.id)}/>) : (userVoteStatus === -1) ? (<TbArrowBigDownFilled className="w-6 h-8"/>) : (<TbArrowBigDown className="w-6 h-8" onClick={() => downVotePost(post.id)}/>)} 
                  <p className="font-semibold">{unlike}</p>
                </button>
                <div className="flex items-center gap-x-1 text-white">
                  <FaRegComment className="w-6 h-8" />
                  <p className="font-semibold">
                    {comments?.length || 0} comment(s)
                  </p>
                </div>
              </div>

              <div className="flex items-center gap-x-2 relative">
                <div
                  className="share text-gray-100 hover:text-white"
                  onClick={copyToClipboard}
                >
                  <BiShare className="w-6 h-8 -scale-x-[1]" />
                </div>
                {role === "ADMIN" && (
                  <button
                    onClick={() => {
                      handleClick("deleteDiscussionPost");
                    }}
                    className="w-8 h-8 p-1 rounded-full bg-red-900/40"
                  >
                    <MdOutlineDelete className="w-full h-full text-red-400 hover:text-red-500" />
                  </button>
                )}
                {iscopied && (
                  <span className="text-[var(--Cobalt-Blue)] text-xs absolute bg-[#e6e2df] z-10 p-2 rounded-lg right-[-1.5rem]">
                    Copied!
                  </span>
                )}
              </div>
            </div>
          </div>

          {/* comment input */}
          <CommentInput getComment={getComment} />

          {/* Commment thread */}
          <div className="flex flex-col gap-y-12 max-h-[32rem] overflow-y-scroll mb-4">
            {comments ? (
              comments?.map((comment) => (
                <CommentThread
                  key={comment.id}
                  commentId={comment.id}
                  commentNumber={comment?.metrics?.comment_count}
                  like={comment?.metrics?.upvote_count}
                  unlike={comment?.metrics?.downvote_count}
                  votingStatus={comment?.metrics?.user_has_voted}
                  userId={post?.user_id}
                  commentUserId={comment?.user_id}
                  postId={post?.id}
                  comment={comment.content}
                  avatarFallback={comment?.title.slice(0, 2).toUpperCase()}
                  username={comment.title}
                  setComments={setComments}
                  setReplies={setReplies}
                  nextLevelComments={
                    replies?.filter(
                      (innerComment) => innerComment?.parent_id === comment.id
                    ) || comment.id
                  }
                  getComment={getComment}
                />
              ))
            ) : (
              <div className="comment mb-4 w-full">
                <Skeleton
                  count={3}
                  height={100}
                  style={{ marginBottom: ".5rem" }}
                />
              </div>
            )}
          </div>
        </div>

        {/* Related products right pane */}
        <div className="flex flex-col">
          <h3 className="text-lg font-semibold mt-12 md:-mt-12 mb-4">
            Related Products
          </h3>
          {/* Data from Userfeed */}
          {relatedProducts ? (
            relatedProducts.map((post, index) => (
              <RelatedProducts key={index} post={post} />
            ))
          ) : (
            <div className="wraps">
              <Skeleton
                count={3}
                height={224}
                style={{ marginBottom: "1rem" }}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ViewDiscussionPostTest;
