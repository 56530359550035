/**
 * API call contexts for the platform, this processes GET, POST, PUT, DELETE
 * requests for communicating with the backend
 *
 * Main method here is the `axiosApiCall{...}`
 * @BASE_URL - This is set from response recieved on logging in from the backend.
 * It sets the environment at which these API calls are made, i.e DEV || STAGING || PROD
 *
 * @config - This params is added inside each of the function call, this is the `token`
 * stored in (localStorage || cookies) on logging in and this also sets the `content-type`
 * of the data been sent, it defaults to `application/json`. The config is sent on every
 * request to validate the API calls via the token.
 *
 * @get - GET - get data from the stipulated URL provided
 * * @uri - Path to where the API call is made to/from i.e /posts or /posts/3edb343s8u
 *
 * @post - POST - make a post request to the stipulated URL provided
 * * @uri - Path to where the API call is made to/from i.e /posts
 * * @data - formData of computed values
 *
 * @update - PUT - make a put request to the stipulated URL provided
 * * @uri - Path to where the API call is made to and/or from i.e posts/6edb343s8u
 * * @data - formData of computed data to be used to update the already existing data
 *
 * @delete - DELETE - make a delete request to the stipulated URL provided
 * * @url - Path to where the API call is made to and/or from /posts/653edb343s8u
 *
 */

import axios from "axios";
import env from "../config/env";

import { createContext, useContext } from "react";
import { useStateContext } from "./ContextProviders";

const validateContextDefaultValues = {
  axiosApiCall: (uri, method, data, contentType) => {},
};

const ValidateContext = createContext(validateContextDefaultValues);

export function useValidateRequest() {
  return useContext(ValidateContext);
}

export function ValidatorProvider({ children }) {
  const { setIsClicked } = useStateContext();

  const excludedUrls = ["user/login", "user/register"]; // Array of excluded URLs

  const axiosApiCall = async (
    uri,
    method,
    data = null,
    contentType = "application/json"
  ) => {
    const token = window.localStorage.getItem("validationToken");

    const config = {
      headers: {
        Authorization: token,
        "Content-Type": contentType,
      },
    };

    const BASE_URL = env.get("API_URL");
    let full_url = `${BASE_URL}/${uri}`;

    try {
      let response;
      switch (method.toLowerCase()) {
        case "get":
          response = await axios.get(full_url, { ...config, params: data });
          break;
        case "post":
          response = await axios.post(full_url, data, config);
          break;
        case "put":
          response = await axios.put(full_url, data, config);
          break;
        case "delete":
          response = await axios.delete(full_url, { ...config, data: data });
          break;
        default:
          throw new Error(`Unsupported request method: ${method}`);
      }
      return response;
    } catch (error) {
      // console.log(error.response);
      if (
        error.response &&
        error.response.status === 401 &&
        !excludedUrls.includes(uri.toLowerCase())
      ) {
        setIsClicked((prevIsClicked) => ({
          ...prevIsClicked,
          isAuthModal: true,
        }));
      } else {
        //some toaster notification here
        throw error;
      }
    }
  };

  const value = {
    axiosApiCall,
  };

  return (
    <ValidateContext.Provider value={value}>
      {children}
    </ValidateContext.Provider>
  );
}
