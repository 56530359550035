import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import { ToastContainer } from "react-toastify";
import { useStateContext } from "./contexts/ContextProviders";

import SignIn from "./pages/authentication/SignIn";
import Profile from "./pages/profile/Profile";
import UserProfile from "./pages/profile/UserProfile";
import EditProfile from "./pages/profile/EditProfile";
import Products from "./pages/products/Products";
import ProductReview from "./pages/products/ProductReview";
import LandingPage from "./pages/homepage/LandingPage";
import Onboarding from "./pages/onboarding/Onboarding";
import OnboardingReturn from "./pages/onboarding/OnboardingReturn";
import DiscussionFeed from "./pages/discussion/UserFeed";
import ViewImagePost from "./pages/imagepost/ViewPostContent";
import ViewDiscussionPost from "./pages/discussion/Discussion";

import Userfeed from "./pages/userfeed/Userfeed";

import NotificationPage from "./pages/notification/NotificationPage";
import About from "./pages/about/About";

import NotFound from "./pages/NotFound";
import SignInModal from "./components/modals/SignInModal";

// import { useStateContext } from "./contexts/ContextProviders";
import { validatePath } from "./utils/auth";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const { isClicked } = useStateContext();
  validatePath();
  return (
    <>
      {isClicked.isAuthModal && <SignInModal />}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/onboarding" element={<Onboarding />} />
          <Route
            path="/onboarding/:invite_code"
            element={<OnboardingReturn />}
          />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/profile/:userId" element={<UserProfile />} />
          <Route path="/profile/edit" element={<EditProfile />} />
          <Route path="/userfeed" element={<Userfeed />} />
          <Route path="/notification" element={<NotificationPage />} />
          <Route path="/imagepost/:postId" element={<ViewImagePost />} />
          <Route path="/discussion" element={<DiscussionFeed />} />
          <Route path="/discussion/:postId" element={<ViewDiscussionPost/>} />
          <Route path="/product" element={<Products />} />
          <Route path="/product/:productId" element={<ProductReview />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default App;
