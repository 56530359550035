import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useValidateRequest } from "../../contexts/ValidateRequest";
import { useStateContext } from "../../contexts/ContextProviders";
import { useDropzone } from "react-dropzone";

import { jwtDecode } from "jwt-decode";
import { toast, Slide } from "react-toastify";

import { BiLoaderCircle } from "react-icons/bi";
import Avatar from "../../assets/icons/avatar.svg";

import BackButton from "../../components/buttons/BackButton";
import SolidButton from "../../components/buttons/SolidButton";
import OutlineButton from "../../components/buttons/OutlineButton";
import DashboardHeader from "../../components/UserfeedHeader";
import { GoPencil } from "react-icons/go";
import ModalContainer from "../../components/modals/ModalContainer";
import UploadModal from "../../components/modals/UploadModal";
import UploadProductModal from "../../components/modals/UploadProductModal";
import UploadDiscussionModal from "../../components/modals/UploadDiscussionModal";

const EditProfile = () => {
  const navigate = useNavigate();
  const { axiosApiCall } = useValidateRequest();
  const { isClicked } = useStateContext();

  /// Get user details from token
  const token = window.localStorage.getItem("validationToken");
  const userDetails = jwtDecode(token);

  const aboutYou =
    "Embracing coils & curls with confidence! Join me on a journey of self-love & empowerment through natural hair. 💫✨ #AfroHair #SelfLove #NaturalBeauty";

  const [imageDataUrl, setImageDataUrl] = useState(null);
  const [defaultData, setDefaultData] = useState({
    first_name: "",
    surname: "",
    username: "",
    about_me: "",
    avt: "",
    website: "",
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axiosApiCall(
          `user/${userDetails.user_id}`,
          "get"
        );

        // Rename "last_name" to "surname"
        const { last_name: surname, ...rest } = response.data.data;
        const updatedResponse = { ...rest, surname };

        setDefaultData(updatedResponse);
        reset(updatedResponse);
      } catch (error) {}
    };

    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axiosApiCall, userDetails.user_id]);

  //Drag and drop doesn't work well on ubuntu 22.04 [wayland]
  //Fix for now is changing wayland to xorg
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFile) => {
      setImageDataUrl(
        Object.assign(acceptedFile[0], {
          preview: URL.createObjectURL(acceptedFile[0]),
        })
      );
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: defaultData,
  });

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();

      formData.set("first_name", data.first_name);
      formData.set("surname", data.surname);
      formData.set("username", defaultData.username);
      formData.set("aboutme", data.about_me);
      formData.set("website", data.website);

      if (imageDataUrl) {
        formData.set("img", imageDataUrl);
      }

      const response = axiosApiCall(
        "user/update",
        "post",
        formData,
        "multipart/form-data"
      );

      await toast.promise(response, {
        success: "Profile updated successfully!",
        error: {
          render() {
            return "Something went wrong";
          },
        },
        autoClose: 2000,
        transition: Slide,
      });
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  return (
    <div>
      <DashboardHeader />
      {isClicked.postContentBtn && <ModalContainer />}
      {isClicked.openUploadModal && <UploadModal />}
      {isClicked.openProductUploadModal && <UploadProductModal />}
      {isClicked.openDiscussionUploadModal && <UploadDiscussionModal />}
      <div className="flex items-center justify-center p-6">
        <div className="mx-auto w-full max-w-[550px] bg-white">
          <BackButton onClick={() => navigate("/profile")} />

          <div className="pt-3 sm:text-left text-center">
            <h2 className="block text-base font-bold text-black sm:text-xl mx-auto sm:mx-0">
              Edit Profile
            </h2>
            <span className="sm:text-sm block mx-auto sm:mx-0 mt-2">
              View and edit profile information
            </span>
            <div className="mb-5 mt-3">
              <label
                htmlFor="avatar"
                className="mb-3 block text-base text-black mx-auto sm:mx-0"
              >
                Profile Photo
              </label>

              <div
                {...getRootProps({
                  className: "relative inline-block sm:mx-0",
                })}
              >
                <img
                  className="w-24 h-24 rounded-full border border-gray-300 mx-auto sm:mx-0"
                  src={
                    imageDataUrl
                      ? imageDataUrl.preview
                      : defaultData && defaultData.avt
                        ? defaultData.avt
                        : Avatar
                  }
                  onLoad={() => {
                    imageDataUrl && URL.revokeObjectURL(imageDataUrl.preview);
                  }}
                  alt="preview"
                />

                <input
                  {...getInputProps()}
                  className="w-8 h-8 rounded-full absolute bottom-0.5 right-0.5 bg-custom-image opacity-0 z-[20]"
                  style={{ display: "block" }}
                />

                <div className="absolute left-0 top-0 h-full w-full rounded-full z-10">
                  <div className="relative h-full w-full">
                    <div className="absolute bg-[var(--Cobalt-Blue)] w-8 h-8 rounded-full bottom-0.5 right-0.5 flex items-center justify-center">
                      <GoPencil className="text-white" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="-mx-3 flex flex-wrap ">
              <div className="w-full px-3 sm:w-1/2">
                <div className="mb-5">
                  <label
                    htmlFor="firstName"
                    className="mb-3 block text-base font-medium text-black"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    {...register("first_name", {
                      required: "First name is required",
                    })}
                    placeholder="John"
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none hover:border-[#007EA7]/40 focus:border-[#007EA7] active:border-[#007EA7] focus:shadow-md"
                  />
                  {errors.first_name && (
                    <p className="text-red-600">{errors.first_name.message}</p>
                  )}
                </div>
              </div>
              <div className="w-full px-3 sm:w-1/2">
                <div className="mb-5">
                  <label
                    htmlFor="surname"
                    className="mb-3 block text-base font-medium text-black"
                  >
                    Surname
                  </label>
                  <input
                    type="text"
                    name="surname"
                    {...register("surname", {
                      required: "Surname is required",
                    })}
                    placeholder="Doe"
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none hover:border-[#007EA7]/40 focus:border-[#007EA7] active:border-[#007EA7] focus:shadow-md"
                  />
                  {errors.surname && (
                    <p className="text-red-600">{errors.surname.message}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="mb-5">
              <label
                htmlFor="email"
                className="mb-3 block text-base font-medium text-black"
              >
                Username
              </label>
              <input
                type="text"
                name="username"
                {...register("username", { required: "Username is required" })}
                disabled
                placeholder="Enter your username"
                disabled
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none hover:border-[#007EA7]/40 focus:border-[#007EA7] active:border-[#007EA7] focus:shadow-md cursor-not-allowed"
              />
              {errors.username && (
                <p className="text-red-600">{errors.username.message}</p>
              )}
            </div>

            <div className="mb-5">
              <label
                htmlFor="about"
                className="mb-3 block text-base font-medium text-black"
              >
                About You<small> (optional)</small>
              </label>
              <textarea
                rows="6"
                className="border border-gray-300 outline-none text-gray-900 sm:text-sm rounded-lg hover:border-[#007EA7]/40 focus:border-[#007EA7] active:border-[#007EA7] block w-full p-4"
                placeholder={aboutYou}
                {...register("about_me")}
                name="about_me"
              ></textarea>
              {errors.about_me && (
                <p className="text-red-600">{errors.aboutme.message}</p>
              )}
            </div>
            <div className="mb-5">
              <label
                htmlFor="website"
                className="mb-3 block text-base font-medium text-black"
              >
                Website <small> (optional)</small>
              </label>
              <input
                type="website"
                name="website"
                {...register("website", {
                  pattern: {
                    value:
                      /^(https?:\/\/)?([\w\d-]+\.){1,3}[a-z]{2,}(\/[\w\d#-]+)*\/?$/,
                    message: "Enter a valid URL",
                  },
                })}
                placeholder="Enter website"
                className="w-full rounded-md border border-[#e0e0e0] hover:border-[#007EA7]/40  active:border-[#007EA7] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:shadow-md"
              />
              {errors.website && (
                <p className="text-red-600">{errors.website.message}</p>
              )}
            </div>

            <div className="flex items-center sm:justify-end justify-center">
              <OutlineButton text="Reset" onClick={reset} />
              <SolidButton
                text={
                  isSubmitting ? (
                    <BiLoaderCircle className="w-5 h-5 text-white animate-spin" />
                  ) : (
                    "Save"
                  )
                }
                className="ml-4 text-white"
                type="submit"
                disabled={isSubmitting}
              />
            </div>
          </form>
        </div>
      </div>

      {/* <UploadPhotoModal open={openModal} handleClose={() => setOpenModal(false)}>
        <ImageCropModalContent handleDone={handleDone} handleClose={() => setOpenModal(false)} />
      </UploadPhotoModal> */}
    </div>
  );
};

export default EditProfile;
