import React, { useCallback, useEffect, useState } from "react";
import { useValidateRequest } from "../../contexts/ValidateRequest";
import { useStateContext } from "../../contexts/ContextProviders";
import DashboardHeader from "../../components/UserfeedHeader";
import UserProfile from "../../components/profiles/UserProfile";
import AdminProfile from "../../components/profiles/AdminProfile";
import ModalContainer from "../../components/modals/ModalContainer";
import UploadModal from "../../components/modals/UploadModal";
import UploadDiscussionModal from "../../components/modals/UploadDiscussionModal";
import UploadProductModal from "../../components/modals/UploadProductModal";
import EditProduct from "../../components/modals/EditDescription";

const Profile = () => {
  // const [page, setPage] = useState(1);
  const [profileInfo, setProfileInfo] = useState(null);
  const [fetchedData, setFetchedData] = useState({
    discussion: [],
    post: [],
  });

  const { axiosApiCall } = useValidateRequest();
  const { role, userId, isClicked, editProductInfo } = useStateContext();

  const updateData = useCallback((newData) => {
    setFetchedData((prevState) => {
      const updatedData = {};
      ["post", "discussion"].forEach((key) => {
        updatedData[key] = newData[key]
          ? [
              ...new Map(
                [...prevState[key], ...newData[key]].map((item) => [
                  item.id,
                  item,
                ])
              ).values(),
            ]
          : prevState[key];
      });
      return updatedData;
    });
  }, []);

  const fetchData = useCallback(async () => {
    const image_params = {
      page: 1,
      limit: 10000,
      created_by: userId,
      content_type: "image",
      include_metrics: true,
    };

    const discussion_params = {
      page: 1,
      limit: 10000,
      created_by: userId,
      content_type: "discussion",
      include_metrics: true,
    };
    try {
      const [postResponse, discussionResponse] = await Promise.all([
        axiosApiCall("post", "get", image_params),
        axiosApiCall("post", "get", discussion_params),
      ]);

      const postData = postResponse.data.data;
      const discussionData = discussionResponse.data.data;

      updateData({
        post: postData.post,
        discussion: discussionData.post,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [axiosApiCall, updateData, userId]);

  const fetchProfileInfo = useCallback(async () => {
    try {
      await axiosApiCall(`user/${userId}`, "get").then(
        (data) => data.status === 200 && setProfileInfo(data?.data?.data)
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [axiosApiCall, userId]);

  useEffect(() => {
    // Fetch profile information
    const getProfileInfo = async () => {
      try {
        userId &&
          (await axiosApiCall(`user/${userId}`, "get").then(
            (data) => data.status === 200 && setProfileInfo(data?.data?.data)
          ));
      } catch (error) {
        console.log(error);
      }
    };
    getProfileInfo();
  }, [axiosApiCall, userId]);

  useEffect(() => {
    userId && fetchData();
    userId && fetchProfileInfo();
  }, [fetchData, fetchProfileInfo, userId]);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (
  //       window.innerHeight + document.documentElement.scrollTop ===
  //       document.documentElement.offsetHeight
  //     ) {
  //       setPage((prevPage) => prevPage + 1);
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  return (
    <div>
      <DashboardHeader />
      {isClicked.postContentBtn && <ModalContainer />}
      {isClicked.openUploadModal && <UploadModal />}
      {isClicked.openProductUploadModal && <UploadProductModal />}
      {isClicked.openDiscussionUploadModal && <UploadDiscussionModal />}
      {isClicked.editProduct && <EditProduct product={editProductInfo} />}
      {role === "ADMIN" ? (
        <AdminProfile />
      ) : (
        <UserProfile fetchedData={fetchedData} profileInfo={profileInfo} />
      )}
    </div>
  );
};

export default Profile;
