import React from 'react';
import PostCard from "../../pages/userfeed/PostCard";

const PostsTab = ({ posts }) => {
  return (
    <div className="container mx-auto">
      <div className="grid lg:grid-cols-4 gap-4 mt-8 lg:m-0 lg:px-10 ">
        {posts && (posts.map((post) => (
          <PostCard key={post.id} post={post} />
        )))}
      </div>
    </div>
  )
}

export default PostsTab