import React, { useState } from "react";

import { useValidateRequest } from "../../contexts/ValidateRequest";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { BiLoaderCircle } from "react-icons/bi";
import logo from "../../assets/images/tc-logo-white.png";
import mobileLogo from "../../assets/images/tc-logo-white.png";
import checkCircle from "./onboarding_images/CheckCircle.png";

const Onboarding = () => {
  const [connectionError, setConnectionError] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const { axiosApiCall } = useValidateRequest();
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm();

  const onSubmit = async ({ email }) => {
    try {
      const response = axiosApiCall(`user/invite?email=${email}`, "post");

      const resolved = await toast.promise(response, {});
      const data = await resolved;

      data.status === 200 && setSubmitted(true);
    } catch (error) {
      console.log(error);
      setConnectionError(true);
    } finally {
      reset({
        email: "",
      });
    }
  };

  return (
    <div className="w-full h-screen relative">
      {/* Backround Image */}
      <div className="w-full h-full fixed -z-10 background bg-mobile-hero md:bg-hero-img bg-center bg-cover"></div>
      {/* Background Image Ends */}

      {/* Header Section Begins*/}
      <div className="header w-full h-12 p-2 lg:px-4 flex justify-between items-center sticky top-3 ">
        <a href="/" className="logo">
          <img
            src={mobileLogo}
            alt="Texture Care"
            className="md:hidden w-32 sm:w-36 h-auto"
          />
          <img
            src={logo}
            alt="Texture Care"
            className="hidden md:block md:w-48 lg:w-56 h-auto"
          />
        </a>
        <div className="hidden md:block w-[17rem] p-3 rounded-3xl h-[3rem] bg-white">
          Got an account already?{" "}
          <a href="/sign-in" className="">
            Sign in!
          </a>
        </div>
        <a
          href="/sign-in"
          className="md:hidden w-[6.5rem] p-2 rounded-3xl h-[3rem] flex justify-center items-center bg-white text-center"
        >
          Sign in
        </a>
      </div>

      {/* Header Section Ends */}

      {/* Connection error Alert */}
      {connectionError ? (
        <div className="connection-wrap border-[1px] absolute top-16 right-4 border-red-500 bg-white p-2 px-4 rounded-3xl">
          <span className="hidden md:block text-red-500">
            Poor connection. Please try again
          </span>
          <span className="md:hidden text-red-500">
            Poor internet connection
          </span>
        </div>
      ) : (
        ""
      )}
      {/* { Connection error Alert } */}

      {/* Form Section Begins */}
      <div>
        <div className="form-wrap w-full h-auto">
          <form
            className={` w-[90%] md:w-[34rem] h-[20rem] p-[1.5rem] bg-white absolute m-auto left-0 right-0 top-0 bottom-0 rounded-xl`}
            onSubmit={handleSubmit(onSubmit)}
          >
            <h1 className="text-4xl font-bold mb-2 font-serif">
              Get invite code
            </h1>
            <p className="text-sm mb-8">
              To access Texture Care, request an invite code by entering your
              email address
            </p>
            <div className="wrap flex flex-col">
              <label htmlFor="email1" className="mb-1 text-[14px]">
                Email address
              </label>
              <input
                type="email"
                className={`border-[1px] outline-0 border-[#007EA7] rounded-lg p-2 text-sm`}
                placeholder="Enter your email "
                name="email1"
                {...register("email")}
                required
              />
              <button
                type="submit"
                disabled={isSubmitting}
                className="flex items-center justify-center bg-[#007EA7] rounded-lg p-2 text-white mt-10"
              >
                {isSubmitting ? (
                  <BiLoaderCircle className="w-5 h-5 text-white animate-spin" />
                ) : (
                  " Request invite code"
                )}
              </button>
            </div>
          </form>
        </div>

        {submitted && <InviteCodeAlert />}
      </div>
    </div>
  );
};

const InviteCodeAlert = () => {
  return (
    <>
      <div className="Invite_Successful w-[90%] h-[20rem] md:w-[34rem] md:h-[25rem] p-[1.5rem] bg-white absolute m-auto z-10 left-0 right-0 top-0 bottom-0 rounded-xl flex flex-col items-center justify-center">
        <img src={checkCircle} alt="" className="w-[9rem] md:w-[12.5rem]" />
        <h1 className="text-3xl md:text-4xl font-serif font-bold text-center mb-4">
          Invite code has been successfully sent!
        </h1>
        <p className="text-base md:text-sm text-[#b4b4b4]">
          Please check the email address you provided
        </p>
      </div>
    </>
  );
};
export default Onboarding;
