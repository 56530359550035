import React from "react";
import { useStateContext } from "../../contexts/ContextProviders";

import SelectContentType from "./SelectContentType";
import DeleteDiscussionPost from "./DeleteDiscussionPost";
import DeleteComment from "./DeleteComment";
import DeleteReply from "./DeleteReply";
import DeleteProductPost from "./DeleteProductPost";

const ModalContainer = () => {
  const { isClicked } = useStateContext();
  return (
    <div className="fixed inset-0 z-[9999] bg-black/40 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0">
      {isClicked.postContentBtn && <SelectContentType />}

      {/* Admin actions */}
      {isClicked.deleteDiscussionPost && <DeleteDiscussionPost />}
      {isClicked.deleteProductPost && <DeleteProductPost />}
      {isClicked.deleteComment && <DeleteComment />}
      {isClicked.deleteReply && <DeleteReply />}
    </div>
  );
};

export default ModalContainer;
