import React from "react";

const SolidButton = ({ text, textColor, bgColor, onClick, className, ...props }) => {
    return (
        <button
            className={`${className} bg-[var(--Cobalt-Blue)] hover:bg-[#006080] rounded focus:outline-none focus:shadow-outline py-3 p-6 `}
            onClick={onClick}
            {...props}
        >
            {text}
        </button>
    );
}

export default SolidButton