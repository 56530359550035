import React from 'react'

function ChatCircle() {
  return (
    <svg
    width={20}
    height={20}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9999 3C13.7555 2.99951 11.5492 3.58011 9.59572 4.68529C7.64227 5.79046 6.00822 7.38256 4.85262 9.30662C3.69703 11.2307 3.05926 13.4212 3.00139 15.6648C2.94353 17.9085 3.46753 20.1289 4.52241 22.11L3.10366 26.3662C2.98614 26.7186 2.96908 27.0968 3.05441 27.4583C3.13973 27.8199 3.32405 28.1505 3.58672 28.4132C3.84939 28.6759 4.18003 28.8602 4.54157 28.9455C4.90311 29.0308 5.28127 29.0138 5.63366 28.8962L9.88991 27.4775C11.6334 28.4048 13.5652 28.9228 15.5387 28.992C17.5122 29.0613 19.4756 28.68 21.2797 27.8772C23.0839 27.0743 24.6815 25.871 25.9512 24.3586C27.2209 22.8462 28.1293 21.0644 28.6076 19.1484C29.0858 17.2325 29.1213 15.2328 28.7113 13.301C28.3013 11.3693 27.4567 9.55642 26.2414 7.9999C25.0262 6.44337 23.4723 5.18415 21.6978 4.31782C19.9232 3.45149 17.9746 3.00081 15.9999 3ZM15.9999 27C14.0661 27.0013 12.1663 26.4921 10.4924 25.5238C10.3699 25.4527 10.2336 25.4085 10.0927 25.394C9.95172 25.3796 9.80933 25.3953 9.67491 25.44L4.99991 27L6.55866 22.325C6.60359 22.1907 6.61947 22.0483 6.60525 21.9074C6.59103 21.7665 6.54702 21.6301 6.47616 21.5075C5.26362 19.4111 4.77679 16.9732 5.09119 14.572C5.40559 12.1707 6.50365 9.94027 8.21502 8.22674C9.92639 6.51321 12.1554 5.41234 14.5563 5.0949C16.9572 4.77747 19.3957 5.26122 21.4936 6.47111C23.5915 7.68099 25.2314 9.54939 26.1591 11.7864C27.0868 14.0235 27.2502 16.5042 26.6242 18.8436C25.9981 21.1831 24.6176 23.2505 22.6966 24.7253C20.7756 26.2 18.4217 26.9996 15.9999 27Z"
      fill="#007EA7"
    />
  </svg>
  
  )
}

export default ChatCircle