import React, { useEffect, useState } from "react";
import { useValidateRequest } from "../../contexts/ValidateRequest";
import { useStateContext } from "../../contexts/ContextProviders";

import { toast } from "react-toastify";

import { BiChevronDown, BiChevronLeft, BiLoaderCircle } from "react-icons/bi";
import { BsChevronDoubleDown, BsChevronDoubleUp } from "react-icons/bs";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md";

import AdminProductCard from "./AdminProductCard";
import ProductReviewCard from "../cards/ProductReviewCard";
import CommentThread from "../comments/CommentThread";
import Skeleton from "react-loading-skeleton";
import RelatedProductCard from "../cards/RelatedProductCard";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const AdminProductPanel = ({ profileInfo }) => {
  const [showPostDetails, setShowPostDetails] = useState(false);
  const [editProduct, setEditProduct] = useState(false);
  //   const [showInput, setShowInput] = useState(false);

  const [products, setProducts] = useState([]);
  const [comments, setComments] = useState([]);
  const [replies, setReplies] = useState(null);
  const [product, setProduct] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState(null);
  const [commentID, setCommentID] = useState(null);

  const [fetchError, setFetchError] = useState("");
  const [activeTab, setActiveTab] = useState("DESCRIPTION");

  const { productID, setProductID, userId, handleClick, setEditProductInfo } =
    useStateContext();
  const { axiosApiCall } = useValidateRequest();

  useEffect(() => {
    axiosApiCall(
      `post?page=1&limit=100000&content_type=product&include_metrics=true&created_by=${userId}`,
      "get"
    ).then((res) =>
      res.status === 200
        ? setProducts(res?.data?.data?.post)
        : setFetchError("Something went wrong, retrying...")
    );
  }, [axiosApiCall, userId]);

  useEffect(() => {
    const getOtherProducts = async () => {
      try {
        const data = await axiosApiCall(
          `post?page=1&limit=5&content_type=product`,
          "get"
        );
        setRelatedProducts(data);
        // console.log(data);
      } catch (error) {
        console.log(error);
      }
    };

    getOtherProducts();
  }, [axiosApiCall]);

  fetchError && toast.error(fetchError);

  //   const handleShowInput = (e) => {
  //     e.preventDefault();
  //     setShowInput((prev) => !prev);
  //   };

  const handleEditProduct = () => {
    setEditProduct(true);
  };

  if (productID) {
    try {
      axiosApiCall(`post/${productID}?include_metrics=true`, "get").then(
        (res) => {
          setProduct(res?.data?.data);
          //   console.log(res?.data?.data);
          setCommentID(productID);
          setProductID(null);
        }
      );
    } catch (error) {
    } finally {
    }
  }

  const id = commentID;

  // get Comments
  const getComment = async () => {
    const params = {
      page: 1,
      limit: 100,
      target_id: id ? id : null,
    };

    try {
      const getCommentResponse = await axiosApiCall("comment", "get", params);
      const allComments = getCommentResponse.data.data;
      const firstComments = allComments.filter(
        (comment) => comment.parent_id === null
      );
      const secondLevelComments = allComments.filter(
        (comment) => comment.parent_id !== null
      );
      setComments(firstComments);
      setReplies(secondLevelComments);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="w-full flex flex-col gap-y-4 px-4 lg:px-24">
      {!!editProduct ? (
        <div className="flex flex-col gap-y-4">
          <button
            className="w-10 h-10 flex items-center justify-center rounded-full bg-gray-200/20 hover:bg-gray-200/60"
            onClick={() => setEditProduct(false)}
          >
            <BiChevronLeft className="w-7 h-7 text-[var(--Cobalt-Blue)]" />
          </button>
          <div className="flex flex-col lg:flex-row lg:justify-between w-full gap-y-2 transition-all">
            <div className="w-full lg:w-[65%]">
              {product ? (
                <ProductReviewCard
                  imgSrc={product.url}
                  contentType={product.type}
                  content={product.content}
                  id={product?.id}
                  upvotes={product?.metrics?.upvote_count}
                  downvotes={product?.metrics?.downvote_count}
                  reviews={product?.metrics?.comment_count}
                  username={product?.username}
                  isAdminProfle={true}
                />
              ) : (
                <div className="w-full h-full flex justify-center items-center">
                  <BiLoaderCircle className="w-20 h-20 text-[var(--Cobalt-Blue)] animate-spin" />
                </div>
              )}

              <div className="flex items-center justify-center">
                <button
                  type="button"
                  onClick={() => setShowPostDetails((prev) => !prev)}
                  className="w-fit flex items-center justify-center gap-x-2 outline-none border-none px-3 py-2 rounded hover:bg-gray-300/40 text-[var(--Cobalt-Blue)] text-sm font-semibold"
                >
                  <span>
                    {!showPostDetails
                      ? "View Post Details"
                      : "Hide Post Details"}
                  </span>
                  {!showPostDetails ? (
                    <BsChevronDoubleDown className="h-4 w-4" />
                  ) : (
                    <BsChevronDoubleUp className="h-4 w-4" />
                  )}
                </button>
              </div>

              {showPostDetails && (
                <div className="flex flex-col gap-y-2 mt-4">
                  <p className="text-sm text-gray-500">
                    Author:{" "}
                    <span className="font-semibold text-gray-800">
                      {`${profileInfo?.first_name} ${profileInfo?.last_name}`} (
                      {`@${profileInfo?.username}`})
                    </span>
                  </p>
                  <p className="text-sm text-gray-500">
                    Post Type:{" "}
                    <span className="font-semibold text-gray-800">
                      Products
                    </span>
                  </p>
                  <p className="text-sm text-gray-500">
                    Tag(s):{" "}
                    <span className="font-semibold text-gray-800">
                      {product && product?.product_type?.join(", ")}
                    </span>
                  </p>
                  <p className="text-sm text-gray-500">
                    Date:{" "}
                    <span className="font-semibold text-gray-800">
                      {product &&
                        new Date(product?.created_time).toLocaleDateString()}
                    </span>
                  </p>
                  <p className="text-sm text-gray-500">
                    Time:{" "}
                    <span className="font-semibold text-gray-800">
                      {product &&
                        new Date(product?.created_time).toLocaleTimeString()}
                    </span>
                  </p>
                </div>
              )}

              <div className="mt-8">
                <div className="flex justify-center items-center gap-x-8 my-2 transition-all">
                  <button
                    onClick={() => setActiveTab("DESCRIPTION")}
                    className={`px-4 pb-2 text-sm font-semibold outline-none  ${
                      activeTab === "DESCRIPTION"
                        ? "border-b-[3px] border-[var(--Cobalt-Blue)] text-[var(--Cobalt-Blue)]"
                        : "border-b-[3px] border-white hover:border-gray-400/20"
                    }`}
                  >
                    Product Descriptions
                  </button>
                  <button
                    onClick={() => {
                      setActiveTab("REVIEW");
                      getComment();
                    }}
                    className={`px-4 pb-2 text-sm font-semibold outline-none  ${
                      activeTab === "REVIEW"
                        ? "border-b-[3px] border-[var(--Cobalt-Blue)] text-[var(--Cobalt-Blue)]"
                        : "border-b-[3px] border-white hover:border-gray-400/20"
                    }`}
                  >
                    Reviews
                  </button>
                </div>
              </div>

              {activeTab === "DESCRIPTION" && (
                <div className="my-8 flex flex-col gap-y-4">
                  <ReactQuill
                    value={product && product.content}
                    readOnly={true}
                    theme={"bubble"}
                  />

                  <div className="flex items-center justify-end gap-x-6">
                    <button
                      onClick={() => {
                        handleClick("editProduct");
                        setEditProductInfo({
                          id: product.id,
                          title: product.title,
                          content: product.content,
                          product_type: product.product_type,
                          hair_type: product.hair_type,
                          created_time: product.created_time,
                          username: product.username,
                          url: product.url,
                        });
                      }}
                      className="flex items-center gap-x-1 px-4 py-2 text-sm font-semibold border-none outline-none rounded
                        text-[var(--Cobalt-Blue)] hover:bg-gray-300/40"
                    >
                      <MdOutlineEdit className="w-5 h-5" />
                      <span>Edit Product</span>
                    </button>
                    <button
                      className="flex items-center gap-x-1 px-4 py-2 text-sm font-semibold border-none outline-none rounded
                        text-red-500 hover:bg-gray-300/40"
                    >
                      <MdDeleteOutline className="w-5 h-5" />
                      <span>Delete Product</span>
                    </button>
                  </div>
                </div>
              )}

              {activeTab === "REVIEW" && (
                <div className="my-8 flex flex-col gap-y-4">
                  <>
                    {comments ? (
                      comments?.map((comment, i) => (
                        <CommentThread
                          key={comment.id}
                          isProductPage={true}
                          isAdminProfile={true}
                          adminProductId={id ? id : null}
                          commentId={comment.id}
                          commentType={comment.type}
                          userId={comment.user_id}
                          commentUserId={comment?.user_id}
                          index
                          commentNumber={comment?.metrics?.comment_count}
                          like={comment?.metrics?.upvote_count}
                          unlike={comment?.metrics?.downvote_count}
                          comment={comment.content}
                          postId={productID}
                          avatarFallback={comment?.title
                            .slice(0, 2)
                            .toUpperCase()}
                          username={comment?.title}
                          setComments={setComments}
                          setReplies={setReplies}
                          nextLevelComments={
                            replies?.filter(
                              (innerComment) =>
                                innerComment?.parent_id === comment.id
                            ) || comment.id
                          }
                          setNextLevelComments
                          getComment={getComment}
                        />
                      ))
                    ) : (
                      <div className="comment mb-4 w-full">
                        <Skeleton
                          count={3}
                          height={100}
                          style={{ marginBottom: ".5rem" }}
                        />
                      </div>
                    )}
                  </>
                </div>
              )}
            </div>
            <div className="">
              <div className="flex flex-col">
                <h3 className="text-lg font-semibold mb-4">Related Products</h3>
                {relatedProducts &&
                  relatedProducts.data?.data?.post.map((post, index) => (
                    <RelatedProductCard key={index} post={post} />
                  ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center gap-x-1">
              <p className="text-sm font-semibold">
                Showing posts by:{" "}
                <span className="font-bold text-[var(--Cobalt-Blue)]">
                  You ({`${profileInfo?.first_name} ${profileInfo?.last_name}`})
                </span>{" "}
              </p>
              <BiChevronDown className="w-5 h-5 text-[var(--Cobalt-Blue)]" />
            </div>
          </div>
          <div className="">
            <div className="w-full flex flex-col lg:flex-row items-center gap-8">
              {products &&
                products.map((product) => (
                  <div
                    key={product.id}
                    className="p-4 rounded flex flex-col items-center justify-center product-grid-item"
                  >
                    <AdminProductCard
                      handleEditProduct={handleEditProduct}
                      id={product.id}
                      product={product}
                    />
                  </div>
                ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AdminProductPanel;
