/**
 * This can be removed/modified - It was basically created
 * to aid the development of the post content/uploads modals
 *
 * If these modals;
 * ====> {isClicked.postContentBtn && <SelectContentType />}
 * ====> {isClicked.openUploadModal && <UploadModal />}
 * are placed high enough the app, they modals can be accessed from anywhere
 */

import React, {useState, useEffect, useCallback} from "react";
import { Link } from "react-router-dom";


import { useStateContext } from "../../contexts/ContextProviders";
import { useValidateRequest} from "../../contexts/ValidateRequest"

import { TbArrowBigDown, TbArrowBigUp } from "react-icons/tb";
import { FaRegComment } from "react-icons/fa";
import { BiShare } from "react-icons/bi";

import ModalContainer from "../../components/modals/ModalContainer";
import UploadModal from "../../components/modals/UploadModal";
import UploadDiscussionModal from "../../components/modals/UploadDiscussionModal";
import DashboardHeader from "../../components/UserfeedHeader";
import DiscussionPostCard from "./DiscussionPostCard";
import UploadProductModal from "../../components/modals/UploadProductModal";

const DiscussionFeed = () => {
  const [response, setResponse] = useState([]);
  const [filter, setFilter] = useState(null);
  const [page, setPage] = useState(1);

  const { isClicked } = useStateContext();
  const { axiosApiCall } = useValidateRequest();

  const handleScroll = () => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  const discussionFeed = useCallback(async () => {
    const params = {
      page: page,
      limit: 25,
      hair_type_tags: "",
      product_type_tags: "",
      content_type: "discussion",
      include_metrics: true
    }

    const getSelectedTags = (tags) => 
      Object.keys(tags).filter(key => tags[key]).map(tag => tag.toLowerCase()).join(",");
 
    if (filter != null) {
      params.hair_type_tags = getSelectedTags(filter.hairType);
      params.product_type_tags = getSelectedTags(filter.productType);
      
    }    try {
      const { data } = await axiosApiCall('post', 'get', params );
      const contentData = data?.data?.post;
      // setResponse(contentData);

      setResponse(prevPosts => {
        const existingIds = new Set(prevPosts.map(post => post.id));
        const newPosts = contentData.filter(post => !existingIds.has(post.id));
        return page === 1 ? contentData : [...prevPosts, ...newPosts];
      });
      // setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, [filter, page]);

  useEffect(() => {
    discussionFeed();
  }, [discussionFeed]);

  return (
    <section className="w-full">
      <DashboardHeader setFilter={setFilter} setPage={setPage} setResponse={setResponse}/>
      {/* If the post content button is clicked? - refer to comment above */}
      {isClicked.postContentBtn && <ModalContainer />}
      {/* admins only */}
      {isClicked.openProductUploadModal && <UploadProductModal />}
      {/* all users */}
      {isClicked.openUploadModal && <UploadModal />}
      {isClicked.openDiscussionUploadModal && <UploadDiscussionModal />}

      <div className="container mx-auto">
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4 mt-8 lg:m-0 px-4">
          {response && response?.map((post) => (
            // <DemoCards key={post.id} post={post}/>
            <DiscussionPostCard key={post.id} post={post}/>
          ))}
        </div>
      </div>
    </section>
  );
};

export default DiscussionFeed;
