import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom"
import axios from "axios";
import { IoChatbubble } from "react-icons/io5";
import { IoMdHeart } from "react-icons/io";
import env from "../../config/env";

const Notification = ({
  postImage,
  postOwner,
  reactions,
  timestamp,
  userComment,
}) => {
  const isOverlayNeeded = reactions.length === 2 || reactions.length === 3;

  return (
    <div className="flex items-center space-x-4 p-4 hover:bg-[#F9FAFB] relative rounded-xl">
      <div className="img-wrap relative w-fit h-auto">
        <img src={postImage} alt="Post" className="w-14 h-14 rounded" />
        {/* Is there anything like an admin approving a post? */}
        <span
          className={` inline-block w-fit p-1 rounded-xl absolute right-0 -top-3 ${userComment.includes("comment") || userComment.includes("replied") ? "bg-[#CCE5ED]" : "bg-[#fee4e2]"}`}
        >
          {userComment.includes("comment") ||
            userComment.includes("replied") ? (
            <IoChatbubble className="text-[#007EA7] text-xs" />
          ) : (
            <IoMdHeart className="text-[#F04438] text-xs" />
          )}
        </span>
      </div>
      <div className="flex flex-col flex-grow">
        <div
          className={`flex items-center space-x-2 relative ${isOverlayNeeded ? "mb-8" : ""}`}
        >
          {reactions.map((reaction, index) => (
            <img
              key={index}
              src={reaction.profilePicture}
              alt="Reaction"
              className={`w-8 h-8 rounded-full ${isOverlayNeeded ? "absolute top-0" : ""}`}
              style={{
                zIndex: index <= 3 ? index : "auto",
                left: index === 1 ? ".5rem" : index === 2 ? "1.5rem" : "0",
              }}
            />
          ))}
        </div>
        <p className=" text-sm">{userComment}</p>
        <p className="text-sm text-gray-500 text-right">{timestamp}</p>
      </div>
    </div>
  );
};

const Notifications = ({ limit, isRoute }) => {
  const navigate = useNavigate()
  const handleClick = (object_id, _type) => {
    const config = {
      headers: {
        Authorization: window.localStorage.getItem("validationToken"),
        "Content-Type": "application/json",
      },
    };

    axios.postForm(`${env.get('API_URL')}/notification`, {
      notification_type: _type,
      target_object_id: object_id,
    }, config).then((response) => {
      console.log({ response })
    });

  };

  const [data, setData] = useState(null);

  useEffect(() => {
    const config = {
      headers: {
        Authorization: window.localStorage.getItem("validationToken"),
        "Content-Type": "application/json",
      },
    };

    axios.get(`${env.get('API_URL')}/notification`, config).then((response) => {
      setData(response.data);
    });
  }, []);

  let data_comment = data?.data?.comment || [];
  let data_like = data?.data?.like || [];
  if (limit === true) {
    data_comment = data_comment.slice(0, 3);
    data_like = data_like.slice(0, 2);
  }

  return (
    <div className="max-w-lg mx-auto rounded-xl">
      <p className="text-xl font-bold font-serif mb-3">Notifications</p>
      {
        data_comment.map((i, k) => {
          return <Link key={k} to={"/product/" + i.object_id} onClick={() => handleClick(i.object_id, 'comment')}>
            <Notification
              key={i.id}
              postImage={i.customer_avt}
              postOwner={i.host_user_id}
              reactions={[]}
              timestamp={i.created_time}
              userComment={i.content} // Passing userComment as a prop
            />
          </Link>
        })}
      {data_like.map((i, k) => {
        if (!i.customer_avt_list) {
          return <></>
        }
        const followers = i.customer_avt_list.split(',')
        let first_avt = "";
        if (followers.length > 0) {
          first_avt = followers[0];
        }
        let reply = []
        for (let i = 1; i < followers.length; i++) {
          reply[i] = { 'profilePicture': followers[i], 'id': i }
        }

        return <Link key={k} to={"/product/" + i.object_id} onClick={() => handleClick(i.object_id, 'vote')}>
          <Notification
            key={i.id}
            postImage={first_avt}
            postOwner={i.host_user_id}
            reactions={reply}
            timestamp={i.created_time}
            userComment={`${data.data.like.length} and other liked your post`} // Passing userComment as a prop
          />
        </Link>
      })}
      {!isRoute && <p
        className="text-[#007EA7] text-right text-sm hover:cursor-pointer"
        onClick={() => navigate("/notification")}
      >
        See more
      </p>}

    </div>
  );
};

export default Notifications;
