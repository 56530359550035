import React, { } from 'react';
import { useNavigate, Link } from "react-router-dom";
import ModalVideo from "../../components/homepage/ModalVideo";
import NewsLetter from "../../components/homepage/NewsLetter";
import OurProducts from "../../components/homepage/OurProducts";
import NavigationBar from "../../components/homepage/NavigationBar";
import HomePageFooter from "../../components/homepage/HomePageFooter";
import HomePageSlider from "../../components/homepage/HomePageSlider";
import TcHome from "../../assets/images/tc_home.png";

const LandingPage = () => {
  const navigate = useNavigate();

  return (
    <>
      {/*  Navbar Section Start */}
      <NavigationBar />

      <div
        className="relative flex items-center justify-center bg-primary h-screen w-full h-full fixed background bg-mobile-hero md:bg-hero-img bg-center bg-cover"
      >
        <div className="absolute inset-0 bg-[#5B96A8] opacity-75"></div>
        <div className="relative container flex justify-center items-center h-full">
          <div className="w-full px-4">
            <div className="mx-auto max-w-[900px] text-center">
              <h1 className="mb-6 text-4xl font-bold leading-snug sm:text-5xl sm:leading-snug lg:text-6xl lg:leading-[1.2] text-[#003249] h2-georgia">
                If you understand your hair, you can find the perfect care.
              </h1>
              <ul className="mb-10 flex flex-wrap items-center justify-center gap-5">
                <li>
                  <Link
                    to="/onboarding"
                    className="inline-flex items-center justify-center rounded-md bg-[#007EA7] px-7 py-[14px] text-center text-base text-white"
                  >
                    Sign Up Now
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/*  About Section Start */}
      <div className="relative z-10 overflow-hidden py-20 gradient-background">

        <div className="flex flex-wrap items-center lg:pr-8 lg:mx-20">
          <div className="w-full px-4 lg:w-1/2">
            <div className="mb-12 lg:mb-0 lg:px-6">
              <h2 className="mb-5 text-3xl font-bold leading-tight sm:text-[40px] sm:leading-[1.2] text-[#1C4F7E]">
                What is Texture Care?
              </h2>
              <p className="mb-10 text-xl leading-relaxed text-[#1C4F7E]">
                Texture Care is a community-led hair care and product discovery platform designed to help you make informed and confident decisions about your hair care. We provide you with hair care advice, so you can confidently choose the best products for your textured hair.
              </p>
              <button
                onClick={() => navigate("/onboarding")}
                className="inline-flex items-center justify-center rounded-md border border-primary bg-[#007EA7] px-7 py-3 text-center text-base text-white"
              >
                Sign Up Now
              </button>
            </div>
          </div>
          <div className="w-full px-4 lg:w-1/2">
            <div className="flex flex-wrap justify-center px-6 lg:px-4">
              <img
                src={TcHome}
                alt="Looking for a new hairstyle"
                className="h-full w-full object-cover"
              />
              <p className="mt-4 text-xl leading-relaxed text-[#1C4F7E] text-justify">
                Looking for a new hairstyle or unsure which product to use? Discover tailored solutions and advice here at Texture Care.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Video */}
      <ModalVideo />
      {/* Our Products */}
      <OurProducts />
      {/* Home Page Slider Products */}
      <HomePageSlider />
      {/* Home Page Newsletter  */}
      <NewsLetter />
      {/* Home Page  Footer*/}
      <HomePageFooter />
    </>
  );
};

export default LandingPage;