class Env {
  constructor(defaults = {}) {
    this.defaults = { ...defaults };
    this.params = { ...defaults };
  }

  set(key, value) {
    this.params[key] = value;
  }

  get(key) {
    return this.params[key];
  }

  reset() {
    this.params = { ...this.defaults };
  }

  getAll() {
    return { ...this.params };
  }

  setAll(newParams) {
    this.params = { ...this.params, ...newParams };
  }
}

const configDefaults = {
  API_URL: process.env.REACT_APP_API_URL // 'https://api-staging.mytexturecare.com' or 'http://127.0.0.1:8000'
};

const env = new Env(configDefaults);

export default env;
