import React, { useState } from "react";
import { useNavigate } from 'react-router-dom'
import { useValidateRequest } from "../../contexts/ValidateRequest"
import { useStateContext } from "../../contexts/ContextProviders";

import { ReactComponent as HeartIcon } from "../../assets/icons/heart_icon.svg";
import { BsHeartFill } from "react-icons/bs";
import { ReactComponent as CommentIcon } from "../../assets/icons/comment_icon.svg";
import { ReactComponent as ShareIcon } from "../../assets/icons/share_icon.svg";
import { ReactComponent as ArrowUp } from "../../assets/icons/arrowup_icon.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrowdown_icon.svg";
import { TbArrowBigUpFilled, TbArrowBigDownFilled } from 'react-icons/tb';
import { FaRegThumbsDown, FaThumbsDown } from 'react-icons/fa';

const PostCard = ({ post }) => {
  const [iscopied, setIscopied] = useState(false)
  const [userVoteStatus, setUserVoteStatus] = useState(post?.metrics?.user_has_voted)
  const [like, setLike] = useState(post?.metrics?.upvote_count)
  const [unlike, setUnlike] = useState(post?.metrics?.downvote_count)
  const UserImg = "https://imagedelivery.net/Tk7nDsSjmVd54VkZiW6IyA/ee43e1e6-4c51-41ab-9c9a-fe626d186700/public"

  const { role } = useStateContext()
  const navigate = useNavigate()
  const { axiosApiCall } = useValidateRequest();


  /* This function copies the individual post URL to clipboard */
  const copyToClipboard = async (id) => {
    let baseUrl = `${window.location.origin}`;

    switch (post.type) {
      case 'discussion':
        baseUrl += `/discussion/${id}`;
        break;
      case 'image':
        baseUrl += `/imagepost/${id}`;
        break;
      default:
        baseUrl += `/product/${id}`;
    }

    try {
      await navigator.clipboard.writeText(baseUrl);
      setIscopied(true);
      setTimeout(() => {
        setIscopied(false);
      }, 1000);
      // console.log('Text copied to clipboard', baseUrl);
    } catch (error) {
      console.error('Error copying text: ', error);
    }
  };

  // Handles Vote
  const handleVote = async (id, type, action, updateFunction) => {
    try {
      const data = new FormData();
      data.set("target_object_id", id);
      data.set("target_type", type);
      data.set("target_user_id", post.user_id);

      const response = await axiosApiCall(action, "post", data, "multipart/form-data");
      if (response.status === 200) {
        updateFunction(id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // This function upvotes a post
  const upVotePost = (id) => handleVote(id, post.type, "vote", getSinglePost);
  const downVotePost = (id) => handleVote(id, post.type, "devote", getSinglePost);

  const getSinglePost = async (id) => {
    try {
      const params = { include_metrics: true }
      const { data } = await axiosApiCall(`post/${id}`, 'get', params);
      setLike(data?.data?.metrics?.upvote_count)
      setUnlike(data?.data?.metrics?.downvote_count)
      setUserVoteStatus(data?.data?.metrics?.user_has_voted)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className={`p-4 bg-white rounded-lg shadow-md mb-8 ${post.type === "image" ? 'lg:h-[17rem]' : post.type === "discussion" ? 'lg:h-[20rem]' : 'lg:h-[14rem]'}`}>
      {post.type.charAt(0).toUpperCase() + post.type.slice(1) === "Image" ? (
        <div className="relative">
          <span className="bg-[#F7E583] border-[#0e0d09] w-[6rem] rounded-xl p-[3px] text-sm absolute top-[1rem] right-[1rem] text-center" > {post.type.charAt(0).toUpperCase() + post.type.slice(1)}</span>
          <div onClick={() => navigate(`/imagepost/${post.id}`)} className="flex justify-center">
            <img
              src={post.url === null ? UserImg : post.url}
              alt={post.content}
              className="w-auto rounded-md lg:h-[13rem]"
            />
            <div className="mt-2 text-left pl-2 absolute bottom-[2rem] text-white text-sm bg-gradient-to-b from-[#00000000] to-black w-full h-[2rem] rounded-b-lg">{post.username}</div>
          </div>
          <div className="flex items-center justify-between mt-2">
            <div className=" w-[35%] flex items-center justify-between space-x-1 text-[#419fbd]">
              <div className="likes flex items-center">
                {userVoteStatus === 0 ? (<HeartIcon className="w-4" onClick={() => upVotePost(post.id)} />) : (userVoteStatus === 1) ? (<BsHeartFill className="w-4" />) : (<HeartIcon className="w-4" onClick={() => upVotePost(post.id)} />)}
                <span className="ml-1">{role === "ADMIN" && like}</span>
              </div>
              <div className="comment flex items-center">
                {userVoteStatus === 0 ? (<FaRegThumbsDown className="w-4" onClick={() => downVotePost(post.id)} />) : (userVoteStatus === -1) ? (<FaThumbsDown className="w-4" />) : (<FaRegThumbsDown className="w-4" onClick={() => downVotePost(post.id)} />)}
                <span className="ml-1">{role === "ADMIN" && unlike}</span>
              </div>
            </div>
            <div className="share-wrap flex justify-between w-[35%]">
              <div className="comment flex text-[#419fbd]">
                <CommentIcon className="w-4" />
                <span className="ml-1">{role === "ADMIN" && (post?.metrics?.comment_count)}</span>
              </div>
              <div className="flex items-center space-x-1 text-red-500" onClick={() => copyToClipboard(post.id)}>
                <ShareIcon className="w-4" />
              </div>
            </div>
            {/* Copy notification. It shows when a user clicks the share icon  */}
            {iscopied && <span className="text-[var(--Cobalt-Blue)] text-xs absolute bg-[#e6e2dffd] z-10 p-2 rounded-lg bottom-8 right-0 lg:bottom-0 lg:right-[-4.5rem]">Copied!</span>}
          </div>
        </div>
      ) : post.type.charAt(0).toUpperCase() + post.type.slice(1) === "Product" ? (
        <div className="relative">
          <span className="bg-[#A1F783] border-[#74E358] w-[5rem] rounded-xl p-[3px] text-sm absolute top-[1rem] right-[1rem] text-center" > {post.type.charAt(0).toUpperCase() + post.type.slice(1)}</span>
          <div onClick={() => navigate(`/product/${post.id}`)}>
            <img src={post.url === null ? UserImg : post.url} alt={post.title} className="w-full rounded-md lg:h-[10rem]" />
            <div className="mt-2 text-left pl-2 absolute bottom-[2rem] text-white text-sm bg-gradient-to-b from-[#00000000] to-black w-full h-[2rem] rounded-b-lg">{post.username}</div>
          </div>
          <div className="flex items-center justify-between mt-2">
            <div className="vote-wrap flex items-center justify-between w-[35%]">
              <div className="flex items-center space-x-1 text-green-500">
                {userVoteStatus === 0 ? (<ArrowUp className="w-4" onClick={() => upVotePost(post.id)} />) : (userVoteStatus === 1) ? (<TbArrowBigUpFilled className="w-4" />) : (<ArrowUp className="w-4" onClick={() => upVotePost(post.id)} />)}
                <span className="ml-1">{like}</span>
              </div>
              <div className="flex items-center space-x-1 text-red-500">
                {userVoteStatus === 0 ? (<ArrowDown className="w-4" onClick={() => downVotePost(post.id)} />) : (userVoteStatus === -1) ? (<TbArrowBigDownFilled className="w-4" />) : (<ArrowDown className="w-4" onClick={() => downVotePost(post.id)} />)}
                <span className="ml-1">{unlike}</span>
              </div>
            </div>
            <div className="share-wrap flex justify-between w-[35%]">
              <div className="comment flex text-[#419fbd]">
                <CommentIcon className="w-4" />
                <span className="ml-1">{role === "ADMIN" && (post?.metrics?.comment_count)}</span>
              </div>
              <div className="flex items-center space-x-1 text-red-500" onClick={() => copyToClipboard(post.id)}>
                <ShareIcon className="w-4" />
              </div>
            </div>
            {/* Copy notification. It shows when a user clicks the share icon  */}
            {iscopied && <span className="text-[var(--Cobalt-Blue)] text-xs absolute bg-[#e6e2dffd] z-10 p-2 rounded-lg bottom-8 lg:bottom-0 right-0 lg:right-[-4.5rem]">Copied!</span>}
          </div>
        </div>
      ) : (
        <div className="relative">
          <span className="bg-[#BEDACE] border-[#8EC2AC] w-[6rem] rounded-xl px-3 py-[3px] text-sm absolute top-[1rem] right-[1rem] z-10" > {post.type.charAt(0).toUpperCase() + post.type.slice(1)}</span>
          <div className="flex flex-col justify-center items-center" onClick={() => navigate(`/discussion/${post.id}`)}>
            {post.url !== null ? <img src={post.url} alt={post.username} className="w-auto rounded-md h-[16rem]" />  : <div className="background bg-[#419fbd] w-full rounded-md h-[16rem]"></div>}
            <div className="overlay bg-[#0000004f] absolute w-full h-[16rem]"></div>
            <p className="title absolute top-[40%] w-full text-center text-white text-wrap z-30 px-[4px] break-words">{post.title}</p>
            <div className="mt-2 text-left pl-2 absolute bottom-[2rem] text-white text-sm bg-gradient-to-b from-[#00000000] to-black w-full h-[2rem] rounded-b-lg">{post.username}</div>
          </div>
          <div className="flex items-center justify-between mt-2">
            <div className="vote-wrap flex items-center justify-between w-[35%]">
              <div className="flex items-center space-x-1 text-green-500">
                {userVoteStatus === 0 ? (<ArrowUp className="w-4" onClick={() => upVotePost(post.id)} />) : (userVoteStatus === 1) ? (<TbArrowBigUpFilled className="w-4" />) : (<ArrowUp className="w-4" onClick={() => upVotePost(post.id)} />)}
                <span className="ml-1">{like}</span>
              </div>
              <div className="flex items-center space-x-1 text-red-500">
                {userVoteStatus === 0 ? (<ArrowDown className="w-4" onClick={() => downVotePost(post.id)} />) : (userVoteStatus === -1) ? (<TbArrowBigDownFilled className="w-4" />) : (<ArrowDown className="w-4" onClick={() => downVotePost(post.id)} />)}
                <span className="ml-1">{unlike}</span>
              </div>
            </div>
            <div className="share-wrap flex justify-between w-[35%]">
              <div className="comment flex text-[#419fbd]">
                <CommentIcon className="w-4" />
                <span className="ml-1">{role === "ADMIN" && (post?.metrics?.comment_count)}</span>
              </div>
              <div className="flex items-center space-x-1 text-red-500" onClick={() => copyToClipboard(post.id)}>
                <ShareIcon className="w-4" />
              </div>
            </div>
            {/* Copy notification. It shows when a user clicks the share icon  */}
            {iscopied && <span className="text-[var(--Cobalt-Blue)] text-xs absolute bg-[#e6e2dffd] z-10 p-2 rounded-lg bottom-8 lg:bottom-0 right-0 lg:right-[-4.5rem]">Copied!</span>}
          </div>
        </div>
      )}
    </div>
  );
};

export default PostCard;
