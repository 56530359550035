import React from "react";
import { ReactComponent as CaretLeft } from "../../assets/icons/caret_left.svg";

const BackButton = ({ text = "Back", textColor, outlineColor, onClick, className, ...props }) => {
    return (
        <button
            className={`${className} text-black py-2 px-4 rounded-full focus:outline focus:shadow-outline border border-gray-300 flex`}
            type="button"
            onClick={onClick}
            {...props}
        >
            <CaretLeft /> {text}
        </button>
    )
}

export default BackButton;