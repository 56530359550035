import React, { useEffect, useState } from "react";
import { useStateContext } from "../../contexts/ContextProviders";
import { useValidateRequest } from "../../contexts/ValidateRequest";

import UserfeedHeader from "../../components/UserfeedHeader";
import ProductCard from "../../components/cards/ProductCard";
import ModalContainer from "../../components/modals/ModalContainer";
import UploadModal from "../../components/modals/UploadModal";
import UploadDiscussionModal from "../../components/modals/UploadDiscussionModal";
import UploadProductModal from "../../components/modals/UploadProductModal";
import { toast } from "react-toastify";
import { BiLoaderCircle } from "react-icons/bi";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [fetchError, setFetchError] = useState("");

  const { isClicked } = useStateContext();
  const { axiosApiCall } = useValidateRequest();

  useEffect(() => {
    axiosApiCall(
      `post?page=1&limit=100000&content_type=product&include_metrics=true`,
      "get"
    ).then((res) => {
      res.status === 200
        ? setProducts(res.data.data.post)
        : setFetchError("Something went wrong, retrying...");
    });
  }, [axiosApiCall]);

  fetchError && toast.error(fetchError);

  // console.log(products);

  return (
    <div className="h-full">
      <UserfeedHeader />
      {/* postContentBtn state - This is the entry points to opening the modal container */}
      {isClicked.postContentBtn && <ModalContainer />}
      {/* admins only */}
      {isClicked.openProductUploadModal && <UploadProductModal />}
      {/* all users */}
      {isClicked.openUploadModal && <UploadModal />}
      {isClicked.openDiscussionUploadModal && <UploadDiscussionModal />}

      {products.length === 0 ? (
        <div className="w-full h-[70vh] lg:h-[85vh] flex justify-center items-center">
          <BiLoaderCircle className="w-20 h-20 lg:w-28 lg:h-28 text-[var(--Cobalt-Blue)] animate-spin" />
        </div>
      ) : (
        <div className="container mx-auto">
          <div className="grid lg:grid-cols-4 gap-4 mt-8 lg:m-0 lg:px-10 ">
            {products &&
              products.map((product) => (
                <div
                  key={product.id}
                  className="p-4 rounded flex flex-col items-center justify-center product-grid-item"
                >
                  <ProductCard id={product.id} product={product} />
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Products;
