import React from "react";
import { Link } from "react-router-dom";

import { PiShoppingBagOpenLight } from "react-icons/pi";
import productImg from "../assets/product.png";

const RelatedProducts = ({post}) => {

  return (
    <div className="flex flex-col gap-y-4 mb-8 last:mb-0">
      <div className="relative flex flex-col justify-center gap-y-2 p-4 bg-[#5fbad8]/10 rounded-[24px] ">
        <img
          src={post ? post.url : productImg } 
          alt="product"
          className="w-full md:w-[250px] h-[301px] rounded-xl object-cover"
        />

        <Link
          to={`/product/${post.id}`}
          state={post}
          className="absolute right-8 bottom-16 p-2 h-10 w-10 rounded-full bg-white"
        >
          <PiShoppingBagOpenLight className="w-full h-full opacity-80 hover:opacity-100" />
        </Link>

        <p className="text-xs w-[250px]">
          {post ? post.title : 'An affordable oil product'}
        </p>
      </div>
    </div>
  );
};

export default RelatedProducts;
