// Checking token validation
export const isTokenValid = (token) => {
  if (!token) return false;

  try {
    const parts = token.split(".");
    // Ensure the token has three parts
    if (parts.length !== 3) return false;

    const payload = JSON.parse(atob(parts[1]));
    // Ensure the payload contains the exp field
    if (!payload.exp) return false;

    const isExpired = payload.exp < Date.now() / 1000;
    return !isExpired;
  } catch (e) {
    return false;
  }
};

export const isLoggedIn = () => {
  const token = window.localStorage.getItem("validationToken");
  return isTokenValid(token);
};

export const validatePath = () => {
  const location = window.location.pathname;

  const nonValidatedRoutes = [
    "/", "/about-us"
  ];

  if (nonValidatedRoutes.includes(location)) return;

  const nonProtectedRoutes = [
    "/sign-in",
    "/onboarding",
    "/onboarding/:invite_code",
  ];
  const validLogin = isLoggedIn();
  const isProtectedRoute = !nonProtectedRoutes.some((route) =>
    location.startsWith(route)
  );

  switch (true) {
    case !validLogin && isProtectedRoute:
      window.location.pathname = "/sign-in";
      break;
    case validLogin && !isProtectedRoute:
      window.location.pathname = "/userfeed";
      break;
    default:
      // Alow page to render
      break;
  }
};

export const validateEmail = (email) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(String(email).toLowerCase());
};
