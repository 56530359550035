import React, { useState, useEffect } from "react";
import Select from "react-select";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useStateContext } from "../../contexts/ContextProviders";
import { useValidateRequest } from "../../contexts/ValidateRequest";
import { useDropzone } from "react-dropzone";

import { Modal } from "react-responsive-modal";
import { useMediaQuery } from "react-responsive";

import { PiCaretLeft } from "react-icons/pi";
import { BsUpload } from "react-icons/bs";
import { GoPencil } from "react-icons/go";
import { hairTagOptions } from "./UploadModal";
import { productTypeOptions } from "./UploadModal";
import { post_type } from "./UploadModal";

const UploadDiscussionModal = () => {
  const [uploadedPhoto, setUploadedPhoto] = useState(null);
  const [hairTag, setHairTag] = useState([]);
  const [productType, setProductType] = useState([]);

  const [topic, setTopic] = useState("");
  const [description, setDescription] = useState("");

  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [selectType, setSelectType] = useState(null);

  //Drag and drop doesn't work well on ubuntu 22.04 [wayland]
  //Fix for now is changing wayland to xorg
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFile) => {
      setUploadedPhoto(
        Object.assign(acceptedFile[0], {
          preview: URL.createObjectURL(acceptedFile[0]),
        })
      );
    },
  });

  const { setIsClicked } = useStateContext();
  const { axiosApiCall } = useValidateRequest();
  const isMobile = useMediaQuery({ maxWidth: 767 }); // Mobile check

  useEffect(() => {
    // Making sure the data urls are revoked to avoid memory leaks>
    return () => window.URL.revokeObjectURL(uploadedPhoto);
  }, [uploadedPhoto]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData();
      const hairTagStr = hairTag.map((tag) => tag.value).join(",");
      const productTagStr = productType.map((tag) => tag.value).join(",");

      data.set("post_type", post_type.discussion);
      data.set("topic", topic);
      data.set("description", description);
      data.set("hair_type_tags", hairTagStr);
      data.set("product_type_tags", productTagStr);
      data.set("img", uploadedPhoto || "");

      const response = axiosApiCall(
        "post",
        "post",
        data,
        "multipart/form-data"
      );
      const notification = await toast.promise(response, {
        pending: 'Loading', // Display while posting
        success: 'Discussion post successfully posted',
        error: {
          render({ data }) {
            // When the api returns error
            return (data?.response?.data?.msg || "Something went wrong")
          },
        },

        autoClose: 2000,
        transition: Slide,
      });
      setTimeout(() => {
        setIsClicked({
          postContentBtn: false,
          openUploadModal: false,
          openDiscussionUploadModal: false,

        })
      }, 3000)
      notification()

    } catch (error) {
      console.log(error);
    } finally {
      window.location.reload();
    }
  };

  return (
    <section className="fixed left-0 top-[7%] lg:top-[80px] z-50 p-4 lg:px-0 w-screen h-screen lg:h-[calc(100vh-80px)] overflow-y-auto bg-white duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]">
      <form
        onSubmit={handleSubmit}
        className="w-full lg:w-full h-full relative lg:flex flex-col items-center justify-center"
      >
        <button
          type="button"
          onClick={() =>
            setIsClicked({
              postContentBtn: false,
              openUploadModal: false,
              openDiscussionUploadModal: false,
            })
          }
          className="absolute flex items-center gap-x-2 top-2 lg:left-14 text-[#003249] text-sm font-[400] bg-[#FCFCFD] hover:bg-[#F2F9FB] py-1.5 lg:py-2 px-6 lg:px-4 border rounded-2xl"
        >
          <PiCaretLeft className="w-4 h-4" />
          <p className="">Back</p>
        </button>

        <div className="absolute top-14 flex flex-col w-full md:w-[584px]">
          <p className="text-sm font-[400] mb-2">Upload image</p>
          <div
            {...getRootProps({
              className:
                "relative flex flex-col w-[100%] h-[250px] items-center justify-center bg-[#FFF8F4] border border-dotted border-[#FFC5A4] px-[45px] py-8 mb-[22px] rounded-3xl  hover:bg-[#FFF8F4]/60 cursor-pointer",
            })}
          >
            <input
              {...getInputProps()}
              className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer z-50"
            />

            {uploadedPhoto && (
              <>
                <div className="absolute w-full h-full z-10 rounded-3xl top-0 left-0">
                  <img
                    alt="preview"
                    src={uploadedPhoto.preview}
                    className="w-full h-full object-cover rounded-3xl"
                    onLoad={() => {
                      URL.revokeObjectURL(uploadedPhoto.preview);
                    }}
                  />
                </div>

                <div className="absolute top-4 right-4 flex items-center justify-center w-[28px] h-[28px] rounded-full bg-gray-50 p-1 z-50">
                  <GoPencil className="text-gray-800" />
                </div>
              </>
            )}

            <BsUpload className="h-[42px] w-[42px] font-light" />
            <div className="flex flex-col w-[301px] gap-y-6">
              <p className="text-center">Choose a file</p>
              <p className="text-sm text-center">
                {uploadedPhoto && uploadedPhoto?.preview
                  ? ""
                  : "Image must be JPG, PNG and should be less than 20MB"}
              </p>
            </div>
          </div>

          <div className="flex flex-col w-[100%] lg:w-[601px]">
            <div className="flex flex-col gap-y-4">
              <div className="flex flex-col gap-y-1">
                <p className="text-sm font-[400]">Discussion topic</p>
                <input
                  type="text"
                  placeholder="Enter a topic"
                  onChange={(e) => setTopic(e.target.value)}
                  value={topic}
                  className="w-full border-2 border-gray-300 rounded active:border-[#007EA7] focus:border-[#007EA7] focus:border-2 active:border-2 text-sm placeholder:text-opacity-90 px-[16px] py-[8px] outline-none hover:border-gray-400"
                  required
                />
              </div>

              <div className="flex flex-col gap-y-1">
                <p className="text-sm font-[400]">
                  Give more context to your topic
                </p>
                <textarea
                  placeholder="Enter text"
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                  className="w-full border-2 border-gray-300 rounded active:border-[#007EA7] focus:border-[#007EA7] focus:border-2 active:border-2 text-sm placeholder:text-opacity-90 h-[160px] px-[16px] py-[8px] outline-none hover:border-gray-400"
                />
              </div>

              <div className="flex flex-col gap-y-3">
                {isMobile ? (
                  <>
                    <div className="flex flex-col gap-y-3">
                      <p className="text-sm font-[400] mt-1">Add tags</p>
                      <div className="w-full flex flex-col gap-y-2 md:grid grid-cols-2 gap-x-2">
                        <button
                          type="button"
                          onClick={() => {
                            setSelectType("hairTag");
                            setIsSelectOpen(true);
                          }}
                          className="w-full border-2 border-gray-300 rounded py-2 text-sm"
                        >
                          Hair Type
                        </button>

                        <button
                          type="button"
                          onClick={() => {
                            setSelectType("productType");
                            setIsSelectOpen(true);
                          }}
                          className="w-full border-2 border-gray-300 rounded py-2 text-sm"
                        >
                          Product Type
                        </button>
                      </div>
                    </div>
                    <Modal
                      open={isSelectOpen}
                      onClose={() => setIsSelectOpen(false)}
                      center
                      classNames={{
                        modal: "w-[90%] md:w-[50%] bg-white rounded-lg p-4",
                      }}
                    >
                      <h2 className="text-xl mb-4">
                        Select {selectType === "hairTag" ? "Hair Type" : "Product Type"}
                      </h2>
                      <Select
                        defaultValue={selectType === "hairTag" ? hairTag : productType}
                        isMulti
                        name={selectType}
                        options={selectType === "hairTag" ? hairTagOptions : productTypeOptions}
                        onChange={(selectedOptions) =>
                          selectType === "hairTag"
                            ? setHairTag(selectedOptions)
                            : setProductType(selectedOptions)
                        }
                        menuPlacement="auto"
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        styles={{
                          menuPortal: base => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    </Modal>
                  </>
                ) : (
                  <div className="w-full flex flex-col gap-y-2 md:grid grid-cols-2 gap-x-2">
                    <Select
                      defaultValue={hairTag}
                      isMulti
                      name="hairTag"
                      options={hairTagOptions}
                      onChange={setHairTag}
                      className="text-sm placeholder:text-opacity-90 active:border-[#007EA7] focus:border-[#007EA7]"
                      classNamePrefix="select"
                      placeholder="Hair type"
                      menuPlacement="auto"
                      onFocus={(e) => {
                        e.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
                      }}
                    />
                    <Select
                      defaultValue={productType}
                      isMulti
                      name="productType"
                      options={productTypeOptions}
                      onChange={setProductType}
                      className="text-sm placeholder:text-opacity-90 active:border-[#007EA7] focus:border-[#007EA7]"
                      classNamePrefix="select"
                      placeholder="Product type"
                      menuPlacement="auto"
                      onFocus={(e) => {
                        e.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
                      }}
                    />
                  </div>)}
              </div>

              <button
                type="submit"
                className="flex items-center justify-center w-full py-[8px] text-sm text-[#FFF8F4] font-[400] bg-[#007EA7] hover:bg-opacity-95 rounded mb-36"
              >
                Post content
              </button>
            </div>
          </div>
        </div>
      </form>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </section>
  );
};

export default UploadDiscussionModal;
