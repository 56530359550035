import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useStateContext } from "../../contexts/ContextProviders";
import { useValidateRequest } from "../../contexts/ValidateRequest";

import { Slide, toast } from "react-toastify";
import { BiLoaderCircle } from "react-icons/bi";

const DeleteImagePost = ({ imageSrc, username, postId }) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const { setIsClicked } = useStateContext();
  const { axiosApiCall } = useValidateRequest();

  const navigate = useNavigate();

  const handleDelete = async (e) => {
    e.preventDefault();
    setIsDeleting(true);
    try {
      const response = axiosApiCall(`post?post_id=${postId}`, "delete");

      const resolved = await toast.promise(response, {
        error: {
          render(data) {
            return data?.data?.response?.data?.msg
              ? data?.data?.response?.data?.msg
              : "Something went wrong";
          },
        },
        autoClose: 2000,
        transition: Slide,
      });
      const res = await resolved;
      res && res.status === 200 && toast.success("Deleted successfully");
      res && res.status === 200 && navigate(-1);
    } catch (error) {
      setIsDeleting(false);
    } finally {
      setIsClicked((prevModals) => ({
        ...prevModals,
        deleteImagePost: false,
      }));
    }
  };

  return (
    <div className="modal-warp fixed inset-0 z-50 bg-black/40 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0">
      <div className="flex flex-col gap-y-4 fixed left-[50%] top-[15%] md:top-[50%] z-50 w-[95%] lg:w-[490px] h-[70%] lg:h-fit rounded-[16px] p-[20px] translate-x-[-50%] lg:translate-y-[-50%] gap-4 bg-white duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]">
        <p className="font-[400] text-[20px] leading-8 text-[#000000]">
          Delete Image Post <br />
          <span className="text-sm">
            Are you sure you want to delete this product post?
          </span>
        </p>

        <div className="rounded-md relative h-[250px]">
          <img
            src={imageSrc}
            alt="delete-img"
            className="w-full h-full rounded-lg object-cover"
          />
          <p className="mt-1 text-left w-full pl-2 absolute bottom-[0] left-0 text-white text-sm bg-gradient-to-b from-[#00000000] to-[#000000e0] h-[2rem] rounded-b-lg">
            {username}
          </p>
        </div>

        <div className="flex flex-col gap-y-4">
          <button
            type="button"
            onClick={(e) => {
              handleDelete(e);
            }}
            className="flex items-center justify-center w-full py-3 text-sm text-[#FCFCFD] bg-[var(--Cobalt-Blue)] hover:bg-[#006080] rounded "
          >
            {isDeleting ? (
              <BiLoaderCircle className="w-5 h-5 text-white animate-spin" />
            ) : (
              "Delete Post"
            )}
          </button>

          <button
            type="button"
            onClick={() => {
              setIsClicked((prevModals) => ({
                ...prevModals,
                deleteImagePost: false,
              }));
            }}
            className="py-3 w-full text-center text-[#007EA7] rounded font-[400] bg-gray-600/10 hover:bg-gray-500/10"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteImagePost;
