import React, { useState } from "react"

import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useValidateRequest } from "../../contexts/ValidateRequest"
import { useStateContext } from "../../contexts/ContextProviders"

import { toast, Slide } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import { PiEyeLight, PiEyeSlash } from "react-icons/pi"
import { BiLoaderCircle } from "react-icons/bi"
import { jwtDecode } from "jwt-decode"

const SignInForm = () => {
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [statusCode, setStatusCode] = useState(null)

  const navigate = useNavigate()

  const { axiosApiCall } = useValidateRequest()
  const { setRole, setUserId } = useStateContext()

  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
  })

  const onSubmit = async (data) => {
    try {
      const formData = new FormData()

      formData.set("username", data.username)
      formData.set("password", data.password)

      const response = axiosApiCall(
        "user/login",
        "post",
        formData,
        "application/x-www-form-urlencoded"
      )

      const resolved = await toast.promise(response, {
        success: "Hey there, welcome back!",
        error: {
          render({ data }) {
            return data?.response?.data?.msg === "username or email not exist"
              ? "Invalid username or email, please verify and try again"
              : data?.response?.data?.msg === "wrong password"
                ? "Wrong password, please verify and try again"
                : "Something went wrong"
          },
        },
        autoClose: 2000,
        transition: Slide,
      })

      const res = await resolved
      res &&
        res.status === 200 &&
        window.localStorage.setItem("validationToken", res.data.data._tk)

      const decodedToken = res.status === 200 && jwtDecode(res.data.data._tk)

      setUserId(decodedToken?.user_id)
      setRole(decodedToken?.role)

      res && res.status === 200 && setStatusCode(res.status)

      res && res.status === 200 && navigate("/userfeed")
    } catch (error) {
      console.error(error)
    } finally {
      if (statusCode) {
        reset({
          username: "",
          password: "",
        })
        setPasswordVisible(false)
      }
    }
  }

  return (
    <div className="form-wrap w-full h-auto">
      <form
        className={`w-[90%] md:w-[34rem] h-fit p-[1.5rem] bg-white absolute m-auto left-0 right-0 top-0 bottom-0 rounded-xl`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <h1 className="text-4xl font-extrabold mb-2">Welcome back!</h1>
        <p className="text-sm mb-6 font-semibold">Let us Know your details</p>
        <div className="wrap flex flex-col gap-y-4">
          <div className="flex flex-col gap-y-1">
            <label
              htmlFor="username"
              className="mb-1 text-[14px] font-semibold"
            >
              Email address or username
            </label>
            <input
              type="text"
              id="username"
              name="username"
              {...register("username")}
              className="border outline-0 border-gray-300 rounded py-3 px-4 text-sm active:border-[#007EA7] focus:border-[#007EA7] hover:border-gray-400 placeholder-gray-500"
              placeholder="Email or Username"
              required
            />
          </div>

          <div className="relative flex flex-col gap-y-1">
            <label
              htmlFor="password"
              className="mb-1 text-[14px] font-semibold"
            >
              Password
            </label>
            <input
              type={!passwordVisible ? "password" : "text"}
              id="password"
              name="password"
              {...register("password")}
              className="border outline-0 border-gray-300 rounded py-3 px-4 text-sm active:border-[#007EA7] focus:border-[#007EA7] hover:border-gray-400 placeholder-gray-500"
              placeholder="Enter password"
              required
            />
            <button
              type="button"
              onClick={() => setPasswordVisible((prev) => !prev)}
              className="absolute inset-y-0 top-[40%] right-4 flex items-center text-gray-600 hover:text-gray-800"
            >
              {passwordVisible ? (
                <PiEyeSlash className="w-6 h-6" />
              ) : (
                <PiEyeLight className="w-6 h-6" />
              )}
            </button>
          </div>

          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full flex items-center justify-center bg-[#007EA7] rounded-lg p-2 text-white mt-4"
          >
            {isSubmitting ? (
              <BiLoaderCircle className="w-5 h-5 text-white animate-spin" />
            ) : (
              "Sign In"
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export default SignInForm
