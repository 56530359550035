import React, { useState, useEffect, useCallback } from "react";
import { useStateContext } from "../../contexts/ContextProviders";
import { useValidateRequest } from "../../contexts/ValidateRequest";
import PostCard from "./PostCard";
import DashboardHeader from "../../components/UserfeedHeader";
import ModalContainer from "../../components/modals/ModalContainer";
import UploadModal from "../../components/modals/UploadModal";
import UploadDiscussionModal from "../../components/modals/UploadDiscussionModal";
import UploadProductModal from "../../components/modals/UploadProductModal";


const Userfeed = () => {
  const [response, setResponse] = useState([]);
  const [filter, setFilter] = useState(null)
  const [page, setPage] = useState(1);


  const { isClicked } = useStateContext(); // this sets the isClicked global states used to manipulate the posts modals
  const { axiosApiCall } = useValidateRequest(); // this provides the network call for this userfeed page



  // Function Checks if the user has reached the bottom of the page and triggers next page load
  const handleScroll = () => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  const feed = useCallback(async () => {
    const params = {
      page: page,
      limit: 20,
      hair_type_tags: "",
      product_type_tags: "",
      include_metrics: true
    }

    const getSelectedTags = (tags) =>
      Object.keys(tags).filter(key => tags[key]).map(tag => tag.toLowerCase()).join(",");

    if (filter != null) {
      params.hair_type_tags = getSelectedTags(filter.hairType);
      params.product_type_tags = getSelectedTags(filter.productType);

    } try {
      const { data } = await axiosApiCall('post', 'get', params);
      const contentData = sortData(data?.data);
      // setResponse(contentData);

      setResponse(prevPosts => {
        const existingIds = new Set(prevPosts.map(post => post.id));
        const newPosts = contentData.filter(post => !existingIds.has(post.id));
        return page === 1 ? contentData : [...prevPosts, ...newPosts];
      });
      // setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, [filter, page]);

  useEffect(() => {
    feed();
  }, [feed]);

  const sortData = (arrayData) => {
    let sort = []
    if (arrayData != null) {
      // Separate data by type
      const imagePost = arrayData?.post.filter(post => post.type === 'image');
      const discussionPost = arrayData?.post.filter(post => post.type === 'discussion');
      const products = arrayData?.post.filter(post => post.type === 'product');

      const maxLength = Math.max(imagePost.length, discussionPost.length, products.length);

      // Push items into sortedData array in the desired order repeatedly
      for (let i = 0; i < maxLength; i++) {
        if (imagePost[i]) sort.push(imagePost[i]);
        if (products[i]) sort.push(products[i]);
        if (discussionPost[i]) sort.push(discussionPost[i]);
      }
    }
    return sort

  }

  return (
    <div>
      <DashboardHeader setFilter={setFilter} setPage={setPage} setResponse={setResponse} />
      {/* postContentBtn state - This is the entry points to opening the modal container */}
      {isClicked.postContentBtn && <ModalContainer />}
      {/* admins only */}
      {isClicked.openProductUploadModal && <UploadProductModal />}
      {isClicked.openUploadModal && <UploadModal />}
      {isClicked.openDiscussionUploadModal && <UploadDiscussionModal />}

      <div className="container mx-auto">
        <div className={`grid lg:grid-cols-4 gap-4 mt-8 lg:m-0 lg:px-10 ${isClicked.openProductUploadModal || isClicked.openDiscussionUploadModal || isClicked.openUploadModal ? 'h-screen overflow-y-hidden' : ''}`}>

          {/* From API */}
          {response && (response?.map((post) => (
            <PostCard key={post.id} post={post} feed={feed} />
          )))}

        </div>
      </div>

    </div>
  );
};

export default Userfeed;
