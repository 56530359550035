import React from 'react'

const ShareIcon = () => {
  return (
    <svg
    width={20}
    height={20}
    viewBox="0 0 28 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.7074 10.2938L17.7074 0.293776C17.5676 0.15384 17.3895 0.0584977 17.1955 0.0198076C17.0016 -0.0188826 16.8005 0.000816822 16.6177 0.0764143C16.4349 0.152012 16.2787 0.280112 16.1687 0.444514C16.0588 0.608916 16 0.802236 15.9999 1.00003V6.04378C12.7574 6.32128 9.17617 7.90878 6.22992 10.4075C2.68242 13.4175 0.473674 17.2963 0.00992401 21.3288C-0.0263163 21.6423 0.0372509 21.9592 0.191579 22.2345C0.345907 22.5098 0.583132 22.7294 0.869495 22.8621C1.15586 22.9947 1.47677 23.0337 1.78655 22.9734C2.09634 22.9131 2.37921 22.7566 2.59492 22.5263C3.96992 21.0625 8.86242 16.4338 15.9999 16.0263V21C16 21.1978 16.0588 21.3911 16.1687 21.5555C16.2787 21.7199 16.4349 21.848 16.6177 21.9236C16.8005 21.9992 17.0016 22.0189 17.1955 21.9802C17.3895 21.9416 17.5676 21.8462 17.7074 21.7063L27.7074 11.7063C27.8944 11.5188 27.9995 11.2648 27.9995 11C27.9995 10.7352 27.8944 10.4812 27.7074 10.2938ZM17.9999 18.5863V15C17.9999 14.7348 17.8946 14.4805 17.707 14.2929C17.5195 14.1054 17.2651 14 16.9999 14C13.4899 14 10.0712 14.9163 6.83867 16.725C5.19235 17.6503 3.65844 18.7627 2.26742 20.04C2.99242 17.06 4.81992 14.2263 7.52367 11.9325C10.4262 9.47128 13.9687 8.00003 16.9999 8.00003C17.2651 8.00003 17.5195 7.89467 17.707 7.70713C17.8946 7.5196 17.9999 7.26524 17.9999 7.00003V3.41503L25.5862 11L17.9999 18.5863Z"
      fill="#007EA7"
    />
  </svg>
  
  )
}

export default ShareIcon;