import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useValidateRequest } from "../../contexts/ValidateRequest";
import { TbArrowBigDown, TbArrowBigUp } from "react-icons/tb";
import { TbArrowBigUpFilled, TbArrowBigDownFilled } from 'react-icons/tb';
import { FaRegComment } from "react-icons/fa";
import { BiShare } from "react-icons/bi";

const DiscussionPostCard = ({ post }) => {
  const [comments, setComments] = useState(null)
  const [iscopied, setIscopied] = useState(false);
  const [userVoteStatus, setUserVoteStatus] = useState(post?.metrics?.user_has_voted)
  const [like, setLike] = useState(post?.metrics?.upvote_count);
  const [unlike, setUnlike] = useState(post?.metrics?.downvote_count);
  

  const { axiosApiCall } = useValidateRequest();
  const navigate = useNavigate()

  /* This function copies the individual post URL to clipboard */
  const copyToClipboard = async (id) => {
    let baseUrl = `${window.location.origin}`;

    if (post.type === 'discussion')
      baseUrl += `/discussion/${id}`;

    try {
      await navigator.clipboard.writeText(baseUrl);
      setIscopied(true);
      setTimeout(() => {
        setIscopied(false);
      }, 1000);
      // console.log('Text copied to clipboard', baseUrl);
    } catch (error) {
      console.error("Error copying text: ", error);
    }
  };

  // Handles Vote
  const handlePostVote = async (id, type, action, updateFunction) => {
    try {
      const data = new FormData();
      data.set("target_object_id", id);
      data.set("target_type", type);
      data.set("target_user_id", post.user_id);

      const response = await axiosApiCall(action, "post", data, "multipart/form-data");
      if (response.status === 200) {
        updateFunction(id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCommentVote = async (id, type, action, updateFunction) => {
    try {
      const data = new FormData();
      data.set("target_object_id", id);
      data.set("target_type", type);
      data.set("target_user_id", post.user_id);

      const response = await axiosApiCall(action, "post", data, "multipart/form-data");
      if (response.status === 200) {
        updateFunction();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // This function upvotes a post
  const upVotePost = (id) => handlePostVote(id, post.type, "vote", getSinglePost);
  const downVotePost = (id) => handlePostVote(id, post.type, "devote", getSinglePost);
  const upVoteComment = (id) => handleCommentVote(id, 'comment', "vote", getComment);
  const downVoteComment = (id) => handleCommentVote(id, 'comment', "devote", getComment);


  const getSinglePost = async (id) => {
    try {
      const params = { include_metrics: true };
      const { data } = await axiosApiCall(`post/${id}`, "get", params);
      setLike(data?.data?.metrics?.upvote_count);
      setUnlike(data?.data?.metrics?.downvote_count);
      setUserVoteStatus(data?.data?.metrics?.user_has_voted)
    } catch (error) {
      console.log(error);
    }
  };

  const getComment = async () => {
    const params = {
      page: 1,
      limit: 1,
      target_id: post.id,
      include_metrics: true
    } 
    try {
      const getCommentResponse = await axiosApiCall('comment', 'get', params);
      setComments(getCommentResponse.data.data)       
    } 
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getComment()
  }, [])

  return (
    <div className=" rounded-t-3xl rounded-b-xl shadow-md w-full mb-8 h-fit bg-blue-500/20 ">
      <div className="relative w-[inherit] h-[10rem] ">
        <div
          className="flex flex-col w-[inherit] h-[10rem]"
        >
          {post.url !== null ? <img
            src={post.url}
            alt={post.title}
            className="w-auto rounded-3xl h-[10rem]"
          /> : <div className="background bg-[#419fbd] w-full rounded-3xl lg:h-[16rem]"></div>}
          <div className=" text-left pl-2 absolute top-0 text-white text-sm bg-[#0000004f] w-full h-[10rem] rounded-b-lg rounded-3xl" onClick={()=> navigate(`/discussion/${post.id}`)}></div>
          <p className="absolute top-[40%] w-full text-center text-white text-wrap z-30 px-[2px] break-words">
            {post.title}
          </p>
          <div className="w-full flex items-center justify-between px-4 absolute bottom-0 ">
            <div className="flex items-center gap-x-4">
              <button className="flex items-center gap-x-1 text-gray-100 hover:text-white">
                {userVoteStatus === 0 ? (<TbArrowBigUp className="w-6 h-8" onClick={() => upVotePost(post.id)}/>) : (userVoteStatus === 1) ? (<TbArrowBigUpFilled className="w-6 h-8" onClick={() => downVotePost(post.id)}/>) : (<TbArrowBigUp className="w-6 h-8" onClick={() => upVotePost(post.id)}/>)}
                <p className="font-semibold ">{like}</p>
              </button>
              <button className="flex items-center gap-x-1 text-gray-100 hover:text-white">
                { userVoteStatus === 0 ? (<TbArrowBigDown className="w-6 h-8" onClick={() => downVotePost(post.id)}/>) : (userVoteStatus === -1) ? (<TbArrowBigDownFilled className="w-6 h-8" onClick={() => upVotePost(post.id)}/>) : (<TbArrowBigDown className="w-6 h-8" onClick={() => downVotePost(post.id)}/>)} 
                <p className="font-semibold">{unlike}</p>
              </button>
              <div className="flex items-center gap-x-1 text-white">
                <FaRegComment className="w-6 h-8" />
                <p className="font-semibold">
                  {post?.metrics?.comment_count || 0}
                </p>
              </div>
            </div>

            <div
              onClick={() => copyToClipboard(post.id)}
              className="flex items-center text-gray-100 hover:text-white"
            >
              <BiShare className="w-6 h-8 -scale-x-[1]" />
            </div>
            {/* Copy notification. It shows when a user clicks the share icon  */}
            {iscopied && (
              <span className="text-[var(--Cobalt-Blue)] text-xs absolute bg-[#e6e2dffd] z-10 p-2 rounded-lg bottom-8 right-0 lg:bottom-0 lg:right-[-3rem]">
                Copied!
              </span>
            )}
          </div>
        </div>
      </div>
      {/* Comment */}
      {comments?.length > 0 ? (
        <div className="flex gap-x-2 px-2 pt-4 w-full">
          <p className="h-[35px] w-[35px] rounded-full flex items-center justify-center bg-gray-400 text-gray-700 font-bold">
            {comments[0]?.title?.slice(0,2).toUpperCase()}
          </p>

          <div className="flex flex-col w-[90%] gap-y-2 p-2 bg-blue-600/20 rounded-lg mb-2">
            <p className="text-sm text-gray-900 font-bold break-words">{ comments[0]?.title }</p>
            <p className="text-[12.5px] font-normal">
            {comments?.length > 0 ? comments[0]?.content : '' }      
            </p>
            <div className="flex items-center gap-x-4">
              <div className="flex items-center gap-x-1">
                {comments[0]?.metrics?.user_has_voted === 0 ? (<TbArrowBigUp className="w-6 h-6" onClick={() => upVoteComment(comments[0]?.id, post.id)}/>) : (comments[0]?.metrics?.user_has_voted === 1) ? (<TbArrowBigUpFilled className="w-6 h-6"/>) : (<TbArrowBigUp className="w-6 h-6" onClick={() => upVoteComment(comments[0]?.id, post.id)}/>)}
                <p className="font-semibold">{comments[0]?.metrics?.upvote_count}</p>
              </div>
              <div className="flex items-center gap-x-1">
                { comments[0]?.metrics?.user_has_voted === 0 ? (<TbArrowBigDown className="w-6 h-6" onClick={() => downVoteComment(comments[0]?.id, post.id)}/>) : (comments[0]?.metrics?.user_has_voted === -1) ? (<TbArrowBigDownFilled className="w-6 h-6"/>) : (<TbArrowBigDown className="w-6 h-6" onClick={() => downVoteComment(comments[0]?.id, post.id)}/>)}
                <p className="font-semibold">{comments[0]?.metrics?.downvote_count}</p>
              </div>
            </div>
          </div>
        </div>
      ) : ''}
      
      {/* Link */}
      <div className="flex items-center justify-end py-2 pb-4 pr-4">
        <Link
          to={`/discussion/${post?.id}`}
          state={post}
          className="text-xs font-semibold text-blue-600"
        >
          Read more
        </Link>
      </div>
    </div>
  );
};

export default DiscussionPostCard;
