import React from "react";

const OutlineButton = ({ text, textColor, outlineColor, onClick, ...props }) => {
    return (
        <button
            className="hover:bg-transparent hover:text-[var(--Cobalt-Blue)]  font-bold py-2 px-4 rounded outline outline-[var(--Cobalt-Blue)] outline-offset-1 focus:outline-none focus:ring-2 focus:ring-[var(--Cobalt-Blue)]"
            type="button"
            onClick={onClick}
            {...props}
        >
            {text}
        </button>
    )
}

export default OutlineButton;