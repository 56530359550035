import React, { useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import { toast, Slide } from "react-toastify";
import { BiLoaderCircle } from "react-icons/bi";
import countryList from "react-select-country-list";
import { useNavigate, useParams } from "react-router-dom";
import { useValidateRequest } from "../../contexts/ValidateRequest";
import { ReactComponent as EyeIcon } from '../../assets/icons/eye_icon.svg';
import { ReactComponent as EyeIconClose } from '../../assets/icons/eye_icon_close.svg';

const SignUpForm = ({ email }) => {
    const [errors, setErrors] = useState({});
    const [modalSteps, setModalSteps] = useState(1);
    const [filterHairTypeValues, setFilterHairTypeValues] = useState([]);
    const [filterHairCareValues, setFilterHairCareValues] = useState([]);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [statusCode, setStatusCode] = useState(0);
    const countryOptions = useMemo(() => countryList().getData(), []);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isConfirmPPasswordVisible, setIsConfirmPPasswordVisible] = useState(false);

    const params = useParams();
    const navigate = useNavigate();
    const { axiosApiCall } = useValidateRequest();

    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&])[A-Za-z\d@.#$!%*?&]{8,15}$/;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        const filter_values = filterHairTypeValues;
        checked
            ? filter_values.push(" " + name)
            : filter_values.splice(filter_values.indexOf(name), 1);
        const values = new Set(filter_values);
        setFilterHairTypeValues([...values]);
    };

    const handleHairCareChange = (e) => {
        const { name, checked } = e.target;
        const hairCare = filterHairCareValues;
        checked
            ? hairCare.push(" " + name)
            : hairCare.splice(hairCare.indexOf(name), 1);
        const values = new Set(hairCare);
        setFilterHairCareValues([...values]);
    };

    const {
        register,
        handleSubmit,
        reset,
        formState: { isSubmitting },
    } = useForm({
        defaultValues: {
            first_name: "",
            surname: "",
            email: email,
            location: "",
            username: "",
            password: "",
            confirmPassword: "",
            inviteCode: "",
        },
    });

    const validateFormData = (data) => {
        let errors = {};
        if (!data.email) {
            errors.email = "Email is required";
        }
        if (data.email !== "" && !emailRegex.test(data.email)) {
            errors.email = "Enter a valid email";
        }
        if (!data.first_name || data.first_name === "") {
            errors.first_name = "First name is required";
        }
        if (data.first_name.length < 3) {
            errors.first_name = "First name should be more 3 than characters";
        }
        if (!data.surname || data.surname === "") {
            errors.surname = "Surname is required";
        }
        if (data.surname.length < 3) {
            errors.surname = "Surname should be more than 3 characters";
        }
        if (!data.username || data.username === "") {
            errors.username = "Username is required";
        }
        if (data.username.length < 3) {
            errors.username = "username should be more than 3 characters";
        }
        if (!data.location) {
            errors.location = "Country is required";
        }
        if (!data.password) {
            errors.password = "Password is required";
        }
        if (data.password !== "" && data.password.length < 8) {
            errors.password1 = "Password must be a minimum of 8 characters";
        }
        if (data.password !== "" && !regex.test(data.password)) {
            errors.password1 = "Password must contain alphabets, numbers, and symbols";
        }
        if (data.password !== confirmPassword) {
            console.log('password: ', data.password)
            console.log('confirmPassword: ', data.confirmPassword)
            errors.confirmPassword = "Passwords do not match";
        }
        return errors;
    };

    const hair_type = filterHairTypeValues
        .map((values) => values.toLowerCase())
        .join(",")
        .replace(/\s/g, "");

    const hair_routine = filterHairCareValues
        .map((values) => values.toLowerCase())
        .join(",")
        .replace(/\s/g, "");

    const onSubmit = async (data) => {
        try {
            const errors = validateFormData(data);
            setErrors(errors);
            if (Object.keys(errors).length === 0) {
                const formData = new FormData();
                formData.set("first_name", data.first_name);
                formData.set("surname", data.surname);
                formData.set("email", data.email);
                formData.set("location", data.location);
                formData.set("username", data.username);
                formData.set("password", data.password);
                formData.set("invite_code", params.invite_code);
                formData.set("hair_type", hair_type);
                formData.set("hair_routine", hair_routine);

                const response = axiosApiCall("user/register", "post", formData);

                const resolved = await toast.promise(response, {
                    success: "Registration successful",
                    error: {
                        render(data) {
                            return data?.data?.response?.data?.msg ===
                                "username or email exist, Please sign in instead"
                                ? "Username or email exist, please sign in instead"
                                : data?.data?.response?.data?.msg;
                        },
                    },
                    autoClose: 2000,
                    transition: Slide,
                });

                const res = await resolved;

                res && res.status === 200 && navigate("/sign-in");

                res && res.status === 200 && setStatusCode(res.status);
            }
        } catch (error) {
            console.log(error);
        } finally {
            if (statusCode) {
                reset({
                    first_name: "",
                    surname: "",
                    email: "",
                    location: "",
                    username: "",
                    password: "",
                    confirmPassword: "",
                });
                setIsPasswordVisible(false);
            }
        }
    };

    console.log('errors: ', errors)

    return (
        <div className="mt-4 md:mt-0 form-wrap justify-center w-full px-[1rem] py-12">

            {modalSteps === 1 && (
                <div className="w-[90%] md:w-[34rem] h-[32rem] md:h-[30rem] p-[1.5rem] bg-white absolute m-auto left-0 right-0 top-0 bottom-0 rounded-xl">
                    <h1 className="text-2xl md:text-4xl font-bold mb-2 font-serif">
                        Time to kick things off!
                    </h1>
                    <p className="text-sm mb-8">
                        First things first, let's dive into a few questions about your hair
                        type. We're excited to tailor your experience just for you!
                    </p>
                    <div className="hairType flex flex-col mb-6">
                        <label htmlFor="" className="mb-1 text-[14px]">
                            Which description fits your hair type best?
                        </label>
                        <input
                            type="text"
                            className="border-[1px] outline-0 border-[#EAECF0] h-[2.7rem] rounded-lg p-2 mb-3 text-sm text-[#007EA7]"
                            placeholder="Choose all that apply"
                            name="hairType"
                            value={filterHairTypeValues}
                            readOnly
                        />
                        <div className="options flex">
                            {["Curly", "Kinky", "Coily", "Straight"].map((value, index) => (
                                <div key={index}>
                                    <input
                                        type="checkbox"
                                        className={`hidden peer ${value}QATEST`}
                                        id={index}
                                        name={value}
                                        value={value}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label
                                        htmlFor={index}
                                        className={`peer-checked:text-white peer-checked:bg-[#007EA7] border-[1px] text-sm  py-1 px-2 rounded-[5px] mr-3 border-[#007EA7] text-[#007EA7]`}
                                    >
                                        {value}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="hairCare flex flex-col mb-10">
                        <label htmlFor="" className="mb-1 text-[14px]">
                            Tell us about your hair care routine.
                        </label>
                        <input
                            type="text"
                            className="border-[1px] text-[#007EA7] outline-0 border-[#EAECF0] h-[2.7rem] rounded-lg p-2 mb-3 text-sm"
                            placeholder="Choose the most suitable option"
                            name="hairCare"
                            value={filterHairCareValues}
                            readOnly
                        />
                        <div className="options flex">
                            {["Monthly", "Weekly", "Bi-Weekly", "Quarterly"].map(
                                (label, index) => (
                                    <div key={index}>
                                        <input
                                            type="checkbox"
                                            id={`checkbox-${index}`}
                                            className="hidden peer"
                                            name={label}
                                            value={label}
                                            onChange={handleHairCareChange}
                                        />
                                        <label
                                            htmlFor={`checkbox-${index}`}
                                            className=" peer-checked:text-white peer-checked:bg-[#007EA7] border-[1px] text-sm text-[#007EA7] py-1 px-2 mr-2 border-[#007EA7] rounded-[5px]"
                                        >
                                            {label}
                                        </label>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                    <button
                        type="button"
                        onClick={() => setModalSteps((prev) => prev + 1)}
                        className="bg-[#007EA7] rounded-lg p-2 text-white w-full"
                    >
                        Continue
                    </button>
                </div>
            )}


            {modalSteps === 2 && (
                <form
                    className="max-w-[40rem] m-auto h-fit p-[1.5rem] bg-white rounded-xl py-4"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <h1 className="text-2xl md:text-4xl font-bold mb-2 font-serif">
                        Start your journey!
                    </h1>
                    <p className="text-sm mb-8">Let us know your details</p>
                    <div className="wrap flex flex-col mb-4">
                        <label htmlFor="email" className="mb-1 text-[14px]">
                            Email address
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            {...register("email")}
                            className={`border-[1px] outline-0 rounded-sm p-2 text-sm ${errors.email ? "border-red-500" : ""} `}
                            placeholder="Email"
                        />
                        {errors.email && (
                            <span className="text-red-500 pt-1 text-xs">{errors.email}</span>
                        )}
                    </div>
                    <div className="wrap flex flex-col md:flex-row w-full md:justify-between mb-4">
                        <div className="sub-wrap flex flex-col mb-4 md:mb-0 md:w-1/2 md:mr-2">
                            <label htmlFor="first_name" className="mb-1 text-[14px]">
                                First Name
                            </label>
                            <input
                                type="text"
                                id="first_name"
                                name="first_name"
                                {...register("first_name")}
                                className={`border-[1px] outline-0 rounded-sm p-2 text-sm ${errors.first_name ? "border-red-500" : ""} `}
                                placeholder="Name"
                            />
                            {errors.first_name && (
                                <span className="text-red-500 pt-1 text-xs">
                                    {errors.first_name}
                                </span>
                            )}
                        </div>
                        <div className="sub-wrap flex flex-col md:w-1/2 md:ml-2">
                            <label htmlFor="surname" className="mb-1 text-[14px]">
                                Surname
                            </label>
                            <input
                                type="text"
                                id="surname"
                                name="surname"
                                {...register("surname")}
                                className={`border-[1px] outline-0 rounded-sm p-2 text-sm ${errors.surname ? "border-red-500" : ""} `}
                                placeholder="Surname"
                            />
                            {errors.surname && (
                                <span className="text-red-500 pt-1 text-xs">
                                    {errors.surname}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="wrap flex flex-col mb-4">
                        <label htmlFor="" className="mb-1 text-[14px]">
                            Username
                        </label>
                        <input
                            type="text"
                            name="username"
                            id="username"
                            {...register("username")}
                            className={`border-[1px] outline-0 rounded-sm p-2 text-sm ${errors.username ? "border-red-500" : ""}`}
                            placeholder="Pick Username"
                        />
                        {errors.username && (
                            <span className="text-red-500 pt-1 text-xs">
                                {errors.username}
                            </span>
                        )}
                    </div>
                    <div className="wrap flex flex-col mb-4">
                        <label htmlFor="selectedCountry" className="mb-1 text-[14px]">
                            Location (Country)
                        </label>
                        <select
                            id="location"
                            name="location"
                            {...register("location")}
                            className={`border-[1px] outline-0 rounded-sm p-2 text-sm text-[#667085] ${errors.location ? "border-red-500" : ""} `}
                        >
                            <option value="" className="">
                                -Select-
                            </option>
                            {countryOptions &&
                                countryOptions.map((option, k) => (
                                    <option value={option.label} key={k}>
                                        {option.label}
                                    </option>
                                ))}
                        </select>
                        {errors.selectedCountry && (
                            <span className="text-red-500 pt-1 text-xs">
                                {errors.selectedCountry}
                            </span>
                        )}
                    </div>
                    <div className="wrap flex flex-col mb-4 relative">
                        <label htmlFor="password" className="mb-1 text-[14px]">
                            Password
                        </label>
                        <input
                            type={isPasswordVisible ? "text" : "password"}
                            name="password"
                            id="password"
                            {...register("password")}
                            className={`border-[1px] outline-0 rounded-sm p-2 text-sm ${errors.password ? "border-red-500" : `${errors.password1 ? "border-red-500" : `${errors.password2 ? "border-red-500" : ""}`}`}`}
                            placeholder="Password"
                        />
                        {Object.keys(errors).length === 0 ? <>
                            <span className="text-black-500 pt-1 text-xs">Password must be a minimum of 8 characters</span>
                            <span className="text-black-500 pt-1 text-xs"> Password must contain alphabets, numbers, and symbols</span>
                        </> : <>
                            {errors.password && (
                                <span className="text-red-500 pt-1 text-xs">
                                    {errors.password}
                                </span>
                            )}
                            {errors.password1 && (
                                <span className="text-red-500 pt-1 text-xs">
                                    {errors.password1}
                                </span>
                            )}
                            {errors.password2 && (
                                <span className="text-red-500 pt-1 text-xs">
                                    {errors.password2}
                                </span>
                            )}
                        </>}
                        <span
                            className={`w-fit flex items-center px-4 text-gray-600 absolute top-8 right-2`}
                            onClick={() => setIsPasswordVisible((prevState) => !prevState)}
                        >
                            {isPasswordVisible ? (
                                <EyeIcon />
                            ) : (
                                <EyeIconClose />
                            )}
                        </span>

                    </div>
                    <div className="wrap flex flex-col mb-4 relative">
                        <label htmlFor="confirmPassword" className="mb-1 text-[14px]">
                            Confirm Password
                        </label>
                        <input
                            type={isConfirmPPasswordVisible ? "text" : "password"}
                            name="confirmPassword"
                            id="confirmPassword"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className={`border-[1px] outline-0 rounded-sm p-2 text-sm ${errors.confirmPassword ? "border-red-500" : ""}`}
                            placeholder="Confirm Password"
                        />
                        <span
                            className={`w-fit flex items-center px-4 text-gray-600 absolute top-8 right-2`}
                            onClick={() => setIsConfirmPPasswordVisible((prevState) => !prevState)}
                        >
                            {isConfirmPPasswordVisible ? (
                                <EyeIcon />
                            ) : (
                                <EyeIconClose />
                            )}
                        </span>
                        {errors.confirmPassword && (
                            <span className="text-red-500 pt-1 text-xs">{errors.confirmPassword}</span>
                        )}
                    </div>
                    <div className="wrap flex flex-col mb-5 hidden">
                        <label
                            htmlFor="inviteCode"
                            className="mb-1 text-[14px] text-[#d9d7d7]"
                        >
                            Invite code
                        </label>
                        <input
                            type="text"
                            className="border-[1px] outline-0 rounded-sm p-2 text-sm text-[#d9d7d7] cursor-not-allowed"
                            name="inviteCode"
                            defaultValue={"30-dnie39"}
                            readOnly
                        />
                    </div>

                    <button
                        type="submit"
                        disabled={isSubmitting}
                        className="w-full flex items-center justify-center bg-[#007EA7] rounded-lg p-2 text-white mb-3"
                    >
                        {isSubmitting ? (
                            <BiLoaderCircle className="w-5 h-5 text-white animate-spin" />
                        ) : (
                            "Create Account"
                        )}
                    </button>
                </form>
            )}

        </div>
    );
};

export default SignUpForm;