/**
 * Proposed contexts for the platform, basically the nav sections
 * Advised to be modified or reimplemented
 *
 * This is to get a general state for the post content buttons
 * and other nav components
 */

import React, { createContext, useContext, useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";

const StateContext = createContext();

//Navbar - Boolean inclined operations/states
const initialState = {
  postContentBtn: false,

  //postContentBtn handles these modals
  openUploadModal: false,
  openDiscussionUploadModal: false,
  openProductUploadModal: false,

  //admin operations modals
  //discussion related posts
  deleteDiscussionPost: false,
  deleteComment: false,
  deleteReply: false,

  //image related post
  deleteImagePost: false,
  deleteImagePostComment: false,

  //product related post
  deleteProductPost: false,
  deleteReview: false,
  editProduct: false,

  // global auth checks
  isAuthModal: false,
};

const productInfoState = {
  productDescription: null,
  productImgSrc: "",
  productPoster: "Admin Texture Care",
};

const commentInfoState = {
  commentDescription: "",
  username: "",
  userProfilePic: "TC",
};

const editProductInfoState = {
  id: null,
  title: null,
  content: "",
  product_type: [],
  hair_type: [],
  created_time: "",
  username: "",
  url: "",
};

export const ContextProvider = ({ children }) => {
  const [isClicked, setIsClicked] = useState(initialState);

  const [productInfo, setProductInfo] = useState(productInfoState);
  const [commentInfo, setCommentInfo] = useState(commentInfoState);
  const [editProductInfo, setEditProductInfo] = useState(editProductInfoState);

  const [productID, setProductID] = useState(null);
  const [deleteProductID, setDeleteProductID] = useState(null);
  const [deleteCommentID, setDeleteCommentID] = useState(null);

  // set this states and persists it when the users logs in
  // from the SignInForm component
  const [role, setRole] = useState(null);
  const [userId, setUserId] = useState(null);

  //This handle clicks of components on the nav bar and modals
  const handleClick = (clicked) =>
    setIsClicked({ ...initialState, [clicked]: true });

  useEffect(() => {
    const user = window.localStorage.getItem("validationToken");
    const decoded = user && jwtDecode(user);

    decoded ? setRole(decoded.role) : setRole(null);
    decoded ? setUserId(decoded.user_id) : setUserId(null);
  }, []);

  // console.log(role);
  // console.log(userId);

  return (
    <StateContext.Provider
      value={{
        handleClick,
        setIsClicked,
        isClicked,
        role,
        userId,
        setRole,
        setUserId,
        productID,
        setProductID,
        setProductInfo,
        productInfo,
        editProductInfo,
        setEditProductInfo,
        setDeleteProductID,
        deleteProductID,
        setCommentInfo,
        commentInfo,
        deleteCommentID,
        setDeleteCommentID,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
