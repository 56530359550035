import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useValidateRequest } from "../../contexts/ValidateRequest";

import { useDropzone } from "react-dropzone";

import Avatar from "../../assets/icons/avatar.svg";
import { Slide, toast } from "react-toastify";
import { BiLoaderCircle } from "react-icons/bi";
import { GoPencil } from "react-icons/go";

const AdminProfileForm = ({ profileInfo }) => {
  const [imageDataUrl, setImageDataUrl] = useState("");
  // const [showPassword, setShowPassword] = useState(false)

  const { axiosApiCall } = useValidateRequest();

  //Drag and drop doesn't work well on ubuntu 22.04 [wayland]
  //Fix for now is changing wayland to xorg
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFile) => {
      setImageDataUrl(
        Object.assign(acceptedFile[0], {
          preview: URL.createObjectURL(acceptedFile[0]),
        })
      );
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    // defaultValues:{
    //   first_name: profileInfo ? profileInfo?.first_name : "",
    //   last_name: profileInfo ? profileInfo.last_name : "",
    // }
  });

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();

      formData.set(
        "first_name",
        data ? data.first_name : profileInfo.first_name
      );
      formData.set("surname", data ? data.surname : profileInfo.last_name);
      formData.set("username", profileInfo.username);

      if (imageDataUrl) {
        formData.set("img", imageDataUrl || "");
      }

      const response = axiosApiCall(
        "user/update",
        "post",
        formData,
        "multipart/form-data"
      );

      await toast.promise(response, {
        success: "Profile updated successfully!",
        error: {
          render() {
            return "Something went wrong";
          },
        },
        autoClose: 2000,
        transition: Slide,
      });
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  return (
    <div className="flex flex-col lg:gap-y-2 px-4 lg:px-0 lg:ml-60">
      <h2 className="block text-sm font-bold text-black sm:text-xl sm:mx-0">
        Edit Profile
      </h2>
      <p className="my-2 text-sm">View and edit profile information</p>

      <div className="">
        <label
          htmlFor="avatar"
          className="mb-3 block text-black sm:mx-0 text-sm"
        >
          Profile Photo
        </label>

        <div
          {...getRootProps({
            className: "relative inline-block sm:mx-0",
          })}
        >
          <img
            className="w-24 h-24 rounded-full border border-gray-300 mx-auto sm:mx-0"
            src={
              imageDataUrl
                ? imageDataUrl.preview
                : (profileInfo && profileInfo.avt) || Avatar
            }
            onLoad={() => {
              imageDataUrl && URL.revokeObjectURL(imageDataUrl.preview);
            }}
            alt="preview"
          />

          <input
            {...getInputProps()}
            className="w-8 h-8 rounded-full absolute bottom-0.5 right-0.5 bg-custom-image opacity-0 z-[20]"
            style={{ display: "block" }}
          />

          <div className="absolute left-0 top-0 h-full w-full rounded-full z-10">
            <div className="relative h-full w-full">
              <div className="absolute bg-[var(--Cobalt-Blue)] w-8 h-8 rounded-full bottom-0.5 right-0.5 flex items-center justify-center">
                <GoPencil className="text-white" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Form */}
      {profileInfo && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col space-y-4 lg:w-[650px] w-full my-4"
        >
          <div className="flex flex-col lg:flex-row lg:items-center gap-x-4">
            <div className="w-full flex flex-col gap-y-2">
              <label
                htmlFor="firstName"
                className="block text-base font-semibold text-black"
              >
                First Name
              </label>
              <input
                type="text"
                name="firstName"
                id="firstName"
                {...register("first_name", {
                  required: "First name is required",
                })}
                defaultValue={profileInfo && profileInfo.first_name}
                placeholder="John"
                className="w-full rounded-md font-medium border border-[#e0e0e0] bg-white py-2 px-3 text-sm text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              />
              {errors.first_name && (
                <p className="text-sm text-red-600">
                  {errors.first_name.message}
                </p>
              )}
            </div>
            <div className="w-full flex flex-col gap-y-2">
              <label
                htmlFor="surname"
                className="block text-base font-semibold text-black"
              >
                Surname
              </label>
              <input
                type="text"
                name="surname"
                id="surname"
                {...register("surname", { required: "Last name is required" })}
                defaultValue={profileInfo && profileInfo.last_name}
                placeholder="Doe"
                className="w-full rounded-md font-medium border border-[#e0e0e0] bg-white py-2 px-3 text-sm
                   text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              />
              {errors.last_name && (
                <p className="text-sm text-red-600">
                  {errors.last_name.message}
                </p>
              )}
            </div>
          </div>
          <div className="w-full flex flex-col gap-y-2">
            <label
              htmlFor="username"
              className="block text-base font-semibold text-black"
            >
              Username
            </label>
            <input
              type="username"
              name="username"
              id="username"
              {...register("username")}
              placeholder="Enter your username"
              disabled
              defaultValue={profileInfo && profileInfo.username}
              disabled
              className="w-full rounded-md font-medium border border-[#e0e0e0] bg-white py-2 px-3 text-sm text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md cursor-not-allowed"
            />
            {errors.username && (
              <p className="text-sm text-red-600">{errors.username.message}</p>
            )}
          </div>
          {/* <div className="w-full flex flex-col gap-y-2">
          <label
            htmlFor="email"
            className="block text-base font-semibold text-black"
          >
            Email Address
          </label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Enter your email"
            disabled
            defaultValue={
              (profileInfo && profileInfo.email) || "texturecare@admin.com"
            }
            className="w-full rounded-md font-medium border border-[#e0e0e0] bg-white py-2 px-3 text-sm text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md cursor-not-allowed"
          />
        </div> */}

          {/* <div className="relative w-full flex flex-col gap-y-2">
          <label
            htmlFor="password"
            className="block text-base font-semibold text-black"
          >
            Password
          </label>
          <input
            type={!showPassword ? "password" : "text"}
            name="password"
            id="password"
            placeholder="Enter password"
            defaultValue={"***********"}
            disabled
            className="w-full rounded-md font-medium border border-[#e0e0e0] bg-white py-2 px-3 text-sm text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md cursor-not-allowed"
          />
          <button
            type="button"
            onClick={() => setShowPassword((prev) => !prev)}
            className="absolute right-2 top-[38%] w-fit outline-none border-none px-3 py-1 rounded hover:bg-gray-300/40 text-[var(--Cobalt-Blue)] text-xs font-extrabold"
          >
            {!showPassword ? "Show" : "Hide"}
          </button>
          <p className="text-sm mt-1 font-semibold text-[var(--Cobalt-Blue)]">
            Change password
          </p>
        </div> */}

          <div className="w-full flex items-center justify-end gap-x-4 my-6">
            <button
              onClick={() =>
                reset({
                  first_name: "",
                  last_name: "",
                  username: "",
                })
              }
              className="px-4 py-2 text-sm text-[var(--Cobalt-Blue)] border border-[var(--Cobalt-Blue)] rounded-md hover:bg-gray-100"
            >
              Reset
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm text-white border border-[var(--Cobalt-Blue)] bg-[var(--Cobalt-Blue)] hover:bg-[#006080] rounded-md"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <BiLoaderCircle className="w-5 h-5 text-white animate-spin" />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default AdminProfileForm;
