import React from 'react';
import { Link } from "react-router-dom";
import CartIcon from '../icons/CartIcon';
import { extractText } from '../../utils/utils';
import RelatedImage from "../../assets/images/related_product.png";

const RelatedProductCard = ({ post }) => {
    return (
        <div className="relative flex w-full flex-col rounded-lg border border-gray-100 bg-[#EAECF0] gap-y-4 mb-8 last:mb-0">
            <Link
                to={`/product/${post.id}`}
                state={post}
                className="relative mx-3 mt-3 flex flex-col justify-center items-center"
            >
                <div className="w-full max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg h-[300px] md:h-[350px] lg:h-[300px] xl:h-[300px] flex justify-center items-center">
                    <img
                        className="w-full h-full rounded-xl object-cover"
                        src={post ? post.url : RelatedImage}
                        alt={extractText(post.content)}
                    />
                </div>
                <span className="absolute bottom-24 right-4 rounded-full bg-[#EAECF0] p-3">
                    <CartIcon />
                </span>
                <div className="mt-4 px-5 pb-5 text-center">
                    <h6 className="text-base md:text-lg lg:text-xl xl:text-2xl tracking-tight text-slate-900">
                        {post ? post.title : 'An affordable oil product'}
                    </h6>
                </div>
            </Link>
        </div>
    )
}

export default RelatedProductCard