import { useState } from "react";
import { useStateContext } from "../../contexts/ContextProviders";

import { MdOutlineEdit } from "react-icons/md";
import ProductImage from "../../assets/images/product_image.jpeg";

import { ReactComponent as CommentIcon } from "../../assets/icons/comment_icon.svg";
import { ReactComponent as ShareIcon } from "../../assets/icons/share_icon.svg";
import { ReactComponent as ArrowUp } from "../../assets/icons/arrowup_icon.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrowdown_icon.svg";

const AdminProductCard = ({ handleEditProduct, product }) => {
  const [iscopied, setIscopied] = useState(false);

  const { setProductID } = useStateContext();

  const copyToClipboard = async (id) => {
    let baseUrl = `${window.location.origin}`;

    try {
      baseUrl += `/product/${id}`;
      await navigator.clipboard.writeText(baseUrl);
      setIscopied(true);
      setTimeout(() => {
        setIscopied(false);
      }, 1000);
      // console.log('Text copied to clipboard', baseUrl);
    } catch (error) {
      console.error("Error copying text: ", error);
    }
  };

  return (
    <div className="relative flex flex-col lg:w-[300px] h-[200px] w-full">
      <div className="relative flex rounded-xl w-full">
        <img
          src={product.url === null ? ProductImage : product.url}
          alt={product.title}
          className="w-full h-fit rounded-md object-cover"
        />
        <span className="absolute top-0 right-0 m-2 rounded-full px-2 text-center font-medium text-black bg-[#A1F783]">
          Products
        </span>
        <div className="absolute bottom-0 left-0 right-0 p-2 bg-black bg-opacity-50 rounded-bl-xl rounded-br-xl">
          <h5 className="text-1xl text-white">
            {product.username || "Texture Care"}
          </h5>
        </div>
      </div>
      <div className="pb-5">
        <div className="mt-2 flex items-center justify-between">
          <div className="vote-wrap flex items-center gap-x-4">
            <div className="flex items-center space-x-1 text-green-500">
              <ArrowUp className="w-4" />
              <span className="ml-1">{product?.metrics?.upvote_count}</span>
            </div>
            <div className="flex items-center space-x-1 text-red-500">
              <ArrowDown className="w-4" />
              <span className="ml-1">{product?.metrics?.downvote_count}</span>
            </div>

            <div className="share-wrap flex gap-x-4">
              <div className="comment flex text-[#419fbd]">
                <CommentIcon className="w-4" />
                <span className="ml-1">{product?.metrics?.comment_count}</span>
              </div>
              <div
                className="flex items-center space-x-1 text-red-500"
                onClick={() => copyToClipboard(product.id)}
              >
                <ShareIcon className="w-4" />
              </div>
              {iscopied && (
                <span className="text-[var(--Cobalt-Blue)] text-xs absolute bg-[#e6e2dffd] z-10 p-2 rounded-lg bottom-8 lg:bottom-0 right-0 lg:right-[-4.5rem]">
                  Copied!
                </span>
              )}
            </div>
          </div>
          <div className="ml-2">
            <button
              onClick={() => {
                setProductID(product.id);
                handleEditProduct();
              }}
              className="w-8 h-8 flex items-center justify-center rounded-full bg-gray-400/40"
            >
              <MdOutlineEdit className="w-5 h-5 text-gray-500 hover:text-gray-600" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminProductCard;
