import React from "react";
import PostCard from "../../pages/userfeed/PostCard";
import { BiLoaderCircle } from "react-icons/bi";

const AllTab = ({ data }) => {
  const allData = [...data.post, ...data.discussion];
  return (
    <div>
      {allData ? (
        <div className="container m-auto">
          <div className="md:px-24 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {allData &&
              allData?.map((post) => <PostCard key={post.id} post={post} />)}
          </div>
        </div>
      ) : (
        <div className="w-full h-[40vh] flex justify-center items-center">
          <BiLoaderCircle className="w-20 h-20 lg:w-28 lg:h-28 text-[var(--Cobalt-Blue)] animate-spin" />
        </div>
      )}
    </div>
  );
};

export default AllTab;
