import React from 'react';
import CartIcon from '../icons/CartIcon';
import RelatedImage from "../../assets/images/related_product.png";

const RelatedProductCard = ({ name }) => {
    return (
        <div className="relative flex w-full max-w-xs flex-col rounded-lg border border-gray-100 bg-[#EAECF0] m-2 mx-auto">
            <a
                className="relative mx-3 mt-3 flex rounded-xl"
                href="/#"
            >
                <img
                    className="object-cover"
                    src={RelatedImage}
                    alt="..."
                />
                <span className="absolute bottom-0 right-0 m-4 rounded-full bg-[#EAECF0] p-3">
                    <CartIcon />
                </span>
            </a>
            <div className="mt-4 px-5 pb-5">
                <a href="/#">
                    <h6 className="text-1xl tracking-tight text-slate-900">
                        {name}
                    </h6>
                </a>

            </div>
        </div>

    );
}
export default RelatedProductCard;