/**
 * Comment input component
 *
 * For adding a first layer comment to a discussion post -> /discussion/id_8w7q7w6qw?
 *
 */

import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { PiSmileyThin } from "react-icons/pi";
import { VscSend } from "react-icons/vsc";
import { useValidateRequest } from "../../contexts/ValidateRequest";
import { BiLoader } from "react-icons/bi";

const CommentInput = ({ getComment, isProductPage }) => {
  const [comment, setComment] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { axiosApiCall } = useValidateRequest();

  const { postId, productId } = useParams();

  // const [chosenEmoji, setChosenEmoji] = useState(null);
  // const [emojiOnOpen, setEmojiOnOpen] = useState(false);

  //TODO: Implement emoji additions
  // const onEmojiClick = (e) => {
  //   // console.log(e);
  //   // e.preventDefault();
  //   setChosenEmoji(e.emoji);
  // };

  const handleCommentSubmission = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const data = new FormData();
      data.set("target_id", postId || productId);
      data.set("content", comment);

      await axiosApiCall("comment", "post", data, "multipart/form-data");

      getComment();
      setComment("");
      setIsSubmitting(false);
      // console.log(comment)
      // console.log(postCommentResponse)
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleCommentSubmission} className="relative w-full mb-8">
      <input
        type="text"
        name="comment"
        id="comment"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        className="w-full outline-none rounded-2xl border border-gray-300 hover:border-[#007EA7]/40 focus:border-[#007EA7] active:border-[#007EA7] pr-6 pl-8 py-[10px] text-sm placeholder-gray-400 focus:placeholder:opacity-75 text-gray-600 "
        placeholder={isProductPage ? "Write a review" : "Write a comment"}
      />
      <button
        type="button"
        onClick={() => console.log("Emoji opened")}
        className="absolute top-[50%] -translate-y-[50%] left-2 opacity-85 hover:opacity-100"
      >
        <PiSmileyThin className="w-5 h-5 text-gray-500 hover:text-[#007EA7]" />
      </button>

      {/* <div className="absolute top-4  left-8">
        <EmojiPicker
          onEmojiClick={onEmojiClick}
          open={emojiOnOpen}
          className="z-10"
        />
      </div> */}

      <button
        type="submit"
        className="absolute top-[50%] -translate-y-[50%] right-2 opacity-85 hover:opacity-100"
      >
        {isSubmitting ? (
          <BiLoader className="w-5 h-5 text-[#007EA7] animate-spin" />
        ) : (
          <VscSend className="w-5 h-5 text-gray-500 hover:text-[#007EA7]" />
        )}
      </button>
    </form>
  );
};

export default CommentInput;
