import React, {useState} from 'react'

import { useValidateRequest } from "../../contexts/ValidateRequest";

import { toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BiLoaderCircle } from "react-icons/bi";
import InnerCommentInput from '../comments/InnerCommentInput';

const EditReply = ({ commentId, comment, getComment, setEditClicked, username, commentType, parent_id, editClicked }) => {
  const [edit, setEdit] = useState(false);

  const { axiosApiCall } = useValidateRequest();

  const editReply = async () => {
    setEdit(true);
    try {
      // const params = { comment_id: commentId };
      const actionResponse = axiosApiCall(
        `comment?comment_id=${commentId}`,
        "put"
      );
      // console.log(actionResponse);

      // Toast Notification
      const notification = await toast.promise(actionResponse, {
        pending: "Loading", // Display while posting
        success: "Comment deleted successfully",
        error: {
          render({ data }) {
            // When the api returns error
            return data?.response?.data?.msg || "Something went wrong";
          },
        },

        autoClose: 2000,
        transition: Slide,
      });
      getComment();
      setTimeout(() => {
        setEditClicked(false);
      }, 3000);
      notification();
    } catch (error) {
      setEdit(false);
    } finally {
      setEdit(false);
    }
  };

  return (
    <div className="modal-warp fixed inset-0 z-50 bg-black/40 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0">
      <div className="flex flex-col gap-y-4 fixed left-[50%] top-[15%] md:top-[50%] z-50 w-[95%] lg:w-[430px] rounded-[16px] p-[20px] translate-x-[-50%] lg:translate-y-[-50%] gap-4 bg-white duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]">
        <p className="font-[400] text-[20px] leading-8 text-[#000000]">
          Edit Reply <br />
          <span className="text-sm">
            Are you sure you want to edit this reply?
          </span>
        </p>

        <div className="flex flex-col gap-y-4">
          <div className="flex items-start">
            <p className="h-8 w-8 flex items-center justify-center rounded-full bg-gray-400 p-2 text-gray-700 font-bold text-sm mr-2">
              {username?.slice(0,2).toUpperCase()}
            </p>
            <div className="flex flex-col w-full gap-y-2 p-2 px-4 border-2 border-[#007EA7]/10 rounded-lg hover:shadow-sm hover:bg-gray-200/10">
              <h4 className="font-semibold text-sm">{username}</h4>
              <InnerCommentInput 
                getComment={getComment}
                commentId={commentId}
                userComment={comment}
                commentType={commentType}
                username={username}
                setEditClicked={setEditClicked}
                editClicked={editClicked}
                parent_id={parent_id}
              />
              {/* <p className="font-normal text-sm">{comment || ""}</p> */}
            </div>
          </div>
        </div>

        {/* <button
          type="button"
          onClick={editReply}
          className="flex items-center justify-center w-full py-3 text-sm text-[#FCFCFD] bg-[var(--Cobalt-Blue)] hover:bg-[#006080] rounded "
        >
          {edit ? (
            <BiLoaderCircle className="w-5 h-5 text-white animate-spin" />
          ) : (
            "Edit Reply"
          )}
        </button> */}

        <button
          type="button"
          onClick={() => setEditClicked(false)}
          className="py-2 w-full text-center text-white rounded font-[400] bg-[var(--Cobalt-Blue)] hover:bg-[#006080]"
        >
          Cancel
        </button>
      </div>
    </div>
  )
}

export default EditReply