import React from 'react';
import PostCard from "../../pages/userfeed/PostCard";

const DiscussionTab = ({ discussions }) => {
  return (
    <div className="container mx-auto">
      <div className="grid lg:grid-cols-4 gap-4 mt-8 lg:m-0 lg:px-10 ">
        {discussions && (discussions.map((discussion) => (
          <PostCard key={discussion.id} post={discussion} />
        )))}
      </div>
    </div>
  )
}

export default DiscussionTab;