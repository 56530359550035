import React from "react";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../contexts/ContextProviders";
import { useValidateRequest } from "../../contexts/ValidateRequest";
import { ToastContainer, toast, Slide } from "react-toastify";

const DeleteDiscussionPost = ({ postid, date, postTitle }) => {
  const { setIsClicked } = useStateContext();
  const { axiosApiCall } = useValidateRequest();
  const navigate = useNavigate();

  const deletePost = async () => {
    try {
      // const params = { post_id: postid };
      const response = axiosApiCall(`post?post_id=${postid}`, "delete");
      console.log(response);

      // Toast Notification
      const notification = await toast.promise(response, {
        pending: "Loading", // Display while posting
        success: "Post deleted successfully",
        error: {
          render({ data }) {
            // When the api returns error
            return data?.response?.data?.msg || "Something went wrong";
          },
        },

        autoClose: 3000,
        transition: Slide,
      });
      setTimeout(() => {
        navigate(-1);
        setIsClicked({
          postContentBtn: false,
          openUploadModal: false,
          openDiscussionUploadModal: false,
        });
      }, 5000);

      notification();
    } catch (error) {}
  };

  return (
    <div className="modal-warp fixed inset-0 z-50 bg-black/40 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0">
      <div className="flex flex-col gap-y-4 fixed left-[50%] top-[15%] md:top-[50%] z-50 w-[95%] lg:w-[430px] rounded-[16px] p-[20px] translate-x-[-50%] lg:translate-y-[-50%] gap-4 bg-white duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]">
        <p className="font-[400] text-[20px] leading-8 text-[#000000]">
          Delete Discussion Post <br />
          <span className="text-sm">
            Are you sure you want to delete this post?
          </span>
        </p>

        <div className="w-full flex flex-col py-2 px-4 gap-y-2 bg-[#007ea7] rounded-md mb-8">
          <div className="flex justify-between">
            <div className="flex items-center gap-x-2">
              <p className="h-5 w-5 rounded-full flex items-center justify-center bg-gray-200 text-gray-800 font-bold text-[10px]">
                KK
              </p>
              <div className="h-1 w-1 rounded-full bg-white" />
              <p className="text-[11px] text-white font-light">{date || ""}</p>
            </div>

            <p className="p-1 px-2 text-xs bg-yellow-200 text-gray-600 rounded-full">
              Hair Type
            </p>
          </div>
          <p className="font-semibold text-white text-center">
            {postTitle || ""}
          </p>
        </div>

        <button
          type="button"
          onClick={deletePost}
          className="py-2 w-full text-center text-[#FCFCFD] bg-[var(--Cobalt-Blue)] hover:bg-[#006080] rounded font-[400]"
        >
          Delete Post
        </button>

        <button
          type="button"
          onClick={() => setIsClicked({ deleteDiscussionPost: false })}
          className="py-2 w-full text-center text-[#007EA7] rounded font-[400] bg-gray-600/10 hover:bg-gray-500/10"
        >
          Cancel
        </button>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>
    </div>
  );
};

export default DeleteDiscussionPost;
