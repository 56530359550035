import React from "react";
// import { useStateContext } from "../contexts/ContextProviders";
import { useStateContext } from "../../contexts/ContextProviders";
import ModalContainer from "../../components/modals/ModalContainer";
import DashboardHeader from "../../components/UserfeedHeader";
import { useNavigate } from "react-router-dom";
import Notifications from "./Notification";

import UploadModal from "../../components/modals/UploadModal";
import UploadDiscussionModal from "../../components/modals/UploadDiscussionModal";
import UploadProductModal from "../../components/modals/UploadProductModal";

const NotificationPage = () => {
  const navigate = useNavigate();
  const { isClicked } = useStateContext(); // this sets the isClicked global states used to manipulate the posts modals
  return (
    <div>
      {/* postContentBtn state - This is the entry points to opening the modal container */}
      {isClicked.postContentBtn && <ModalContainer />}
      {/* admins only */}
      {isClicked.openProductUploadModal && <UploadProductModal />}
      {/* all users */}
      {isClicked.openUploadModal && <UploadModal />}
      {isClicked.openDiscussionUploadModal && <UploadDiscussionModal />}

      <DashboardHeader />
      <Notifications limit={false} isRoute={true}></Notifications>
    </div>
  );
};

export default NotificationPage;
