import React, { useState } from "react";
import { useStateContext } from "../../contexts/ContextProviders";

import { PiEyeLight, PiEyeSlash } from "react-icons/pi";

const SignInModal = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { setIsClicked } = useStateContext();

  // console.log(isClicked.openUploadModal);

  const handleSignIn = async (e) => {
    e.preventDefault();

    try {
      // If verified from modal
      // save updated token
      // Close sign in Modal

      // const res = await signInUser({
      //   email: textInputValue,
      //   password: passwordInputValue
      // })

      console.log("submitted");
      return;
    } catch (e) {
      console.log(e);
    } finally {
      setIsClicked((prevIsClicked) => ({
        ...prevIsClicked,
        isAuthModal: false,
      }));
    }
  };

  return (
    <div className="fixed inset-0 z-[100] bg-black/40 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 w-screen h-screen">
      <div className="form-wrap w-full h-auto">
        <form
          action=""
          className={`w-[92%] md:w-[34rem] h-fit p-4 lg:p-[1.5rem] bg-white absolute m-auto left-0 right-0 top-0 bottom-0 rounded-xl`}
          onSubmit={handleSignIn}
        >
          <h1 className="text-3xl font-extrabold mb-2">
            Ooops, something happened!
          </h1>
          <p className="text-sm mb-6 font-semibold">
            Please sign in to continue using Texture Care
          </p>
          <div className="wrap flex flex-col gap-y-4">
            <div className="flex flex-col gap-y-1">
              <label htmlFor="" className="mb-1 text-[14px] font-semibold">
                Email address or username
              </label>
              <input
                type="text"
                className="border outline-0 border-gray-300 rounded py-3 px-4 text-sm active:border-[#007EA7] focus:border-[#007EA7] hover:border-gray-400 placeholder-gray-500"
                placeholder="Email or Username"
                id="email"
                //   value={textValue}
                //   onChange={handleTextChange}
                required
              />
            </div>

            <div className="relative flex flex-col gap-y-1">
              <label htmlFor="" className="mb-1 text-[14px] font-semibold">
                Password
              </label>
              <input
                type={!passwordVisible ? "password" : "text"}
                className="border outline-0 border-gray-300 rounded py-3 px-4 text-sm active:border-[#007EA7] focus:border-[#007EA7] hover:border-gray-400 placeholder-gray-500"
                id="password"
                //   value={passwordValue}
                placeholder="Enter password"
                //   onChange={handlePasswordChange}
                required
              />
              <button
                type="button"
                onClick={() => setPasswordVisible((prev) => !prev)}
                className="absolute inset-y-0 top-[40%] right-4 flex items-center text-gray-600 hover:text-gray-800"
              >
                {passwordVisible ? (
                  <PiEyeSlash className="w-6 h-6" />
                ) : (
                  <PiEyeLight className="w-6 h-6" />
                )}
              </button>
            </div>

            <button
              type="submit"
              className="bg-[var(--Cobalt-Blue)] hover:bg-[#006080] py-2 rounded text-white mt-4"
            >
              Sign In
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignInModal;
