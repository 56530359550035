import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router-dom";
// import CommentInput from "../../components/comments/CommentInput";
import CommentThread from "../../components/comments/CommentThread";
import ProductReviewCard from "../../components/cards/ProductReviewCard";
import RelatedProductCard from "../../components/cards/RelatedProductCard";
import BackButton from "../../components/buttons/BackButton";
import DashboardHeader from "../../components/UserfeedHeader";
import { useValidateRequest } from "../../contexts/ValidateRequest";
import { useStateContext } from "../../contexts/ContextProviders";
import ModalContainer from "../../components/modals/ModalContainer";

const ProductReview = () => {
  const [relatedProducts, setRelatedProducts] = useState(null);
  const [comments, setComments] = useState(null);
  const [product, setProduct] = useState(null);
  const [replies, setReplies] = useState(null);

  const navigate = useNavigate();
  const { productId } = useParams();
  const { isClicked } = useStateContext();
  const { axiosApiCall } = useValidateRequest();

  //  get single post and for refresh after vote is done
  const getSingleProduct = async () => {
    try {
      const params = { include_metrics: true };
      const { data } = await axiosApiCall(`post/${productId}`, "get", params);

      setProduct(data.data);
      // console.log(data)
    } catch (error) {
      console.log(error);
    }
  };

  // get Comments
  const getComment = async () => {
    const params = {
      page: 1,
      limit: 100,
      target_id: productId,
    };

    try {
      const getCommentResponse = await axiosApiCall("comment", "get", params);
      const allComments = getCommentResponse.data.data;
      const firstComments = allComments.filter((comment) => comment.parent_id === null);
      const secondLevelComments = allComments.filter((comment) => comment.parent_id !== null)
      setComments(firstComments);
      setReplies(secondLevelComments);
    } catch (error) {
      console.log(error);
    }
  };

  //get other products
  const getOtherProducts = async () => {
    try {
      const data = await axiosApiCall(
        `post?page=1&limit=5&content_type=product`,
        "get"
      );
      setRelatedProducts(data);
      // console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getComment();
    getOtherProducts();
    getSingleProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  return (
    <div>
      {(isClicked.deleteReview ||
        isClicked.deleteProductPost ||
        isClicked.deleteReply) && <ModalContainer />}
      <DashboardHeader productReviewPage={true} />
      <div className="md:p-8 px-2 mt-4">
        <BackButton onClick={() => navigate(-1)} />
        <div className="flex flex-col md:flex-row md:p-24">
          <div className="w-full md:w-[70%] mb-4 md:mb-0 p-4">
            <ProductReviewCard
              id={product?.id}
              imgSrc={product?.url}
              contentType={product?.type}
              content={product?.content}
              upvotes={product?.metrics?.upvote_count}
              downvotes={product?.metrics?.downvote_count}
              reviews={product?.metrics?.comment_count}
              getComment={getComment}
              refreshSinglePost={getSingleProduct}
              userId={product?.user_id}
              username={product?.username}
            />
            {/* <CommentInput getComment={getComment} isProductPage={true} /> */}
            <div className="commentThread-wrap flex flex-col gap-y-12 max-h-[32rem] overflow-y-scroll">
              {comments ? (
                comments?.map((comment, i) => (
                  <CommentThread
                    key={comment.id}
                    isProductPage={true}
                    isAdminProfile={true}
                    commentId={comment.id}
                    commentType={comment.type}
                    userId={comment.user_id}
                    commentUserId={comment?.user_id}
                    index
                    commentNumber={comment?.metrics?.comment_count}
                    like={comment?.metrics?.upvote_count}
                    unlike={comment?.metrics?.downvote_count}
                    comment={comment.content}
                    postId={product?.id}
                    avatarFallback={comment?.title.slice(0, 2).toUpperCase()}
                    username={comment?.title}
                    setComments={setComments}
                    setReplies={setReplies}
                    nextLevelComments={
                      replies?.filter(
                        (innerComment) => innerComment?.parent_id === comment.id
                      ) || comment.id
                    }
                    setNextLevelComments
                    getComment={getComment}
                  />
                ))
              ) : (
                <div className="comment mb-4 w-full">
                  <Skeleton
                    count={3}
                    height={100}
                    style={{ marginBottom: ".5rem" }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="flex w-full md:w-[30%] flex-col">
            <div className="p-4">
              <h2 className="text-2xl font-semibold mt-12 md:-mt-12 mb-4">
                Related Products
              </h2>
              {relatedProducts ? (
                relatedProducts.data?.data?.post.map((post, index) => (
                  <RelatedProductCard key={index} post={post} />
                ))
              ) : (
                <div className="wraps">
                  <Skeleton
                    count={3}
                    height={224}
                    style={{ marginBottom: "1rem" }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductReview;
