import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { BiLoaderCircle } from 'react-icons/bi';
import { validateEmail } from '../../utils/auth';
import { useValidateRequest } from '../../contexts/ValidateRequest';

const NewsLetter = () => {
    const { axiosApiCall } = useValidateRequest();
    const [isEmailValid, setIsEmailValid] = useState(true);

    const { handleSubmit, reset, register, formState: { isSubmitting } } = useForm({
        defaultValues: { email: '' },
    });

    const onSubmit = async ({ email }) => {
        if (!validateEmail(email)) {
            setIsEmailValid(false);
            return;
        }

        setIsEmailValid(true);

        try {
            const data = new FormData();
            data.set('email', email);

            const response = axiosApiCall('subscribe/newsletter/public', 'post', data);

            await toast.promise(response, {
                pending: 'Loading...',
                success: 'Subscribed successfully!',
                error: 'Something went wrong',
                autoClose: 2000,
            });

            const res = await response;
            if (res?.status === 200) reset();

        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="xl:w-auto mx-auto flex flex-col xl:items-stretch justify-between xl:flex-row gradient-background">
            <div className="w-full xl:pl-40 xl:py-28 p-4">
                <h1 className="text-4xl md:text-4xl xl:text-5xl font-bold leading-10 text-gray-800 mb-4 xl:text-left md:mt-0 mt-4 text-[#1C4F7E] h2-georgia">
                    Take Your Hair Journey to the <br /> Next Level, Join Our Community!
                </h1>

                <form onSubmit={handleSubmit(onSubmit)} className="flex items-stretch mt-12">
                    <input
                        className={`bg-transparent text-lg w-full sm:w-1/2 p-3 sm:p-4 ${isEmailValid ? 'text-[#003249]' : 'text-red-500'} newsletter-placeholder rounded-full mx-2 sm:mx-4`}
                        placeholder="Enter your email address"
                        {...register('email')}
                    />
                    <button className="rounded-full text-sm sm:text-base font-medium text-white p-4 sm:p-5 uppercase bg-[#007EA7]" type="submit">
                        {isSubmitting ? <BiLoaderCircle className="w-5 h-5 text-white animate-spin" /> : 'Subscribe'}
                    </button>
                </form>


                {!isEmailValid && <p className="text-red-500 mt-2">Please enter a valid email address</p>}
            </div>
        </div>
    );
};

export default NewsLetter;
