export const handleOpenPdf = (pdfUrl) => {
  if (!pdfUrl || typeof pdfUrl !== 'string') {
    return;
  }
  setTimeout(() => {
    window.open(pdfUrl, '_blank', 'noopener,noreferrer');
  }, 0);
};


export const extractText = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  return doc.body.textContent || "";
}