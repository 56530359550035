import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import FollowingModal from "../modals/FollowingModal";
import ProfileAvatar from "../ProfileAvatar";
import SolidButton from "../buttons/SolidButton";
import OutlineButton from "../buttons/OutlineButton";
import ProfileTab from "../ProfileTab";
import { BiLoaderCircle } from "react-icons/bi";

const UserProfile = ({ fetchedData, profileInfo }) => {
  const [iscopied, setIscopied] = useState(false);
  const navigate = useNavigate();

  const copyToClipboard = async () => {
    let baseUrl = `${window.location.href}`;
    try {
      await navigator.clipboard.writeText(baseUrl);
      setIscopied(true);
      setTimeout(() => {
        setIscopied(false);
      }, 1000);
      // console.log('Text copied to clipboard', baseUrl);
    } catch (error) {
      console.error("Error copying text: ", error);
    }
  };

  const shareProfileClick = () => {
    console.log("shareProfileClick");
    copyToClipboard();
  };
  return (
    <div>
      {profileInfo ? (
        <div className="flex flex-col items-center mt-8 md:mt-2">
          <ProfileAvatar
            bgColor="blue-500"
            initials={profileInfo?.first_name.substring(0, 2).toUpperCase()}
            imgSrc={profileInfo?.avt}
          />
          <div className="flex items-center space-x-2 mt-2">
            <p className="text-2xl">{`${profileInfo?.first_name} ${profileInfo?.last_name}`}</p>
          </div>
          <p className="text-1xl">@{profileInfo?.username}</p>
          <p className="text-gray-700 break-words m-4 text-center lg:w-[70%]">
            {profileInfo?.about_me}
          </p>
          <p className="text-black m-3 font-bold">
            {profileInfo?.post_sum} Posts&nbsp;&nbsp;&nbsp;
            {profileInfo?.follower_sum || 0} <FollowingModal />
          </p>
        </div>
      ) : (
        <div className="w-full h-[70vh] lg:h-[85vh] flex justify-center items-center">
          <BiLoaderCircle className="w-20 h-20 lg:w-28 lg:h-28 text-[var(--Cobalt-Blue)] animate-spin" />
        </div>
      )}
      <div className="flex justify-center">
        <OutlineButton
          text={iscopied ? "Copied" : "Share"}
          onClick={shareProfileClick}
        />
        <span className="ml-2" />
        <SolidButton
          text="Edit Profile"
          onClick={() => navigate("/profile/edit")}
          className="ml-4 text-white"
        />
      </div>
      <div className="flex justify-center items-center">
        <ProfileTab data={fetchedData} />
      </div>
    </div>
  );
};

export default UserProfile;
