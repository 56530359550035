import { useState, useRef, useEffect } from 'react';
import dark_lady from "../../assets/dark_lady.jpeg";
import light_lady from "../../assets/light_lady.png";
import './HomePageSlider.css';

const HomePageSlider = () => {
  const maxScrollWidth = useRef(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const carousel = useRef(null);

  const data = [
    {
      "img": dark_lady
    }, {
      "img": light_lady
    },
    {
      "img": dark_lady
    }, {
      "img": light_lady
    },
    {
      "img": dark_lady
    }, {
      "img": light_lady
    },

  ];

  const movePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
    }
  };

  const moveNext = () => {
    if (
      carousel.current !== null &&
      carousel.current.offsetWidth * currentIndex <= maxScrollWidth.current
    ) {
      setCurrentIndex((prevState) => prevState + 1);
    }
  };

  const isDisabled = (direction) => {
    if (direction === 'prev') {
      return currentIndex <= 0;
    }

    if (direction === 'next' && carousel.current !== null) {
      return (
        carousel.current.offsetWidth * currentIndex >= maxScrollWidth.current
      );
    }

    return false;
  };

  useEffect(() => {
    if (carousel !== null && carousel.current !== null) {
      carousel.current.scrollLeft = carousel.current.offsetWidth * currentIndex;
    }
  }, [currentIndex]);

  useEffect(() => {
    maxScrollWidth.current = carousel.current
      ? carousel.current.scrollWidth - carousel.current.offsetWidth
      : 0;
  }, []);

  return (
    <div className="carousel mx-auto">
      <div className="relative overflow-hidden">
        <div
          ref={carousel}
          className="carousel-container relative flex gap-1 overflow-hidden scroll-smooth snap-x snap-mandatory touch-pan-x z-0"
        >
          {data.map((item, index) => {
            return (
              <div
                key={index}
                className="carousel-item text-center relative w-full md:w-1/3 h-64 md:h-96 snap-start"
              >
                <img
                  src={item.img || ''}
                  alt="description"
                  className="w-full h-full object-cover rounded-xl"
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="absolute bottom-0 right-0 flex space-x-2 p-2">

        <svg
          onClick={movePrev}
          disabled={isDisabled('prev')}
          width="46" height="46" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.5">
            <rect x="-0.75" y="0.75" width="94.5" height="94.5" rx="47.25" transform="matrix(-1 0 0 1 94.5 0)" stroke="#003249" strokeWidth="1.5" />
            <path d="M31.9961 47.9995L63.9961 47.9995" stroke="#141B34" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M41.996 57.9995C41.996 57.9995 31.9961 50.6346 31.9961 47.9994C31.9961 45.3642 41.9961 37.9995 41.9961 37.9995" stroke="#141B34" strokeWidth="1.5" strokeLinecap="round" />
          </g>
        </svg>

        <svg
          onClick={moveNext}
          disabled={isDisabled('next')}
          width="46" height="46" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.75" y="0.75" width="94.5" height="94.5" rx="47.25" stroke="#003249" strokeWidth="1.5" />
          <path d="M64.0039 47.9995L32.0039 47.9995" stroke="#141B34" strokeWidth="1.5" strokeLinecap="round" />
          <path d="M54.004 57.9995C54.004 57.9995 64.0039 50.6346 64.0039 47.9994C64.0039 45.3642 54.0039 37.9995 54.0039 37.9995" stroke="#141B34" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
      </div>
    </div>
  );
};

export default HomePageSlider;
