import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useDropzone } from "react-dropzone";

import { toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { useStateContext } from "../../contexts/ContextProviders";
import { useValidateRequest } from "../../contexts/ValidateRequest";

import { PiCaretLeft } from "react-icons/pi";
import { BsUpload } from "react-icons/bs";
import { GoPencil } from "react-icons/go";

import { post_type } from "./UploadModal";
import { hairTagOptions } from "./UploadModal";
import { productTypeOptions } from "./UploadModal";
import { BiLoaderCircle } from "react-icons/bi";

const UploadProductModal = () => {
  const [uploadedPhoto, setUploadedPhoto] = useState(null);
  const [hairTag, setHairTag] = useState([]);
  const [productType, setProductType] = useState([]);
  const [description, setDescription] = useState("");

  const [statusCode, setStatusCode] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  //Drag and drop doesn't work well on ubuntu 22.04 [wayland]
  //Fix for now is changing wayland to xorg
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFile) => {
      setUploadedPhoto(
        Object.assign(acceptedFile[0], {
          preview: URL.createObjectURL(acceptedFile[0]),
        })
      );
    },
  });

  const { setIsClicked } = useStateContext();
  const { axiosApiCall } = useValidateRequest();

  useEffect(() => {
    // Making sure the data urls are revoked to avoid memory leaks>
    return () => window.URL.revokeObjectURL(uploadedPhoto);
  }, [uploadedPhoto]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const data = new FormData();
      const hairTagStr = hairTag.map((tag) => tag.value).join(",");
      const productTagStr = productType.map((tag) => tag.value).join(",");

      data.set("description", description);
      data.set("hair_type_tags", hairTagStr);
      data.set("product_type_tags", productTagStr);
      data.set("post_type", post_type.product);
      data.set("img", uploadedPhoto);

      const response = axiosApiCall(
        "post",
        "post",
        data,
        "multipart/form-data"
      );

      const resolved = await toast.promise(response, {
        error: {
          render(data) {
            return data?.data?.response?.data?.msg;
          },
        },
        autoClose: 2000,
        transition: Slide,
      });

      const res = await resolved;
      res && res.status === 200 && setStatusCode(res.status);
      res && res.status === 200 && toast.success("Product added successfully");
      res &&
        res.status === 200 &&
        setIsClicked({
          postContentBtn: false,
          openProductUploadModal: false,
          openUploadModal: false,
          openDiscussionUploadModal: false,
        });
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
    } finally {
      if (statusCode) {
        setIsClicked({
          postContentBtn: false,
          openProductUploadModal: false,
          openUploadModal: false,
          openDiscussionUploadModal: false,
        });
        setUploadedPhoto(null);
        setDescription("");
        setHairTag([]);
        setProductType([]);
      }
      setIsSubmitting(false);
    }
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  return (
    <section className="productModal fixed left-0 top-[88px] md:top-[80px] z-[70] px-4 md:px-0 w-screen h-[100vh] lg:h-[calc(100%-80px)] scrollbar-thin overflow-y-scroll bg-white duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]">
      <form
        onSubmit={handleSubmit}
        className="w-[100%] md:w-full h-full relative md:flex flex-col items-center justify-center mb-8"
      >
        <button
          type="button"
          onClick={() =>
            setIsClicked({
              postContentBtn: false,
              openUploadModal: false,
              openDiscussionUploadModal: false,
            })
          }
          className="absolute flex items-center gap-x-2 top-2 lg:left-14 text-[#003249] text-sm font-[400] bg-[#FCFCFD] hover:bg-[#F2F9FB] py-1.5 md:py-2 px-6 md:px-4 border rounded-2xl"
        >
          <PiCaretLeft className="w-4 h-4" />
          <p className="">Back</p>
        </button>

        <div className="absolute top-16 lg:top-20 flex flex-col lg:flex-row-reverse items-center lg:items-start justify-between w-full lg:w-[1123px]">
          <div className="flex flex-col w-[100%] lg:w-[452px]">
            <p className="text-sm font-[400] mb-2">Upload image</p>
            <div
              {...getRootProps({
                className:
                  "relative flex flex-col w-[100%] h-[250px] items-center justify-center bg-[#FFF8F4] border border-dotted border-[#FFC5A4] px-[45px] py-8 mb-[22px] rounded-3xl  hover:bg-[#FFF8F4]/60 cursor-pointer",
              })}
            >
              <input
                {...getInputProps()}
                className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer z-50 block"
                style={{ display: "block" }}
                required
              />

              {uploadedPhoto && (
                <>
                  <div className="absolute w-full h-full z-10 rounded-3xl top-0 left-0">
                    <img
                      alt="preview"
                      src={uploadedPhoto.preview}
                      className="w-full h-full object-cover rounded-3xl"
                      onLoad={() => {
                        URL.revokeObjectURL(uploadedPhoto.preview);
                      }}
                    />
                  </div>

                  <div className="absolute top-4 right-4 flex items-center justify-center w-[28px] h-[28px] rounded-full bg-gray-50 p-1 z-50">
                    <GoPencil className="text-gray-800" />
                  </div>
                </>
              )}

              <BsUpload className="h-[42px] w-[42px] font-light" />
              <div className="flex flex-col w-[301px] gap-y-6">
                <p className="text-center">Choose a file</p>
                <p className="text-sm text-center">
                  {uploadedPhoto && uploadedPhoto?.preview
                    ? ""
                    : "Image must be JPG, PNG and should be less than 20MB"}
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col w-[100%] lg:w-[601px]">
            <div className="flex flex-col gap-y-4">
              <p className="text-sm font-[400] -mt-1">Description</p>
              <ReactQuill
                placeholder="Enter a detailed description"
                modules={modules}
                formats={formats}
                onChange={(desc) => setDescription(desc)}
                value={description}
              />
              <div className="flex flex-col gap-y-3">
                <p className="text-sm font-[400] mt-1">Add tags</p>
                <div className="w-full flex flex-col gap-y-2 md:grid grid-cols-2 gap-x-2">
                  <Select
                    defaultValue={hairTag}
                    isMulti
                    name="hairTag"
                    options={hairTagOptions}
                    onChange={setHairTag}
                    className="text-sm placeholder:text-opacity-90 active:border-[#007EA7] focus:border-[#007EA7]"
                    classNamePrefix="select"
                    placeholder="Hair type"
                    menuPlacement="auto"
                    onFocus={(e) => {
                      e.target.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                      });
                    }}
                  />
                  <Select
                    defaultValue={productType}
                    isMulti
                    name="productType"
                    options={productTypeOptions}
                    onChange={setProductType}
                    className="text-sm placeholder:text-opacity-90 active:border-[#007EA7] focus:border-[#007EA7]"
                    classNamePrefix="select"
                    placeholder="Product type"
                    menuPlacement="auto"
                    onFocus={(e) => {
                      e.target.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                      });
                    }}
                  />
                </div>
              </div>

              <button
                type="submit"
                className="flex items-center justify-center w-full py-[8px] text-sm text-[#FCFCFD] bg-[var(--Cobalt-Blue)] hover:bg-[#006080] rounded mb-[20vh] lg:mb-10"
              >
                {isSubmitting ? (
                  <BiLoaderCircle className="w-5 h-5 text-white animate-spin" />
                ) : (
                  "Post Content"
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
    </section>
  );
};

export default UploadProductModal;
