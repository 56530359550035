import React from 'react';
import RelatedProductCard from "../../components/cards/LandingPageRelatedProductCard";

const OurProducts = () => {
  const products = [
    'Dexou Argan Oil Shampoo – with Keratin & Repair Water 5000ml / 5 Litres',
    'Super food Lx Hair, skin & nail oil - 50 ml',
    'Dexou Argan Oil Shampoo – with Keratin & Repair Water 5000ml / 5 Litres',
    'Dexou Argan Oil Shampoo – with Keratin & Repair Water 5000ml / 5 Litres',
  ];

  return (
    <div className="relative overflow-hidden py-20 lg:py-[115px]">
      <div className="container mx-auto">
        <div className="relative overflow-hidden">
          <div className="-mx-4 flex flex-wrap items-stretch">
            <div className="w-full px-4">
              <div className="mx-auto max-w-[570px] text-center">
                <h2 className="mb-2.5 text-3xl font-bold md:text-[38px] md:leading-[1.44] text-[#1C4F7E] h2-georgia">
                  Our Products
                </h2>
                <p className="mx-auto mb-6 max-w-[515px] text-[#043964] text-xl text-justify px-6">
                  Discover our curated selection of premium hair care products designed for textured hair. Our range includes nourishing shampoos, deep conditioners, moisturising leave-ins, and protective styling essentials.
                </p>
                <a
                  href="https://mytexturecare.com/"
                  target="_blank" rel="noopener noreferrer"
                  className="rounded-md px-7 py-3 text-base font-medium text-white bg-[#007EA7]"
                >
                  Visit our shop
                </a>
              </div>

              <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-8">
                {products.map((product, index) => (
                  <RelatedProductCard key={index} name={product} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurProducts;