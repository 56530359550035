import React from "react";
import Avatar from "../assets/icons/avatar.svg";

const ProfileAvatar = ({ bgColor, initials, imgSrc }) => {
  return (
    <div className="flex justify-center items-center">
      {imgSrc ? (
        <img
          className="w-24 h-24 rounded-full border border-gray-300 mx-auto sm:mx-0 object-cover"
          src={imgSrc ? imgSrc : Avatar}
          alt="avatar"
        />
      ) : (
        <div
          className={`bg-[#F79009] rounded-full w-24 h-24 flex items-center justify-center text-black text-2xl font-bold`}
          style={{ backgroundColor: bgColor }}
        >
          {initials}
        </div>
      )}
    </div>
  );
};

export default ProfileAvatar;
