/**
 * Inner Comment input component
 *
 * For adding a second layer reply to a discussion post commenet
 * supposed path -> /discussion/id_8w7q7w6qwye7....
 *
 */

import React, { useState } from "react";
import { useParams } from "react-router-dom";
// import EmojiPicker from "emoji-picker-react";

import { useValidateRequest } from "../../contexts/ValidateRequest";
import { ToastContainer, toast, Slide } from "react-toastify";
import { PiSmileyThin } from "react-icons/pi";
import { VscSend } from "react-icons/vsc";
import { BiLoader } from "react-icons/bi";
import { useStateContext } from "../../contexts/ContextProviders";

const InnerCommentInput = ({
  getComment,
  parent_id,
  userComment,
  editClicked,
  commentId,
  commentType,
  username,
  setEditClicked,
  isAdminProfile,
  adminProductId,
}) => {
  const [comment, setComment] = useState("");
  const [adminReply, setAdminReply] = useState(userComment);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { axiosApiCall } = useValidateRequest();
  const { role } = useStateContext();
  const { postId, productId } = useParams();

  // const [chosenEmoji, setChosenEmoji] = useState(null);
  // const [emojiOnOpen, setEmojiOnOpen] = useState(false);

  //TODO: Implement emoji additions
  // const onEmojiClick = (e) => {
  //   // console.log(e);
  //   // e.preventDefault();
  //   setChosenEmoji(e.emoji);
  // };

  const handleCommentSubmission = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const data = new FormData();
      data.set(
        "target_id",
        adminProductId ? adminProductId : postId || productId
      );
      data.set("content", comment);
      data.set("parent_id", parent_id);

      const postCommentResponse = await axiosApiCall(
        "comment",
        "post",
        data,
        "multipart/form-data"
      );

      getComment();
      setComment("");
      setIsSubmitting(false);
      // console.log(comment)
      console.log('postCommentResponse');
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
    }
  };
  console.log(parent_id)

  const handleEditReplySubmission = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const data = new FormData();
      data.set("target_id", adminProductId ? adminProductId : postId || productId);
      data.set("parent_id", parent_id);
      data.set("content", adminReply);
      data.set("post_type", commentType);
      data.set("title", username);
      
      const postEditResponse = axiosApiCall(
        "comment",
        "put",
        data,
        "multipart/form-data"
      );
       // Toast Notification
      const notification = await toast.promise(postEditResponse, {
        pending: "Loading", // Display while posting
        success:  'Reply edited successfully',
        error: {
          render({ data }) {
            // When the api returns error
            return data?.response?.data?.msg || "Something went wrong";
          },
        },

        autoClose: 2000,
        transition: Slide,
      });
      getComment() 
      setAdminReply("");
      setIsSubmitting(false);
      setEditClicked(false);
        setTimeout(() => {
      }, 3000);
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
    } 
    // finally {
    //   getComment();
    // }
  };

  return (
    <form onSubmit={ (role === 'ADMIN' && editClicked) ? handleEditReplySubmission : handleCommentSubmission} className="relative w-full">
      <input
        type="text"
        name="comment"
        id="comment"
        value={(role === "ADMIN" && editClicked) ? adminReply : comment}
        onChange={(e) => role === "ADMIN" && editClicked ? setAdminReply(e.target.value) : setComment(e.target.value)}
        className="w-full outline-none rounded-2xl border border-gray-300 hover:border-[#007EA7]/40 focus:border-[#007EA7] active:border-[#007EA7] pr-6 pl-8 py-[10px] text-sm placeholder-gray-400 focus:placeholder:opacity-75 text-gray-600 "
        placeholder="Enter a reply"
      />
      <button
        type="button"
        onClick={() => console.log("Emoji opened")}
        className="absolute top-[50%] -translate-y-[50%] left-2 opacity-85 hover:opacity-100"
      >
        <PiSmileyThin className="w-5 h-5 text-gray-500 hover:text-[#007EA7]" />
      </button>

      {/* <div className="absolute top-4  left-8">
        <EmojiPicker
          onEmojiClick={onEmojiClick}
          open={emojiOnOpen}
          className="z-10"
        />
      </div> */}

      <button
        type="submit"
        className="absolute top-[50%] -translate-y-[50%] right-2 opacity-85 hover:opacity-100"
      >
        {isSubmitting ? (
          <BiLoader className="w-5 h-5 text-[#007EA7] animate-spin" />
        ) : (
          <VscSend className="w-5 h-5 text-gray-500 hover:text-[#007EA7]" />
        )}
      </button>
    </form>
  );
};

export default InnerCommentInput;
