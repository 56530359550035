import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../contexts/ContextProviders";
import { useValidateRequest } from "../../contexts/ValidateRequest";
import { useDropzone } from "react-dropzone";

import Select from "react-select";
import { Slide, toast } from "react-toastify";

import { GoPencil } from "react-icons/go";
import { BsUpload } from "react-icons/bs";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { hairTagOptions, post_type, productTypeOptions } from "./UploadModal";
import ProductImage from "../../assets/images/product_image.jpeg";
import { BiLoaderCircle } from "react-icons/bi";

const EditProduct = ({ product }) => {
  const [uploadedPhoto, setUploadedPhoto] = useState(null);
  const [defaultProductFile, setDefaultProductFile] = useState(null);
  const [hairTag, setHairTag] = useState([]);
  const [productType, setProductType] = useState([]);
  const [description, setDescription] = useState("");

  const [statusCode, setStatusCode] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { setIsClicked, setEditProductInfo } = useStateContext();
  const { axiosApiCall } = useValidateRequest();
  const navigate = useNavigate();

  //Drag and drop doesn't work well on ubuntu 22.04 [wayland]
  //Fix for now is changing wayland to xorg
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFile) => {
      setUploadedPhoto(
        Object.assign(acceptedFile[0], {
          preview: URL.createObjectURL(acceptedFile[0]),
        })
      );
    },
  });

  useEffect(() => {
    // Making sure the data urls are revoked to avoid memory leaks>
    return () => window.URL.revokeObjectURL(uploadedPhoto);
  }, [uploadedPhoto]);

  useEffect(() => {
    //create a file blob for already existing image
    product &&
      fetch(product.url)
        .then((res) => res.blob()) // Gets the response and returns it as a blob
        .then((blob) => {
          let metadata = {
            type: blob.type,
          };

          // Create a file with the blob details
          let defaultFile = new File(
            [blob],
            `default.${blob.type.split("/")[1]}`,
            metadata
          );

          // Set the file using states
          defaultFile && setDefaultProductFile(defaultFile);
        });
  }, [product]);

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    try {
      const data = new FormData();
      const hairTagStr = hairTag.map((tag) => tag.value).join(",");
      const productTagStr = productType.map((tag) => tag.value).join(",");

      data.set("description", description ? description : product.content);
      data.set(
        "hair_type_tags",
        hairTagStr ? hairTagStr : product.hair_type.join(",")
      );
      data.set(
        "product_type_tags",
        productTagStr ? productTagStr : product.product_type.join(",")
      );
      data.set("post_type", post_type.product);
      data.set("post_id", product.id);

      data.set("img", uploadedPhoto ? uploadedPhoto : defaultProductFile);

      const response = axiosApiCall("post", "put", data, "multipart/form-data");

      // console.log(response);

      const resolved = await toast.promise(response, {
        error: {
          render(data) {
            return data?.data?.response?.data?.msg;
          },
        },
        autoClose: 2000,
        transition: Slide,
      });

      const res = await resolved;
      res && res.status === 200 && setStatusCode(res.status);
      res &&
        res.status === 200 &&
        toast.success("Product updated successfully");
      res && res.status === 200 && setIsClicked({ editProduct: false });

      navigate("/profile");
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
    } finally {
      if (statusCode) {
        setIsClicked({ editProduct: false });

        setEditProductInfo({
          id: null,
          title: null,
          content: "",
          product_type: [],
          hair_type: [],
          created_time: "",
          username: "",
          url: "",
        });

        setUploadedPhoto(null);
        setDefaultProductFile(null);
        setDescription("");
        setHairTag([]);
        setProductType([]);
      }
      setIsSubmitting(false);
    }
  };

  return (
    <div className="modal-warp fixed inset-0 z-50 bg-black/40 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0">
      <div className="flex flex-col gap-y-4 fixed left-[50%] top-[15%] md:top-[50%] z-50 w-[95%] lg:w-[1080px] rounded-[16px] p-[20px] translate-x-[-50%] lg:translate-y-[-50%] gap-4 bg-white duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] overflow-y-auto max-h-[600px]">
        <div className="flex items-center w-full justify-between">
          <p className="font-semibold text-[20px] leading-8 text-[#000000]">
            Edit Product
            <br />
            <span className="text-sm">
              Are you sure you want to edit this product?
            </span>
          </p>
          <div className="flex flex-col text-xs text-gray-400">
            <p>
              Last updated:{" "}
              <span className="text-gray-600 font-semibold">
                {new Date(product?.created_time).toLocaleDateString()}
              </span>
            </p>
            <p>
              Created by:{" "}
              <span className="text-gray-600 font-semibold">
                {product.username}
              </span>
            </p>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="w-full ">
          <div className="w-full flex lg:flex-row-reverse items-center lg:items-start justify-between ">
            <div className="flex flex-col">
              <p className="text-sm font-[400] mb-2">Upload image</p>
              <div
                {...getRootProps({
                  className:
                    "relative flex flex-col w-[90%] h-[250px] items-center justify-center bg-[#FFF8F4] border border-dotted border-[#FFC5A4] px-[45px] py-8 mb-[22px] rounded-3xl  hover:bg-[#FFF8F4]/60 cursor-pointer",
                })}
              >
                <input
                  {...getInputProps()}
                  className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer z-50 block"
                  style={{ display: "block" }}
                  required={product.url ? false : true}
                />

                {uploadedPhoto ? (
                  <>
                    <div className="absolute w-full h-full z-10 rounded-3xl top-0 left-0">
                      <img
                        alt="preview"
                        src={uploadedPhoto.preview}
                        className="w-full h-full object-cover rounded-3xl"
                        onLoad={() => {
                          URL.revokeObjectURL(uploadedPhoto.preview);
                        }}
                      />
                    </div>

                    <div className="absolute top-4 right-4 flex items-center justify-center w-[28px] h-[28px] rounded-full bg-gray-50 p-1 z-50">
                      <GoPencil className="text-gray-800" />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="absolute w-full h-full z-10 rounded-3xl top-0 left-0">
                      <img
                        alt="preview"
                        src={
                          product?.url === null ? ProductImage : product?.url
                        }
                        className="w-full h-full object-cover rounded-3xl"
                      />
                    </div>

                    <div className="absolute top-4 right-4 flex items-center justify-center w-[28px] h-[28px] rounded-full bg-gray-50 p-1 z-50">
                      <GoPencil className="text-gray-800" />
                    </div>
                  </>
                )}

                <BsUpload className="h-[42px] w-[42px] font-light" />
                <div className="flex flex-col w-[301px] gap-y-6">
                  <p className="text-center">Choose a file</p>
                  <p className="text-sm text-center">
                    {uploadedPhoto && uploadedPhoto?.preview
                      ? ""
                      : "Image must be JPG, PNG and should be less than 20MB"}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col w-[95%] lg:w-[601px]">
              <div className="flex flex-col gap-y-4">
                <p className="text-sm font-[400] -mt-1">Description</p>
                <ReactQuill
                  placeholder="Enter a detailed description"
                  theme="snow"
                  modules={modules}
                  formats={formats}
                  // defaultValue={`<h1>Hello world</h1>`}
                  onChange={(desc) => setDescription(desc)}
                  value={
                    product && !description ? product.content : description
                  }
                />

                <div className="flex flex-col gap-y-3">
                  <p className="text-sm font-[400] mt-1">Update product tags</p>
                  <div className="w-full flex flex-col gap-y-2 md:grid grid-cols-2 gap-x-2">
                    <Select
                      defaultValue={hairTag}
                      isMulti
                      name="hairTag"
                      options={hairTagOptions}
                      onChange={setHairTag}
                      defaultInputValue={product?.hair_type.join(", ")}
                      className="text-sm placeholder:text-opacity-90 active:border-[#007EA7] focus:border-[#007EA7]"
                      classNamePrefix="select"
                      placeholder="Hair type"
                    />
                    <Select
                      defaultValue={productType}
                      isMulti
                      name="productType"
                      options={productTypeOptions}
                      onChange={setProductType}
                      defaultInputValue={product?.product_type.join(", ")}
                      className="text-sm placeholder:text-opacity-90 active:border-[#007EA7] focus:border-[#007EA7]"
                      classNamePrefix="select"
                      placeholder="Product type"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full flex items-center justify-end gap-x-8 mt-8">
            <button
              type="submit"
              // onClick={editPost}
              disabled={isSubmitting}
              className="flex items-center justify-center py-2 w-[160px] text-[#FCFCFD] bg-[var(--Cobalt-Blue)] hover:bg-[#006080] rounded font-[400]"
            >
              {isSubmitting ? (
                <BiLoaderCircle className="w-5 h-5 text-white animate-spin" />
              ) : (
                "Edit Product"
              )}
            </button>

            <button
              type="button"
              onClick={() => {
                setIsClicked({ editProduct: false });
                setEditProductInfo({
                  id: null,
                  title: null,
                  content: "",
                  product_type: [],
                  hair_type: [],
                  created_time: "",
                  username: "",
                  url: "",
                });
              }}
              className="py-2 w-[160px] text-center text-[#007EA7] rounded font-[400] bg-gray-600/10 hover:bg-gray-500/10"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditProduct;
