import React, { useCallback, useEffect, useState } from "react";
import { useStateContext } from "../../contexts/ContextProviders";
import { useValidateRequest } from "../../contexts/ValidateRequest";

import ModalContainer from "../modals/ModalContainer";

import ProfileAvatar from "../ProfileAvatar";
import AdminProfileForm from "../admin-components/AdminProfileForm";
import AdminProductPanel from "../admin-components/AdminProductPanel";
import BackButton from "../buttons/BackButton";
import { useNavigate } from "react-router-dom";

const AdminProfile = () => {
  const [activeBar, setActiveBar] = useState("PROFILE");
  const [profileInfo, setProfileInfo] = useState(null);

  const { axiosApiCall } = useValidateRequest();
  const { isClicked, userId } = useStateContext();

  const navigate = useNavigate();

  const fetchProfileInfo = useCallback(async () => {
    try {
      await axiosApiCall(`user/${userId}`, "get").then(
        (data) => data.status === 200 && setProfileInfo(data?.data?.data)
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [axiosApiCall, userId]);

  useEffect(() => {
    userId && fetchProfileInfo();
  }, [fetchProfileInfo, userId]);

  return (
    <div className="w-full flex flex-col gap-y-6">
      <BackButton className="ml-5 mt-3 w-fit" onClick={() => navigate(-1)} />
      {(isClicked.deleteReview ||
        isClicked.deleteProductPost ||
        isClicked.deleteReply) && <ModalContainer />}

      {profileInfo ? (
        <div className="flex flex-col items-center mt-8 md:mt-2">
          <ProfileAvatar
            bgColor="blue-500"
            initials={profileInfo?.first_name.substring(0, 2).toUpperCase()}
            imgSrc={profileInfo?.avt}
          />
          <div className="flex items-center space-x-2 mt-2">
            <p className="text-2xl">{`${profileInfo?.first_name} ${profileInfo?.last_name}`}</p>
          </div>
          <p className="text-1xl">@{profileInfo?.username}</p>
          <p className="px-4 py-1 text-sm rounded-full bg-gray-400/40 font-semibold my-4 text-center">
            Admin
          </p>
        </div>
      ) : (
        <div className="flex flex-col items-center gap-y-4">
          <div className="w-24 h-24 rounded-full bg-gray-300 animate-pulse" />
          <div className="flex flex-col gap-y-2 items-center">
            <div className="w-44 h-8 bg-gray-300 animate-pulse rounded-md" />
            <div className="w-32 h-6 bg-gray-300 animate-pulse rounded-md" />
          </div>
          <div className="w-20 h-8 bg-gray-300 animate-pulse rounded-full mt-2" />
        </div>
      )}

      <div className="flex justify-center items-center gap-x-4 my-2 transition-all">
        <button
          onClick={() => setActiveBar("PROFILE")}
          className={`px-8 py-2 text-sm border-none outline-none rounded  ${
            activeBar === "PROFILE"
              ? "bg-[var(--Cobalt-Blue)] text-white"
              : "hover:bg-gray-300/40"
          }`}
        >
          Profile Information
        </button>
        <button
          onClick={() => setActiveBar("PRODUCTS")}
          className={`px-8 py-2 text-sm border-none outline-none rounded  ${
            activeBar === "PRODUCTS"
              ? "bg-[var(--Cobalt-Blue)] text-white"
              : "hover:bg-gray-300/40"
          }`}
        >
          Products
        </button>
      </div>

      {activeBar === "PROFILE" && (
        <AdminProfileForm profileInfo={profileInfo} />
      )}

      {activeBar === "PRODUCTS" && (
        <AdminProductPanel profileInfo={profileInfo} />
      )}
    </div>
  );
};

export default AdminProfile;
