import React, { useState } from 'react';
import overlay from "../../assets/images/overlay.png";
import { ReactComponent as CloseIcon } from '../../assets/icons/close_icon.svg';
import { ReactComponent as PlayButton } from '../../assets/icons/play_button.svg';


const ModalVideo = () => {
    const [modalOpen, setModalOpen] = useState(false);
    return (
        <div className="relative z-10 overflow-hidden py-20 lg:py-[115px] bg-[#003249]">
            <div className="container mx-auto">
                <div className="relative overflow-hidden">
                    <div className="mx-8 px-4 md:px-6 mt-8">
                        <div className="mx-auto max-w-[570px] text-center">
                            <h2 className="text-3xl font-bold text-white md:text-[38px] md:leading-[1.44] text-[#1C4F7E] h2-georgia">
                                Maybe a video can convince you?
                            </h2>
                        </div>
                        <div className="flex justify-center mt-8">
                            <button
                                className="relative flex justify-center items-center focus:outline-none focus-visible:ring focus-visible:ring-indigo-300 rounded-3xl group"
                                onClick={() => setModalOpen(true)}
                                aria-controls="modal"
                                aria-label="Watch the video"
                            >
                                <img
                                    className="w-full max-w-4xl rounded-3xl shadow-2xl transition-transform duration-500 group-hover:scale-105"
                                    src={overlay}
                                    alt="Video thumbnail"
                                />
                                <div className="absolute inset-0 flex justify-center items-center">
                                    <div className="flex justify-center items-center w-20 h-20 bg-white bg-opacity-80 rounded-full shadow-2xl">
                                        <PlayButton />
                                    </div>
                                </div>
                            </button>

                            {modalOpen && (
                                <div
                                    className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
                                    onClick={() => setModalOpen(false)}
                                >
                                    <div
                                        className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <div className="p-4">
                                            <div className="flex justify-end">
                                                <button
                                                    className="text-gray-400 hover:text-gray-500"
                                                    onClick={() => setModalOpen(false)}
                                                >
                                                    <span className="sr-only">Close</span>
                                                    <CloseIcon />
                                                </button>
                                            </div>
                                            <div className="mt-2">
                                                <video
                                                    className="w-full rounded-lg"
                                                    controls
                                                    src="https://www.w3schools.com/html/mov_bbb.mp4"
                                                >
                                                    Your browser does not support the video tag.
                                                </video>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalVideo;