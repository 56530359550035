import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useStateContext } from "../../contexts/ContextProviders";
import { useValidateRequest } from "../../contexts/ValidateRequest";

import UserfeedHeader from "../../components/UserfeedHeader";
import BackButton from "../../components/buttons/BackButton";
import PostCard from "../userfeed/PostCard";
import Skeleton from "react-loading-skeleton";

import CommentThread from "../../components/comments/CommentThread";

import { ReactComponent as HeartIcon } from "../../assets/icons/heart_icon.svg";
import { ReactComponent as CommentIcon } from "../../assets/icons/comment_icon.svg";
import { ReactComponent as ShareIcon } from "../../assets/icons/share_icon.svg";

import { FaRegThumbsDown, FaThumbsDown } from "react-icons/fa";
import { MdOutlineDelete } from "react-icons/md";
import { BsHeartFill } from "react-icons/bs";

import ModalContainer from "../../components/modals/ModalContainer";
import UploadModal from "../../components/modals/UploadModal";
import UploadDiscussionModal from "../../components/modals/UploadDiscussionModal";

import CommentInput from "../../components/comments/CommentInput";
import DeleteImagePost from "../../components/modals/DeleteImagePost";

const ViewImagePost = () => {
  const navigate = useNavigate();

  const [comments, setComments] = useState(null);
  const [nextLevelComments, setNextLevelComments] = useState(null);
  const [explore, setExplore] = useState(null);
  const [post, setPost] = useState(null);
  const [userVoteStatus, setUserVoteStatus] = useState(
    post?.metrics?.user_has_voted
  );
  const [like, setLike] = useState(0);
  const [unlike, setUnlike] = useState(0);
  const [iscopied, setIscopied] = useState(false);

  const { isClicked, userId, role, handleClick } = useStateContext();
  const { axiosApiCall } = useValidateRequest();

  const { postId } = useParams();

  // console.log(url)

  const copyToClipboard = async () => {
    const url = window.location.href;
    try {
      await navigator.clipboard.writeText(url);
      setIscopied(true);
      setTimeout(() => {
        setIscopied(false);
      }, 1000);
      console.log("Text copied to clipboard");
    } catch (error) {
      console.log(error);
    }
  };

  // Handles Vote
  const handleVote = async (id, type, action, updateFunction) => {
    try {
      const data = new FormData();
      data.set("target_object_id", id);
      data.set("target_type", type);
      data.set("target_user_id", post.user_id);

      const response = await axiosApiCall(
        action,
        "post",
        data,
        "multipart/form-data"
      );
      if (response.status === 200) {
        updateFunction(id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSinglePost = async (postId) => {
    try {
      const params = { include_metrics: true };
      const { data } = await axiosApiCall(`post/${postId}`, "get", params);
      // console.log(data)
      setPost(data.data);
      setLike(data?.data?.metrics?.upvote_count);
      setUnlike(data?.data?.metrics?.downvote_count);
      setUserVoteStatus(data?.data?.metrics?.user_has_voted);
    } catch (error) {
      console.log(error);
    }
  };

  // This function upvotes a post
  const upVotePost = (id) => handleVote(id, post.type, "vote", getSinglePost);
  const downVotePost = (id) =>
    handleVote(id, post.type, "devote", getSinglePost);

  const getComment = async () => {
    const params = {
      page: 1,
      limit: 100,
      target_id: postId,
      include_metrics: true,
    };
    try {
      const getCommentResponse = await axiosApiCall("comment", "get", params);
      const allComments = getCommentResponse.data.data;
      const firstComments = allComments.filter((comment) => comment.parent_id === null);
      const secondLevelComments = allComments.filter((comment) => comment.parent_id !== null)
      setComments(firstComments);
      setNextLevelComments(secondLevelComments);
    } catch (error) {
      console.log(error);
    }
  };

  const getOtherProducts = async () => {
    try {
      const params = { include_metrics: true, content_type: "image" };
      const data = await axiosApiCall("post", "get", params);
      setExplore(data);
      // console.log(data.data)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const rand = async () => {
      await getComment();
      await getOtherProducts();
      await getSinglePost(postId);
      // console.log(nextLevelComments)
    };
    window.scrollTo(0,0)
    rand();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postId]);

  return (
    <div className="">
      <UserfeedHeader imagePostPage={true} />
      {/* postContentBtn state - This is the entry points to opening the modal container */}
      {isClicked.postContentBtn && <ModalContainer />}
      {isClicked.openUploadModal && <UploadModal />}
      {isClicked.openDiscussionUploadModal && <UploadDiscussionModal />}
      {isClicked.deleteImagePost && (
        <DeleteImagePost
          imageSrc={post?.url}
          postId={postId}
          username={post?.username}
        />
      )}

      <div className="p-4 lg:ml-10">
        <BackButton onClick={() => navigate(-1)} />
      </div>
      <div className="content grid grid-cols-1 lg:grid-cols-3 lg:gap-4 h-fit lg:h-[40rem] p-4 ">
        <div className=" col-span-2 bg-white w-full lg:w-[60%] p-2 mx-auto">
          <div className="rounded-md relative h-auto lg:h-[40rem] w-full">
            <img
              src={post?.url}
              alt=""
              className="w-full h-auto lg:h-[40rem] rounded-lg"
            />

            <div className="flex items-center justify-between mt-2 text-left pl-2 absolute bottom-[0] text-white text-sm bg-gradient-to-b from-[#00000000] to-[#000000e0] w-full h-[2rem] rounded-b-lg">
              <Link
                to={
                  post?.user_id && userId === post?.user_id
                    ? "/profile"
                    : `/profile/${post?.user_id}`
                }
                className=""
              >
                {post?.username}
              </Link>
              {role === "ADMIN" && (
                <button
                  onClick={() => {
                    handleClick("deleteImagePost");
                  }}
                  className="absolute bottom-1 right-2 w-8 h-8 p-1 rounded-full bg-gray-100/20"
                >
                  <MdOutlineDelete className="w-full h-full text-gray-200 hover:text-gray-50" />
                </button>
              )}
            </div>
            {post?.hair_type.length !== 0 || post?.product_type.length !== 0 ? (
              <span className="absolute top-7 right-7  p-1 px-2 text-xs bg-yellow-200 text-gray-600 rounded-full">{`${post?.hair_type.map((hair_tag) => " " + hair_tag)}${post?.hair_type.length === 0 ? "" : post?.product_type.length === 0 ? "" : ","} ${post?.product_type.map((product_tag) => " " + product_tag)}`}</span>
            ) : null}
          </div>
          <div className="flex items-center justify-between my-2 relative">
            <div className="w-[5.4rem] flex items-center justify-between space-x-1">
              <div className="flex items-center text-[var(--Cobalt-Blue)]">
                {userVoteStatus === 0 ? (
                  <HeartIcon
                    className="w-4"
                    onClick={() => upVotePost(post.id)}
                  />
                ) : userVoteStatus === 1 ? (
                  <BsHeartFill className="w-4" />
                ) : (
                  <HeartIcon
                    className="w-4"
                    onClick={() => upVotePost(post.id)}
                  />
                )}
                <span className="ml-1">
                  {/* {like !== 0 ? like : post?.metrics?.upvote_count} */}
                </span>
              </div>
              <div className="unlike flex items-center text-[var(--Cobalt-Blue)]">
                {userVoteStatus === 0 ? (
                  <FaRegThumbsDown
                    className="w-4"
                    onClick={() => downVotePost(post.id)}
                  />
                ) : userVoteStatus === -1 ? (
                  <FaThumbsDown className="w-4" />
                ) : (
                  <FaRegThumbsDown
                    className="w-4"
                    onClick={() => downVotePost(post.id)}
                  />
                )}
                <span className="ml-1">
                  {/* {unlike !== 0 ? unlike : post?.metrics?.downvote_count} */}
                </span>
              </div>
            </div>
            <div className="otherSide flex justify-between w-[5rem]">
              <div className="flex text-[var(--Cobalt-Blue)]">
                <CommentIcon className="w-4 mr-1" /> {comments?.length || 0}
              </div>
              <div className="share" onClick={copyToClipboard}>
                <ShareIcon className="w-4" />
              </div>
            </div>

            {/* Copy notification. Displays when a user clicks on the share icon */}
            {iscopied && (
              <span className="text-[var(--Cobalt-Blue)] text-xs absolute bg-[#e6e2dff8] z-10 p-2 rounded-lg right-0 bottom-8 lg:bottom-0 lg:right-[-4.5rem]">
                Copied!
              </span>
            )}
          </div>

          {/* comment input */}
          <CommentInput getComment={getComment} />

          {/* Commment thread */}
          <div className="commentSection flex flex-col w-full gap-y-12 max-h-[32rem] overflow-y-scroll mb-4">
            {comments ? (
              comments.map((comment) => {
                return (
                  <CommentThread
                    key={comment.id}
                    commentId={comment.id}
                    commentType={comment.type}
                    userId={comment.user_id}
                    commentUserId={comment?.user_id}
                    postId={post?.id}
                    commentNumber={comment?.metrics?.comment_count}
                    like={comment?.metrics?.upvote_count}
                    unlike={comment?.metrics?.downvote_count}
                    votingStatus={comment?.metrics?.user_has_voted}
                    comment={comment.content}
                    avatarFallback={comment?.title.slice(0, 2).toUpperCase()}
                    username={comment.title}
                    setComments={setComments}
                    setReplies={setNextLevelComments}
                    nextLevelComments={
                      nextLevelComments?.filter(
                        (innerComment) => innerComment?.parent_id === comment.id
                      ) || comment.id
                    }
                    getComment={getComment}
                  />
                );
              })
            ) : (
              <div className="comment mb-4 w-full">
                <Skeleton
                  count={3}
                  height={100}
                  style={{ marginBottom: ".5rem" }}
                />
              </div>
            )}
          </div>
        </div>
        <div className="w-full lg:w-[60%] mx-auto lg:m-0 ">
          <p className="font-serif font-bold text-[16px] mb-4">
            More Posts to Explore
          </p>

          {/* Data from Userfeed */}
          {explore ? (
            explore.data.data.post
              .slice(1, 5)
              .map((post, index) => <PostCard key={index} post={post} />)
          ) : (
            <div className="wraps">
              <Skeleton
                count={3}
                height={224}
                style={{ marginBottom: "1rem" }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewImagePost;
