import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useValidateRequest } from "../../contexts/ValidateRequest";

import { BiLoaderCircle } from "react-icons/bi";

import BackButton from "../../components/buttons/BackButton";
import ProfileAvatar from "../../components/ProfileAvatar";
import ProfileTab from "../../components/ProfileTab";
import DashboardHeader from "../../components/UserfeedHeader";
import { Slide, toast } from "react-toastify";

const UserProfile = () => {
  const [profileDetails, setProfileDetails] = useState(null);
  const [isFollowing, setIsFollowing] = useState(false);
  const [isUnFollowing, setIsUnFollowing] = useState(false);
  const [fetchedData, setFetchedData] = useState({
    discussion: [],
    post: [],
    reviews: [],
  });

  const navigate = useNavigate();
  const { userId: userProfileId } = useParams();
  const { axiosApiCall } = useValidateRequest();

  const updateData = useCallback((newData) => {
    setFetchedData((prevState) => {
      const updatedData = {};
      ["post", "discussion"].forEach((key) => {
        updatedData[key] = newData[key]
          ? [
              ...new Map(
                [...prevState[key], ...newData[key]].map((item) => [
                  item.id,
                  item,
                ])
              ).values(),
            ]
          : prevState[key];
      });
      return updatedData;
    });
  }, []);

  const fetchData = useCallback(async () => {
    const image_params = {
      page: 1,
      limit: 10000,
      created_by: userProfileId,
      content_type: "image",
      include_metrics: true,
    };

    const discussion_params = {
      page: 1,
      limit: 10000,
      created_by: userProfileId,
      content_type: "discussion",
      include_metrics: true,
    };
    try {
      const [postResponse, discussionResponse] = await Promise.all([
        axiosApiCall("post", "get", image_params),
        axiosApiCall("post", "get", discussion_params),
      ]);

      const postData = postResponse.data.data;
      const discussionData = discussionResponse.data.data;

      updateData({
        post: postData.post,
        discussion: discussionData.post,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [axiosApiCall, updateData, userProfileId]);

  const fetchProfileInfo = useCallback(async () => {
    const params = {
      include_metrics: true,
    };
    try {
      await axiosApiCall(`user/${userProfileId}`, "get", params).then(
        (data) => data.status === 200 && setProfileDetails(data?.data?.data)
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [axiosApiCall, userProfileId]);

  const followUser = async () => {
    setIsFollowing(true);
    try {
      const response = axiosApiCall(
        `follower?followed_user_id=${userProfileId}`,
        "post"
      );

      const resolved = await toast.promise(response, {
        error: {
          render(data) {
            return data?.data?.response?.data?.msg
              ? data?.data?.response?.data?.msg
              : "Something went wrong";
          },
        },
        autoClose: 2000,
        transition: Slide,
      });

      const res = await resolved;

      res &&
        res.status === 200 &&
        toast.success(
          `You followed ${profileDetails?.first_name} ${profileDetails?.last_name}`
        );
      setIsFollowing(false);
      fetchProfileInfo();
    } catch (error) {
      console.log(error);
      setIsFollowing(false);
    }
  };

  const unFollowUser = async () => {
    setIsUnFollowing(true);
    try {
      const response = axiosApiCall(
        `follower?followed_user_id=${userProfileId}`,
        "delete"
      );
      const resolved = await toast.promise(response, {
        error: {
          render(data) {
            return data?.data?.response?.data?.msg
              ? data?.data?.response?.data?.msg
              : "Something went wrong";
          },
        },
        autoClose: 2000,
        transition: Slide,
      });

      const res = await resolved;
      res &&
        res.status === 200 &&
        toast.success(
          `You unfollowed ${profileDetails?.first_name} ${profileDetails?.last_name}`
        );
      setIsUnFollowing(false);
      fetchProfileInfo();
    } catch (error) {
      console.log(error);
      setIsUnFollowing(false);
    }
  };

  useEffect(() => {
    userProfileId && fetchData();
    userProfileId && fetchProfileInfo();
  }, [fetchData, fetchProfileInfo, userProfileId]);

  return (
    <div>
      <DashboardHeader />
      <BackButton className="ml-5 mt-3 lg:mt-0" onClick={() => navigate(-1)} />
      {profileDetails ? (
        <div className="flex flex-col items-center">
          <ProfileAvatar
            bgColor="blue-500"
            initials={profileDetails?.first_name.substring(0, 2).toUpperCase()}
            imgSrc={profileDetails?.avt}
          />
          <div className="flex items-center space-x-2 mt-2">
            <p className="text-2xl">{`${profileDetails?.first_name} ${profileDetails?.last_name}`}</p>
          </div>
          <p className="text-1xl">@{profileDetails?.username}</p>
          <p className="text-gray-700 break-words m-4 text-center lg:w-[70%]">
            {profileDetails?.about_me}
          </p>
          <p className="text-black m-3 font-bold">
            {profileDetails?.post_sum} Posts&nbsp;&nbsp;&nbsp;
            {profileDetails?.follower_sum || 0} following
          </p>
        </div>
      ) : (
        <div className="w-full h-[70vh] lg:h-[85vh] flex justify-center items-center">
          <BiLoaderCircle className="w-20 h-20 lg:w-28 lg:h-28 text-[var(--Cobalt-Blue)] animate-spin" />
        </div>
      )}
      <div className="flex justify-center gap-x-8 transition-all">
        <button
          onClick={profileDetails?.metrics?.is_follower ? () => {} : followUser}
          className={`hover:bg-transparent font-bold py-2 px-4 rounded outline outline-[var(--Cobalt-Blue)] outline-offset-1 focus:outline-none focus:ring-2 focus:ring-[var(--Cobalt-Blue)] ${profileDetails?.metrics?.is_follower ? "cursor-default" : "hover:text-[var(--Cobalt-Blue)]"}`}
        >
          {profileDetails?.metrics?.is_follower ? (
            "Following"
          ) : (
            <>
              {isFollowing ? (
                <BiLoaderCircle className="w-5 h-5 text-[var(--Cobalt-Blue)] animate-spin" />
              ) : (
                "Follow"
              )}
            </>
          )}
        </button>

        {profileDetails?.metrics?.is_follower && (
          <button
            onClick={unFollowUser}
            className="hover:bg-transparent hover:text-red-500 font-bold py-2 px-4 rounded outline outline-red-500 outline-offset-1 focus:outline-none focus:ring-2 focus:ring-red-500"
          >
            {isUnFollowing ? (
              <BiLoaderCircle className="w-5 h-5 text-red-500 animate-spin" />
            ) : (
              "Unfollow"
            )}
          </button>
        )}
      </div>
      <div className="flex justify-center items-center">
        <ProfileTab data={fetchedData} />
      </div>
    </div>
  );
};

export default UserProfile;
