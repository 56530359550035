import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import TagManager from 'react-gtm-module'
import reportWebVitals from "./reportWebVitals";
import { ContextProvider } from "./contexts/ContextProviders";
import { ValidatorProvider } from "./contexts/ValidateRequest";

const root = ReactDOM.createRoot(document.getElementById("root"));
const tagManagerArgs = {
  gtmId: 'GTM-PMH82ZXS' 
}

TagManager.initialize(tagManagerArgs)

root.render(
  <React.StrictMode>
    <ContextProvider>
      <ValidatorProvider>
        <App />
      </ValidatorProvider>
    </ContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
